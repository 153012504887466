/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhotpMasterViTriKho,
  KhotpMasterViTriKhoControllerCountParams,
  KhotpMasterViTriKhoControllerFindByIdParams,
  KhotpMasterViTriKhoControllerFindParams,
  KhotpMasterViTriKhoControllerUpdateAllParams,
  KhotpMasterViTriKhoPartial,
  KhotpMasterViTriKhoWithRelations,
  LoopbackCount,
  NewKhotpMasterViTriKho
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khotpMasterViTriKhoControllerCount = (
    params?: KhotpMasterViTriKhoControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-vi-tri-khos/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterViTriKhoControllerCountQueryKey = (params?: KhotpMasterViTriKhoControllerCountParams,) => {
    return [`/khotp-master-vi-tri-khos/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterViTriKhoControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterViTriKhoControllerCount>>, TError = ErrorType<unknown>>(params?: KhotpMasterViTriKhoControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterViTriKhoControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerCount>>> = ({ signal }) => khotpMasterViTriKhoControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterViTriKhoControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerCount>>>
export type KhotpMasterViTriKhoControllerCountQueryError = ErrorType<unknown>

export const useKhotpMasterViTriKhoControllerCount = <TData = Awaited<ReturnType<typeof khotpMasterViTriKhoControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterViTriKhoControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterViTriKhoControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterViTriKhoControllerReplaceById = (
    id: string,
    khotpMasterViTriKho: KhotpMasterViTriKho,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-vi-tri-khos/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterViTriKho
    },
      );
    }
  


export const getKhotpMasterViTriKhoControllerReplaceByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerReplaceById>>, TError,{id: string;data: KhotpMasterViTriKho}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerReplaceById>>, TError,{id: string;data: KhotpMasterViTriKho}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerReplaceById>>, {id: string;data: KhotpMasterViTriKho}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterViTriKhoControllerReplaceById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterViTriKhoControllerReplaceByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerReplaceById>>>
    export type KhotpMasterViTriKhoControllerReplaceByIdMutationBody = KhotpMasterViTriKho
    export type KhotpMasterViTriKhoControllerReplaceByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterViTriKhoControllerReplaceById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerReplaceById>>, TError,{id: string;data: KhotpMasterViTriKho}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterViTriKhoControllerReplaceById>>,
        TError,
        {id: string;data: KhotpMasterViTriKho},
        TContext
      > => {

      const mutationOptions = getKhotpMasterViTriKhoControllerReplaceByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterViTriKhoControllerUpdateById = (
    id: string,
    khotpMasterViTriKhoPartial: KhotpMasterViTriKhoPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-vi-tri-khos/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterViTriKhoPartial
    },
      );
    }
  


export const getKhotpMasterViTriKhoControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerUpdateById>>, TError,{id: string;data: KhotpMasterViTriKhoPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerUpdateById>>, TError,{id: string;data: KhotpMasterViTriKhoPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerUpdateById>>, {id: string;data: KhotpMasterViTriKhoPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterViTriKhoControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterViTriKhoControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerUpdateById>>>
    export type KhotpMasterViTriKhoControllerUpdateByIdMutationBody = KhotpMasterViTriKhoPartial
    export type KhotpMasterViTriKhoControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterViTriKhoControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerUpdateById>>, TError,{id: string;data: KhotpMasterViTriKhoPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterViTriKhoControllerUpdateById>>,
        TError,
        {id: string;data: KhotpMasterViTriKhoPartial},
        TContext
      > => {

      const mutationOptions = getKhotpMasterViTriKhoControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterViTriKhoControllerFindById = (
    id: string,
    params?: KhotpMasterViTriKhoControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterViTriKhoWithRelations>(
      {url: `/khotp-master-vi-tri-khos/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterViTriKhoControllerFindByIdQueryKey = (id: string,
    params?: KhotpMasterViTriKhoControllerFindByIdParams,) => {
    return [`/khotp-master-vi-tri-khos/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterViTriKhoControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterViTriKhoControllerFindById>>, TError = ErrorType<unknown>>(id: string,
    params?: KhotpMasterViTriKhoControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterViTriKhoControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerFindById>>> = ({ signal }) => khotpMasterViTriKhoControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterViTriKhoControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerFindById>>>
export type KhotpMasterViTriKhoControllerFindByIdQueryError = ErrorType<unknown>

export const useKhotpMasterViTriKhoControllerFindById = <TData = Awaited<ReturnType<typeof khotpMasterViTriKhoControllerFindById>>, TError = ErrorType<unknown>>(
 id: string,
    params?: KhotpMasterViTriKhoControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterViTriKhoControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterViTriKhoControllerDeleteById = (
    id: string,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-vi-tri-khos/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhotpMasterViTriKhoControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerDeleteById>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerDeleteById>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  khotpMasterViTriKhoControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterViTriKhoControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerDeleteById>>>
    
    export type KhotpMasterViTriKhoControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterViTriKhoControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterViTriKhoControllerDeleteById>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getKhotpMasterViTriKhoControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterViTriKhoControllerCreate = (
    newKhotpMasterViTriKho: NewKhotpMasterViTriKho,
 ) => {
      
      
      return drcInstance<KhotpMasterViTriKho>(
      {url: `/khotp-master-vi-tri-khos`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhotpMasterViTriKho
    },
      );
    }
  


export const getKhotpMasterViTriKhoControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerCreate>>, TError,{data: NewKhotpMasterViTriKho}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerCreate>>, TError,{data: NewKhotpMasterViTriKho}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerCreate>>, {data: NewKhotpMasterViTriKho}> = (props) => {
          const {data} = props ?? {};

          return  khotpMasterViTriKhoControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterViTriKhoControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerCreate>>>
    export type KhotpMasterViTriKhoControllerCreateMutationBody = NewKhotpMasterViTriKho
    export type KhotpMasterViTriKhoControllerCreateMutationError = ErrorType<unknown>

    export const useKhotpMasterViTriKhoControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerCreate>>, TError,{data: NewKhotpMasterViTriKho}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterViTriKhoControllerCreate>>,
        TError,
        {data: NewKhotpMasterViTriKho},
        TContext
      > => {

      const mutationOptions = getKhotpMasterViTriKhoControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterViTriKhoControllerUpdateAll = (
    khotpMasterViTriKhoPartial: KhotpMasterViTriKhoPartial,
    params?: KhotpMasterViTriKhoControllerUpdateAllParams,
 ) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-vi-tri-khos`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterViTriKhoPartial,
        params
    },
      );
    }
  


export const getKhotpMasterViTriKhoControllerUpdateAllMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerUpdateAll>>, TError,{data: KhotpMasterViTriKhoPartial;params?: KhotpMasterViTriKhoControllerUpdateAllParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerUpdateAll>>, TError,{data: KhotpMasterViTriKhoPartial;params?: KhotpMasterViTriKhoControllerUpdateAllParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerUpdateAll>>, {data: KhotpMasterViTriKhoPartial;params?: KhotpMasterViTriKhoControllerUpdateAllParams}> = (props) => {
          const {data,params} = props ?? {};

          return  khotpMasterViTriKhoControllerUpdateAll(data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterViTriKhoControllerUpdateAllMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerUpdateAll>>>
    export type KhotpMasterViTriKhoControllerUpdateAllMutationBody = KhotpMasterViTriKhoPartial
    export type KhotpMasterViTriKhoControllerUpdateAllMutationError = ErrorType<unknown>

    export const useKhotpMasterViTriKhoControllerUpdateAll = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerUpdateAll>>, TError,{data: KhotpMasterViTriKhoPartial;params?: KhotpMasterViTriKhoControllerUpdateAllParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterViTriKhoControllerUpdateAll>>,
        TError,
        {data: KhotpMasterViTriKhoPartial;params?: KhotpMasterViTriKhoControllerUpdateAllParams},
        TContext
      > => {

      const mutationOptions = getKhotpMasterViTriKhoControllerUpdateAllMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterViTriKhoControllerFind = (
    params?: KhotpMasterViTriKhoControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterViTriKhoWithRelations[]>(
      {url: `/khotp-master-vi-tri-khos`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterViTriKhoControllerFindQueryKey = (params?: KhotpMasterViTriKhoControllerFindParams,) => {
    return [`/khotp-master-vi-tri-khos`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterViTriKhoControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterViTriKhoControllerFind>>, TError = ErrorType<unknown>>(params?: KhotpMasterViTriKhoControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterViTriKhoControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerFind>>> = ({ signal }) => khotpMasterViTriKhoControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterViTriKhoControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerFind>>>
export type KhotpMasterViTriKhoControllerFindQueryError = ErrorType<unknown>

export const useKhotpMasterViTriKhoControllerFind = <TData = Awaited<ReturnType<typeof khotpMasterViTriKhoControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterViTriKhoControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterViTriKhoControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterViTriKhoControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



