import { useQldhTransDonHangControllerCountDonHangDashboard } from 'main/apis/drc/endpoints/qldh-trans-don-hang-controller/qldh-trans-don-hang-controller';
import BasicTemplate from 'main/components/templates/basic-template';
import { Page } from 'main/constants';
import { OrderStatusOptions } from 'main/constants/enums';
import { useAppDispatch } from 'main/redux/hooks';
import { clickSearchImport, ImportFilter } from 'main/redux/modules/order-management/order-management-list-slice';
import moment from 'moment';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';

function OrderDashboard() {
  const { data: orderManagementCount } = useQldhTransDonHangControllerCountDonHangDashboard({});

  const dispatch = useAppDispatch();

  const getDateRangeForUpcomingOrders = () => {
    const startDate = moment();
    const endDate = moment().add(30, 'days');
    return { startDate, endDate };
  };

  const handleSearchClick = useCallback(
    (newFilter: ImportFilter) => {
      const { startDate, endDate } = getDateRangeForUpcomingOrders();

      const searchSanPham = {
        startDate: newFilter?.searchKey ? startDate : undefined,
        endDate: newFilter?.searchKey ? endDate : undefined,
        importStatus: newFilter?.importStatus,
        enableTongDonSearchMode: newFilter?.enableTongDonSearchMode || undefined,
      };

      dispatch(clickSearchImport(searchSanPham as any));
    },
    [dispatch],
  );

  return (
    <BasicTemplate pageTitle={Page.ORDER_DASHBOARD.TITLE} pageName={Page.ORDER_DASHBOARD.NAME}>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3>Dashboard đơn đặt hàng</h3>
              </CardHeader>
              <CardBody className="header bg-info pb-6">
                <Row className="bg-info">
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h3" className="text-uppercase text-muted mb-0">
                              Đơn sắp đến hạn giao hàng
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {orderManagementCount?.tongDonSapDenHanGiaoHang}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-warning text-white rounded-circle shadow">
                              <i className="fa fa-clock" />
                            </div>
                          </Col>
                        </Row>
                        <div className="mt-3 mb-0 text-sm">
                          <Link
                            className="primary px-2"
                            onClick={() => {
                              handleSearchClick({
                                searchKey: 'startDate',
                                importStore: '',
                                origin: '',
                                importStatus: '',
                              });
                            }}
                            to={Page.ORDER_MANAGEMENT_WARNING.PATH}>
                            View
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h3" className="text-uppercase text-muted mb-0">
                              Tổng đơn
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0"> {orderManagementCount?.tongDonHang}</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                              <i className="fa fa-list" />
                            </div>
                          </Col>
                        </Row>
                        <div className="mt-3 mb-0 text-sm">
                          <Link
                            className="primary px-2"
                            onClick={() => {
                              handleSearchClick({
                                importStatus: '',
                                importStore: '',
                                origin: '',
                                enableTongDonSearchMode: true,
                              });
                            }}
                            to={Page.ORDER_MANAGEMENT_LIST.PATH}>
                            View
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h3" className="text-uppercase text-muted mb-0">
                              Đơn hoàn thành
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">924</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
                              <i className="fa fa-check-circle" /> {/* Icon dấu check cho trạng thái hoàn thành */}
                            </div>
                          </Col>
                        </Row>
                        <div className="mt-3 mb-0 text-sm">
                          <Link
                            className="primary px-2"
                            onClick={() => {
                              handleSearchClick({
                                importStatus: OrderStatusOptions[2].id,
                                importStore: '',
                                origin: '',
                              });
                            }}
                            to={Page.ORDER_MANAGEMENT_LIST.PATH}>
                            View
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h3" className="text-uppercase text-muted mb-0">
                              Đơn đang tiến hành
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {orderManagementCount?.tongDonDangTienHanh}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                              <i className="fa fa-spinner" /> {/* Icon spinner cho trạng thái đang tiến hành */}
                            </div>
                          </Col>
                        </Row>
                        <div className="mt-3 mb-0 text-sm">
                          <Link
                            className="primary px-2"
                            onClick={() => {
                              handleSearchClick({
                                importStatus: OrderStatusOptions[0].id,
                                importStore: '',
                                origin: '',
                              });
                            }}
                            to={Page.ORDER_MANAGEMENT_LIST.PATH}>
                            View
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h3" className="text-uppercase text-muted mb-0">
                              Đơn tạm dừng
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">{orderManagementCount?.tongDonTamHoan}</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-danger text-white rounded-circle shadow">
                              <i className="fa fa-pause-circle" />
                            </div>
                          </Col>
                        </Row>
                        <div className="mt-3 mb-0 text-sm">
                          <Link
                            className="primary px-2"
                            onClick={() => {
                              handleSearchClick({
                                importStatus: OrderStatusOptions[1].id,
                                importStore: '',
                                origin: '',
                              });
                            }}
                            to={Page.ORDER_MANAGEMENT_LIST.PATH}>
                            View
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                {/* </div> */}
                {/* </Container> */}
                {/* </div> */}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </BasicTemplate>
  );
}

export default OrderDashboard;
