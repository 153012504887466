export const calculateTonKhoDuKien = (soLuongThucTe: number | undefined, tonKho: number | undefined): number => {
  if (!soLuongThucTe || !tonKho) {
    return 0;
  }
  return Number(soLuongThucTe) > Number(tonKho) ? Number(tonKho) : Number(tonKho) - Number(soLuongThucTe);
};

export const calculateSoLuongThucTe = (soLuongThucTe: number | undefined, tonKho: number | undefined): number => {
  if (!soLuongThucTe || !tonKho) {
    return 0;
  }

  return Number(soLuongThucTe) > Number(tonKho) ? 0 : soLuongThucTe;
};
