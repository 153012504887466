import ExcelJS from 'exceljs';
import { KhovtXuatNhapTonControllerFind200DataItem } from 'main/apis/drc/models';
import { InventoryFilter } from 'main/redux/modules/supplies-store-inventory/supplies-store-inventory-list-slice';
import { writeDataSheet1, writeDataSheetDMListBaoCao } from './services';
import { AbstractExcelWriter } from '..';

class SuppliesStoreInventoryExcelWriter extends AbstractExcelWriter<{
  filter: InventoryFilter;
  suppliesInventoryItems: KhovtXuatNhapTonControllerFind200DataItem[];
}> {
  protected async writeDataImpl(): Promise<ExcelJS.Buffer> {
    const workbook = new ExcelJS.Workbook();

    // 1. Sheet[List DM - Báo Cáo]
    this.writeDataSheetDMListBaoCao(workbook);

    // 2. Sheet[1]
    this.writeDataSheet1(workbook);

    return await workbook.xlsx.writeBuffer();
  }

  private writeDataSheetDMListBaoCao(workbook: ExcelJS.Workbook): void {
    writeDataSheetDMListBaoCao(workbook);
  }

  private writeDataSheet1(workbook: ExcelJS.Workbook): void {
    writeDataSheet1(workbook, this.data);
  }
}

export default SuppliesStoreInventoryExcelWriter;
