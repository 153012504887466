// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {
  margin-bottom: 0.5rem !important;
}
.form-group label {
  font-weight: 600;
  color: #525f7f;
}

.card-body {
  padding: 1rem !important;
}

.col-1_5 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.col-10_5 {
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.form-control-label,
.table th {
  font-weight: 800;
  color: #525f7f;
}

.form-control {
  font-weight: 400;
}

@media (min-width: 576px) {
  .col-sm-1_5 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-sm-10_5 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
}
@media (min-width: 768px) {
  .col-md-1_5 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-md-10_5 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
}
@media (min-width: 992px) {
  .col-lg-1_5 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-lg-10_5 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
}
@media (min-width: 1200px) {
  .col-xl-1_5 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .col-xl-10_5 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
}
.col-1_5,
.col-10_5,
.col-sm-1_5,
.col-sm-10_5,
.col-md-1_5,
.col-md-10_5,
.col-lg-1_5,
.col-lg-10_5,
.col-xl-1_5,
.col-xl-10_5 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/main/index.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;AACF;AAAE;EACE,gBAAA;EACA,cAAA;AAEJ;;AACA;EACE,wBAAA;AAEF;;AACA;EACE,eAAA;EACA,gBAAA;AAEF;;AAAA;EACE,eAAA;EACA,gBAAA;AAGF;;AAAA;;EAEE,gBAAA;EACA,cAAA;AAGF;;AAAA;EACE,gBAAA;AAGF;;AAAA;EACE;IACE,eAAA;IACA,gBAAA;EAGF;EADA;IACE,eAAA;IACA,gBAAA;EAGF;AACF;AAAA;EACE;IACE,eAAA;IACA,gBAAA;EAEF;EAAA;IACE,eAAA;IACA,gBAAA;EAEF;AACF;AACA;EACE;IACE,eAAA;IACA,gBAAA;EACF;EACA;IACE,eAAA;IACA,gBAAA;EACF;AACF;AAEA;EACE;IACE,eAAA;IACA,gBAAA;EAAF;EAEA;IACE,eAAA;IACA,gBAAA;EAAF;AACF;AAGA;;;;;;;;;;EAUE,kBAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;AADF","sourcesContent":[".form-group {\n  margin-bottom: 0.5rem !important;\n  & label {\n    font-weight: 600;\n    color: #525f7f;\n  }\n}\n.card-body {\n  padding: 1rem !important;\n}\n\n.col-1_5 {\n  flex: 0 0 12.5%;\n  max-width: 12.5%;\n}\n.col-10_5 {\n  flex: 0 0 87.5%;\n  max-width: 87.5%;\n}\n\n.form-control-label,\n.table th {\n  font-weight: 800;\n  color: #525f7f;\n}\n\n.form-control {\n  font-weight: 400;\n}\n\n@media (min-width: 576px) {\n  .col-sm-1_5 {\n    flex: 0 0 12.5%;\n    max-width: 12.5%;\n  }\n  .col-sm-10_5 {\n    flex: 0 0 87.5%;\n    max-width: 87.5%;\n  }\n}\n\n@media (min-width: 768px) {\n  .col-md-1_5 {\n    flex: 0 0 12.5%;\n    max-width: 12.5%;\n  }\n  .col-md-10_5 {\n    flex: 0 0 87.5%;\n    max-width: 87.5%;\n  }\n}\n\n@media (min-width: 992px) {\n  .col-lg-1_5 {\n    flex: 0 0 12.5%;\n    max-width: 12.5%;\n  }\n  .col-lg-10_5 {\n    flex: 0 0 87.5%;\n    max-width: 87.5%;\n  }\n}\n\n@media (min-width: 1200px) {\n  .col-xl-1_5 {\n    flex: 0 0 12.5%;\n    max-width: 12.5%;\n  }\n  .col-xl-10_5 {\n    flex: 0 0 87.5%;\n    max-width: 87.5%;\n  }\n}\n\n.col-1_5,\n.col-10_5,\n.col-sm-1_5,\n.col-sm-10_5,\n.col-md-1_5,\n.col-md-10_5,\n.col-lg-1_5,\n.col-lg-10_5,\n.col-xl-1_5,\n.col-xl-10_5 {\n  position: relative;\n  width: 100%;\n  padding-right: 15px;\n  padding-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
