const Color = {
  BLACK: '#000000',
  GRAY: '#808080',
  GREEN: '#008000',
  RED: '#FF0000',
  YELLOW: '#FFFF00',
  WHITE: '#ffffff',
  LIGHT_GRAY: '#D3D3D3',
  LIGHT_GREEN: '#c8e6c9',
  ORANGE: `#FFA500`,
};

export default Color;
