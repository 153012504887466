import { Autocomplete, TextField } from '@mui/material';
import { SuppliesFilter, SuppliesFilterOptions } from 'main/hooks/useSuppliesOptions';
import React from 'react';

export type onChangeType = SuppliesFilter & {
  soLuong?: number;
  tonKho?: number;
  soLo?: number;
  maLo?: string;
  nguonGoc?: number;
  idNhap?: number;
  thoiHanLuuKho?: number | null;
  soLuongThucTe?: number;
  soLuongYeuCau?: number;
  ngayHetHanLK?: string;
};

interface AutocompleteCellProps {
  options: SuppliesFilterOptions;
  value: SuppliesFilter | null;
  onChange: (newValue: onChangeType | null) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  getOptionLabel?: (option: any) => string;
  placeholder?: string;
}

const AutocompleteCell: React.FC<AutocompleteCellProps> = ({
  options,
  value,
  onChange,
  inputRef,
  getOptionLabel,
  placeholder,
}) => {
  return (
    <Autocomplete
      getOptionKey={(e: any) => e.id}
      options={options}
      // value={value}
      getOptionLabel={getOptionLabel}
      onChange={(_event, newValue) => onChange(newValue)}
      onKeyDown={(e) => {
        if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter' || e.key === ' ') {
          e.stopPropagation();
        }
      }}
      style={{ whiteSpace: 'normal', overflow: 'visible', lineHeight: 2.5 }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          variant="outlined"
          size="medium"
          placeholder={placeholder}
          value={placeholder}
        />
      )}
    />
  );
};

export default AutocompleteCell;
