// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.disable-gray {
  background-color: #d3d3d3 !important;
}
.custom-pagination p {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.error-cell {
  background-color: #ffebee;
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/main/components/atoms/mui-data-grid/index.css"],"names":[],"mappings":";AACA;EACE,oCAAoC;AACtC;AACA;EACE,2BAA2B;EAC3B,8BAA8B;AAChC;AACA;EACE,yBAAyB;EACzB,UAAU;AACZ","sourcesContent":["\n.disable-gray {\n  background-color: #d3d3d3 !important;\n}\n.custom-pagination p {\n  margin-top: auto !important;\n  margin-bottom: auto !important;\n}\n.error-cell {\n  background-color: #ffebee;\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
