/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhotpMasterBtpControllerCountParams,
  KhotpMasterBtpControllerFindByIdParams,
  KhotpMasterBtpControllerFindParams,
  KhotpMasterSanPham,
  KhotpMasterSanPhamPartial,
  KhotpMasterSanPhamWithRelations,
  LoopbackCount,
  NewKhotpMasterSanPham
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khotpMasterBtpControllerCount = (
    params?: KhotpMasterBtpControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-btps/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterBtpControllerCountQueryKey = (params?: KhotpMasterBtpControllerCountParams,) => {
    return [`/khotp-master-btps/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterBtpControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterBtpControllerCount>>, TError = ErrorType<unknown>>(params?: KhotpMasterBtpControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterBtpControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterBtpControllerCount>>> = ({ signal }) => khotpMasterBtpControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterBtpControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterBtpControllerCount>>>
export type KhotpMasterBtpControllerCountQueryError = ErrorType<unknown>

export const useKhotpMasterBtpControllerCount = <TData = Awaited<ReturnType<typeof khotpMasterBtpControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterBtpControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterBtpControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterBtpControllerReplaceById = (
    id: string,
    khotpMasterSanPham: KhotpMasterSanPham,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-btps/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterSanPham
    },
      );
    }
  


export const getKhotpMasterBtpControllerReplaceByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerReplaceById>>, TError,{id: string;data: KhotpMasterSanPham}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerReplaceById>>, TError,{id: string;data: KhotpMasterSanPham}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterBtpControllerReplaceById>>, {id: string;data: KhotpMasterSanPham}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterBtpControllerReplaceById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterBtpControllerReplaceByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterBtpControllerReplaceById>>>
    export type KhotpMasterBtpControllerReplaceByIdMutationBody = KhotpMasterSanPham
    export type KhotpMasterBtpControllerReplaceByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterBtpControllerReplaceById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerReplaceById>>, TError,{id: string;data: KhotpMasterSanPham}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterBtpControllerReplaceById>>,
        TError,
        {id: string;data: KhotpMasterSanPham},
        TContext
      > => {

      const mutationOptions = getKhotpMasterBtpControllerReplaceByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterBtpControllerUpdateById = (
    id: string,
    khotpMasterSanPhamPartial: KhotpMasterSanPhamPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-btps/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterSanPhamPartial
    },
      );
    }
  


export const getKhotpMasterBtpControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerUpdateById>>, TError,{id: string;data: KhotpMasterSanPhamPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerUpdateById>>, TError,{id: string;data: KhotpMasterSanPhamPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterBtpControllerUpdateById>>, {id: string;data: KhotpMasterSanPhamPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterBtpControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterBtpControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterBtpControllerUpdateById>>>
    export type KhotpMasterBtpControllerUpdateByIdMutationBody = KhotpMasterSanPhamPartial
    export type KhotpMasterBtpControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterBtpControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerUpdateById>>, TError,{id: string;data: KhotpMasterSanPhamPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterBtpControllerUpdateById>>,
        TError,
        {id: string;data: KhotpMasterSanPhamPartial},
        TContext
      > => {

      const mutationOptions = getKhotpMasterBtpControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterBtpControllerFindById = (
    id: string,
    params?: KhotpMasterBtpControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterSanPhamWithRelations>(
      {url: `/khotp-master-btps/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterBtpControllerFindByIdQueryKey = (id: string,
    params?: KhotpMasterBtpControllerFindByIdParams,) => {
    return [`/khotp-master-btps/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterBtpControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterBtpControllerFindById>>, TError = ErrorType<unknown>>(id: string,
    params?: KhotpMasterBtpControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterBtpControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterBtpControllerFindById>>> = ({ signal }) => khotpMasterBtpControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterBtpControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterBtpControllerFindById>>>
export type KhotpMasterBtpControllerFindByIdQueryError = ErrorType<unknown>

export const useKhotpMasterBtpControllerFindById = <TData = Awaited<ReturnType<typeof khotpMasterBtpControllerFindById>>, TError = ErrorType<unknown>>(
 id: string,
    params?: KhotpMasterBtpControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterBtpControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterBtpControllerDeleteById = (
    id: string,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-btps/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhotpMasterBtpControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerDeleteById>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterBtpControllerDeleteById>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  khotpMasterBtpControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterBtpControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterBtpControllerDeleteById>>>
    
    export type KhotpMasterBtpControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterBtpControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterBtpControllerDeleteById>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getKhotpMasterBtpControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterBtpControllerCreate = (
    newKhotpMasterSanPham: NewKhotpMasterSanPham,
 ) => {
      
      
      return drcInstance<KhotpMasterSanPham>(
      {url: `/khotp-master-btps`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhotpMasterSanPham
    },
      );
    }
  


export const getKhotpMasterBtpControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerCreate>>, TError,{data: NewKhotpMasterSanPham}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerCreate>>, TError,{data: NewKhotpMasterSanPham}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterBtpControllerCreate>>, {data: NewKhotpMasterSanPham}> = (props) => {
          const {data} = props ?? {};

          return  khotpMasterBtpControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterBtpControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterBtpControllerCreate>>>
    export type KhotpMasterBtpControllerCreateMutationBody = NewKhotpMasterSanPham
    export type KhotpMasterBtpControllerCreateMutationError = ErrorType<unknown>

    export const useKhotpMasterBtpControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerCreate>>, TError,{data: NewKhotpMasterSanPham}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterBtpControllerCreate>>,
        TError,
        {data: NewKhotpMasterSanPham},
        TContext
      > => {

      const mutationOptions = getKhotpMasterBtpControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterBtpControllerFind = (
    params?: KhotpMasterBtpControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterSanPhamWithRelations[]>(
      {url: `/khotp-master-btps`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterBtpControllerFindQueryKey = (params?: KhotpMasterBtpControllerFindParams,) => {
    return [`/khotp-master-btps`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterBtpControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterBtpControllerFind>>, TError = ErrorType<unknown>>(params?: KhotpMasterBtpControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterBtpControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterBtpControllerFind>>> = ({ signal }) => khotpMasterBtpControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterBtpControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterBtpControllerFind>>>
export type KhotpMasterBtpControllerFindQueryError = ErrorType<unknown>

export const useKhotpMasterBtpControllerFind = <TData = Awaited<ReturnType<typeof khotpMasterBtpControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterBtpControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterBtpControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterBtpControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



