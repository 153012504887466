import { FormikProps } from 'formik';
import { find } from 'lodash';
import { KhotpMasterKhachHangWithRelations, NewQldhTransDonHang } from 'main/apis/drc/models';
import { DatePicker } from 'main/components/atoms/date';
import { Select2Box } from 'main/components/molecules/selectbox';
import { FieldName, OrderStatusOptions } from 'main/constants/enums';
import { YeuCauGiuHangOptions } from 'main/constants/enums/order-management';
import moment from 'moment';
import { ChangeEvent } from 'react';
import { Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';

export type OrderManagementInfo = NewQldhTransDonHang & {
  khuVucKh?: string;
  idDonHang?: string;
  id?: string;
  maKhoXuat?: string;
};

type Props = {
  formikProps: FormikProps<OrderManagementInfo>;
  productExportCustomer?: KhotpMasterKhachHangWithRelations[];
  productExportCustomersUnitOptions?: any[];
};

function OrderManagementAddInfo({
  formikProps: { values, errors, handleChange, setFieldValue, setValues },
  productExportCustomer,
  productExportCustomersUnitOptions,
}: Props) {
  return (
    <Form>
      <Row>
        <Col md={12} lg={5}>
          <FormGroup className="row mb-2">
            <Label className="form-control-label" md="4">
              Khách hàng&nbsp;
              <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Col md="8">
              <Select2Box
                className="form-control"
                options={{ placeholder: 'Chọn khách hàng' }}
                value={values.maKH}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  const newValue = e.target.value;
                  setValues({
                    ...values,
                    maKH: newValue,
                    khuVucKh: find(productExportCustomer, (item) => item.maKh === newValue)?.khuVucKh || '',
                  });
                }}
                invalid={errors.maKH ? 'true' : undefined}
                data={productExportCustomersUnitOptions}
              />
              {errors.maKH && <FormText color="danger">{errors.maKH}</FormText>}
            </Col>
          </FormGroup>
        </Col>
        <Col md={12} lg={5}>
          <FormGroup className="row mb-2">
            <Label className="form-control-label" md="4">
              ID đơn hàng
            </Label>
            <Col md="8">
              <Input
                type="text"
                name={FieldName.DON_HANG_ID}
                placeholder="Nhập ID đơn hàng"
                value={values.donHangId || ''}
                onChange={handleChange}
                disabled
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={5}>
          <FormGroup className="row mb-2">
            <Label className="form-control-label" md="4">
              Vùng&nbsp;
              <i className="fa fa-asterisk fa-sm" />
            </Label>
            <Col md="8">
              <Input
                type="text"
                name={FieldName.KHU_VUC_KH}
                placeholder="Vùng khách hàng"
                value={values.khuVucKh || ''}
                onChange={handleChange}
                disabled
              />
            </Col>
          </FormGroup>
        </Col>
        <Col md={12} lg={5}>
          <FormGroup className="row mb-2">
            <Label className="form-control-label" md="4">
              Ngày đặt hàng&nbsp;
              <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Col md="8">
              <DatePicker
                inputProps={{
                  placeholder: 'Chọn ngày',
                  className: `form-control ${!!errors.ngayDatHang ? 'is-invalid' : ''}`,
                }}
                // value={values.ngayDatHang}
                value={values.ngayDatHang ? moment(values.ngayDatHang) : undefined}
                onChange={(e) => setFieldValue(FieldName.NGAY_DAT_HANG, e ? moment(e) : undefined)}
              />
              {errors.ngayDatHang && <FormText color="danger">{errors.ngayDatHang}</FormText>}
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={5}>
          <FormGroup className="row mb-2">
            <Label className="form-control-label" md="4">
              Yêu cầu giữ hàng &nbsp;
              <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Col md="8">
              <Select2Box
                className="form-control"
                options={{ placeholder: 'Chọn yêu cầu giữ hàng' }}
                // onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                //   setFieldValue(FieldName.YEU_CAU_GIU_HANG, e.target.value)
                // }
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  const newValue = e.target.value;
                  setValues({
                    ...values,
                    yeuCauGiuHang: newValue,
                  });
                }}
                value={values.yeuCauGiuHang}
                placeholder
                data={YeuCauGiuHangOptions}
              />
              {errors.yeuCauGiuHang && <FormText color="danger">{errors.yeuCauGiuHang}</FormText>}
            </Col>
          </FormGroup>
        </Col>
        <Col md={12} lg={5}>
          <FormGroup className="row mb-2">
            <Label className="form-control-label" md="4">
              Người Tạo&nbsp;
              <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Col md="8">
              <Input
                type="text"
                name={FieldName.NGUOI_TAO}
                placeholder="Nhập người tạo phiếu"
                value={values.nguoiTao || ''}
                onChange={handleChange}
                invalid={!!errors.nguoiTao}
              />
              {errors.nguoiTao && <FormText color="danger">{errors.nguoiTao}</FormText>}
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={5}>
          <FormGroup className="row">
            <Label className="form-control-label" md="4">
              Trạng thái
              <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Col md="8">
              <Select2Box
                className="form-control"
                options={{ placeholder: 'Chọn trạng thái' }}
                value={values.trangThai}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setFieldValue(FieldName.TRANG_THAI, e.target.value)}
                data={OrderStatusOptions}
                invalid={errors.trangThai ? 'true' : undefined}
              />
              {errors.trangThai && <FormText color="danger">{errors.trangThai}</FormText>}
            </Col>
          </FormGroup>
        </Col>

        <Col md={12} lg={5}>
          <FormGroup className="row mb-2">
            <Label className="form-control-label" md="4">
              Người yêu cầu&nbsp;
              <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Col md="8">
              <Input
                type="text"
                name={FieldName.NGUOI_YEU_CAU}
                placeholder="Nhập người yêu cầu"
                value={values.nguoiYeuCau}
                onChange={handleChange}
                invalid={!!errors.nguoiYeuCau}
              />
              {errors.nguoiYeuCau && <FormText color="danger">{errors.nguoiYeuCau}</FormText>}
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}

export default OrderManagementAddInfo;
