/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  DmMasterBoDinhMuc,
  DmMasterBoDinhMucControllerCountParams,
  DmMasterBoDinhMucControllerFindByIdParams,
  DmMasterBoDinhMucControllerFindCompareParams,
  DmMasterBoDinhMucControllerFindDetailItems200,
  DmMasterBoDinhMucControllerFindDetailItemsParams,
  DmMasterBoDinhMucControllerFindParams,
  DmMasterBoDinhMucPartial,
  DmMasterBoDinhMucWithRelations,
  DmTransDinhMucCompareItem,
  LoopbackCount,
  NewDmMasterBoDinhMuc
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const dmMasterBoDinhMucControllerFindCompare = (
    params?: DmMasterBoDinhMucControllerFindCompareParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<DmTransDinhMucCompareItem[]>(
      {url: `/dm-master-bodinhmucs/compare`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getDmMasterBoDinhMucControllerFindCompareQueryKey = (params?: DmMasterBoDinhMucControllerFindCompareParams,) => {
    return [`/dm-master-bodinhmucs/compare`, ...(params ? [params]: [])] as const;
    }

    
export const getDmMasterBoDinhMucControllerFindCompareQueryOptions = <TData = Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindCompare>>, TError = ErrorType<unknown>>(params?: DmMasterBoDinhMucControllerFindCompareParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindCompare>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDmMasterBoDinhMucControllerFindCompareQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindCompare>>> = ({ signal }) => dmMasterBoDinhMucControllerFindCompare(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindCompare>>, TError, TData> & { queryKey: QueryKey }
}

export type DmMasterBoDinhMucControllerFindCompareQueryResult = NonNullable<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindCompare>>>
export type DmMasterBoDinhMucControllerFindCompareQueryError = ErrorType<unknown>

export const useDmMasterBoDinhMucControllerFindCompare = <TData = Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindCompare>>, TError = ErrorType<unknown>>(
 params?: DmMasterBoDinhMucControllerFindCompareParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindCompare>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDmMasterBoDinhMucControllerFindCompareQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const dmMasterBoDinhMucControllerCount = (
    params?: DmMasterBoDinhMucControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/dm-master-bodinhmucs/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getDmMasterBoDinhMucControllerCountQueryKey = (params?: DmMasterBoDinhMucControllerCountParams,) => {
    return [`/dm-master-bodinhmucs/count`, ...(params ? [params]: [])] as const;
    }

    
export const getDmMasterBoDinhMucControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof dmMasterBoDinhMucControllerCount>>, TError = ErrorType<unknown>>(params?: DmMasterBoDinhMucControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDmMasterBoDinhMucControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerCount>>> = ({ signal }) => dmMasterBoDinhMucControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type DmMasterBoDinhMucControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerCount>>>
export type DmMasterBoDinhMucControllerCountQueryError = ErrorType<unknown>

export const useDmMasterBoDinhMucControllerCount = <TData = Awaited<ReturnType<typeof dmMasterBoDinhMucControllerCount>>, TError = ErrorType<unknown>>(
 params?: DmMasterBoDinhMucControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDmMasterBoDinhMucControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const dmMasterBoDinhMucControllerFindDetailItems = (
    params?: DmMasterBoDinhMucControllerFindDetailItemsParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<DmMasterBoDinhMucControllerFindDetailItems200>(
      {url: `/dm-master-bodinhmucs/dinhmucitems`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getDmMasterBoDinhMucControllerFindDetailItemsQueryKey = (params?: DmMasterBoDinhMucControllerFindDetailItemsParams,) => {
    return [`/dm-master-bodinhmucs/dinhmucitems`, ...(params ? [params]: [])] as const;
    }

    
export const getDmMasterBoDinhMucControllerFindDetailItemsQueryOptions = <TData = Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindDetailItems>>, TError = ErrorType<unknown>>(params?: DmMasterBoDinhMucControllerFindDetailItemsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindDetailItems>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDmMasterBoDinhMucControllerFindDetailItemsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindDetailItems>>> = ({ signal }) => dmMasterBoDinhMucControllerFindDetailItems(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindDetailItems>>, TError, TData> & { queryKey: QueryKey }
}

export type DmMasterBoDinhMucControllerFindDetailItemsQueryResult = NonNullable<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindDetailItems>>>
export type DmMasterBoDinhMucControllerFindDetailItemsQueryError = ErrorType<unknown>

export const useDmMasterBoDinhMucControllerFindDetailItems = <TData = Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindDetailItems>>, TError = ErrorType<unknown>>(
 params?: DmMasterBoDinhMucControllerFindDetailItemsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindDetailItems>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDmMasterBoDinhMucControllerFindDetailItemsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const dmMasterBoDinhMucControllerUpdateById = (
    id: number,
    dmMasterBoDinhMucPartial: DmMasterBoDinhMucPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/dm-master-bodinhmucs/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: dmMasterBoDinhMucPartial
    },
      );
    }
  


export const getDmMasterBoDinhMucControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerUpdateById>>, TError,{id: number;data: DmMasterBoDinhMucPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerUpdateById>>, TError,{id: number;data: DmMasterBoDinhMucPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerUpdateById>>, {id: number;data: DmMasterBoDinhMucPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  dmMasterBoDinhMucControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DmMasterBoDinhMucControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerUpdateById>>>
    export type DmMasterBoDinhMucControllerUpdateByIdMutationBody = DmMasterBoDinhMucPartial
    export type DmMasterBoDinhMucControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useDmMasterBoDinhMucControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerUpdateById>>, TError,{id: number;data: DmMasterBoDinhMucPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof dmMasterBoDinhMucControllerUpdateById>>,
        TError,
        {id: number;data: DmMasterBoDinhMucPartial},
        TContext
      > => {

      const mutationOptions = getDmMasterBoDinhMucControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const dmMasterBoDinhMucControllerFindById = (
    id: number,
    params?: DmMasterBoDinhMucControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<DmMasterBoDinhMucWithRelations>(
      {url: `/dm-master-bodinhmucs/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getDmMasterBoDinhMucControllerFindByIdQueryKey = (id: number,
    params?: DmMasterBoDinhMucControllerFindByIdParams,) => {
    return [`/dm-master-bodinhmucs/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getDmMasterBoDinhMucControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindById>>, TError = ErrorType<unknown>>(id: number,
    params?: DmMasterBoDinhMucControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDmMasterBoDinhMucControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindById>>> = ({ signal }) => dmMasterBoDinhMucControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type DmMasterBoDinhMucControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindById>>>
export type DmMasterBoDinhMucControllerFindByIdQueryError = ErrorType<unknown>

export const useDmMasterBoDinhMucControllerFindById = <TData = Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindById>>, TError = ErrorType<unknown>>(
 id: number,
    params?: DmMasterBoDinhMucControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDmMasterBoDinhMucControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const dmMasterBoDinhMucControllerDeleteById = (
    id: number,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/dm-master-bodinhmucs/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDmMasterBoDinhMucControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerDeleteById>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerDeleteById>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  dmMasterBoDinhMucControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DmMasterBoDinhMucControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerDeleteById>>>
    
    export type DmMasterBoDinhMucControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useDmMasterBoDinhMucControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof dmMasterBoDinhMucControllerDeleteById>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDmMasterBoDinhMucControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const dmMasterBoDinhMucControllerCreate = (
    newDmMasterBoDinhMuc: NewDmMasterBoDinhMuc,
 ) => {
      
      
      return drcInstance<DmMasterBoDinhMucWithRelations>(
      {url: `/dm-master-bodinhmucs`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newDmMasterBoDinhMuc
    },
      );
    }
  


export const getDmMasterBoDinhMucControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerCreate>>, TError,{data: NewDmMasterBoDinhMuc}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerCreate>>, TError,{data: NewDmMasterBoDinhMuc}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerCreate>>, {data: NewDmMasterBoDinhMuc}> = (props) => {
          const {data} = props ?? {};

          return  dmMasterBoDinhMucControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DmMasterBoDinhMucControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerCreate>>>
    export type DmMasterBoDinhMucControllerCreateMutationBody = NewDmMasterBoDinhMuc
    export type DmMasterBoDinhMucControllerCreateMutationError = ErrorType<unknown>

    export const useDmMasterBoDinhMucControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerCreate>>, TError,{data: NewDmMasterBoDinhMuc}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof dmMasterBoDinhMucControllerCreate>>,
        TError,
        {data: NewDmMasterBoDinhMuc},
        TContext
      > => {

      const mutationOptions = getDmMasterBoDinhMucControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const dmMasterBoDinhMucControllerFind = (
    params?: DmMasterBoDinhMucControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<DmMasterBoDinhMuc[]>(
      {url: `/dm-master-bodinhmucs`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getDmMasterBoDinhMucControllerFindQueryKey = (params?: DmMasterBoDinhMucControllerFindParams,) => {
    return [`/dm-master-bodinhmucs`, ...(params ? [params]: [])] as const;
    }

    
export const getDmMasterBoDinhMucControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFind>>, TError = ErrorType<unknown>>(params?: DmMasterBoDinhMucControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDmMasterBoDinhMucControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFind>>> = ({ signal }) => dmMasterBoDinhMucControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type DmMasterBoDinhMucControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFind>>>
export type DmMasterBoDinhMucControllerFindQueryError = ErrorType<unknown>

export const useDmMasterBoDinhMucControllerFind = <TData = Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFind>>, TError = ErrorType<unknown>>(
 params?: DmMasterBoDinhMucControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dmMasterBoDinhMucControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDmMasterBoDinhMucControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



