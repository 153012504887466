import ExcelJS from 'exceljs';

const SHEET_RANGE_CONSTANTS = {
  originCell: [2, 1],
  columns: {
    STT: { width: 8, alignment: { horizontal: 'center', vertical: 'middle' } },
    'Nội Dung': { width: 64, alignment: undefined },
    'GHI CHÚ': { width: 16, alignment: undefined },
  },
  rows: {
    header: { height: 32 },
  },
  data: [
    {
      STT: '1',
      'Nội Dung': 'Báo cáo nhập xuất tồn trong kỳ',
      'GHI CHÚ': '',
    },
    {
      STT: '',
      'Nội Dung': 'Báo cáo bảng kê chi tiết giao dịch xuất theo phiếu xuất hằng ngày',
      'GHI CHÚ': '',
    },
    {
      STT: '2',
      'Nội Dung': 'Báo cáo Tổng Nhập trong kỳ',
      'GHI CHÚ': '',
    },
    {
      STT: '3',
      'Nội Dung': 'Báo cáo Tổng xuất trong kỳ (có thể lựa chọn xuất chi tiết cho từng đơn vị)',
      'GHI CHÚ': '',
    },
    {
      STT: '4',
      'Nội Dung': 'Báo cáo Chi tiết Lô hàng nhập trong kỳ',
      'GHI CHÚ': '',
    },
    {
      STT: '5',
      'Nội Dung': 'Báo cáo phiếu xuất hằng ngày - trong kỳ tháng, năm',
      'GHI CHÚ': '',
    },
    {
      STT: '6',
      'Nội Dung': 'Báo cáo phiếu nhập hằng ngày - trong kỳ tháng, năm',
      'GHI CHÚ': '',
    },
    {
      STT: '7',
      'Nội Dung': 'Báo cáo DM từng lô hàng theo nhóm',
      'GHI CHÚ': '',
    },
    {
      STT: '8',
      'Nội Dung': 'Báo cáo Giấy Báo Vật tư Nhập  Về',
      'GHI CHÚ': '',
    },
    ...new Array(6).fill({
      STT: '',
      'Nội Dung': '',
      'GHI CHÚ': '',
    }),
  ],
};

export function writeDataSheetDMListBaoCao(workbook: ExcelJS.Workbook): void {
  const sheetName = 'List DM - Báo Cáo';
  const worksheet = workbook.addWorksheet(sheetName);
  const originCell = SHEET_RANGE_CONSTANTS.originCell;
  const columns = SHEET_RANGE_CONSTANTS.columns;
  const rows = SHEET_RANGE_CONSTANTS.rows;
  const jsonData = SHEET_RANGE_CONSTANTS.data;
  const headers = Object.keys(jsonData[0]);

  // 1. Write headers
  worksheet.getRow(originCell[0]).height = rows.header.height;
  headers.forEach((header, index) => {
    worksheet.getColumn(index + 1).width = columns[header as keyof typeof columns].width;

    const cell = worksheet.getCell(originCell[0], originCell[1] + index);
    cell.value = header;
    cell.style.font = { bold: true };
    cell.style.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.style.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  });

  // 2. Write data
  const originJsonDataCell = [originCell[0] + 1, originCell[1]];
  jsonData.forEach((data, rowIndex) => {
    headers.forEach((key, colIndex) => {
      const cell = worksheet.getCell(originJsonDataCell[0] + rowIndex, originJsonDataCell[1] + colIndex);
      cell.value = data[key as keyof typeof data];
      const alignment = columns[key as keyof typeof columns].alignment;
      if (alignment) {
        cell.style.alignment = alignment as Partial<ExcelJS.Alignment>;
      }
      cell.style.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  });
}
