/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  LoopbackCount,
  NewQldhTransYeuCauSanXuat,
  QldhTransYeuCauSanXuat,
  QldhTransYeuCauSanXuatControllerCountParams,
  QldhTransYeuCauSanXuatControllerFindByIdParams,
  QldhTransYeuCauSanXuatControllerFindParams,
  QldhTransYeuCauSanXuatWithRelations
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const qldhTransYeuCauSanXuatControllerCount = (
    params?: QldhTransYeuCauSanXuatControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/qldh-trans-yeu-cau-san-xuats/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getQldhTransYeuCauSanXuatControllerCountQueryKey = (params?: QldhTransYeuCauSanXuatControllerCountParams,) => {
    return [`/qldh-trans-yeu-cau-san-xuats/count`, ...(params ? [params]: [])] as const;
    }

    
export const getQldhTransYeuCauSanXuatControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerCount>>, TError = ErrorType<unknown>>(params?: QldhTransYeuCauSanXuatControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQldhTransYeuCauSanXuatControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerCount>>> = ({ signal }) => qldhTransYeuCauSanXuatControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type QldhTransYeuCauSanXuatControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerCount>>>
export type QldhTransYeuCauSanXuatControllerCountQueryError = ErrorType<unknown>

export const useQldhTransYeuCauSanXuatControllerCount = <TData = Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerCount>>, TError = ErrorType<unknown>>(
 params?: QldhTransYeuCauSanXuatControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQldhTransYeuCauSanXuatControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const qldhTransYeuCauSanXuatControllerUpdateById = (
    id: number,
    qldhTransYeuCauSanXuat: QldhTransYeuCauSanXuat,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/qldh-trans-yeu-cau-san-xuats/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: qldhTransYeuCauSanXuat
    },
      );
    }
  


export const getQldhTransYeuCauSanXuatControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerUpdateById>>, TError,{id: number;data: QldhTransYeuCauSanXuat}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerUpdateById>>, TError,{id: number;data: QldhTransYeuCauSanXuat}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerUpdateById>>, {id: number;data: QldhTransYeuCauSanXuat}> = (props) => {
          const {id,data} = props ?? {};

          return  qldhTransYeuCauSanXuatControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QldhTransYeuCauSanXuatControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerUpdateById>>>
    export type QldhTransYeuCauSanXuatControllerUpdateByIdMutationBody = QldhTransYeuCauSanXuat
    export type QldhTransYeuCauSanXuatControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useQldhTransYeuCauSanXuatControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerUpdateById>>, TError,{id: number;data: QldhTransYeuCauSanXuat}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerUpdateById>>,
        TError,
        {id: number;data: QldhTransYeuCauSanXuat},
        TContext
      > => {

      const mutationOptions = getQldhTransYeuCauSanXuatControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const qldhTransYeuCauSanXuatControllerFindById = (
    id: number,
    params?: QldhTransYeuCauSanXuatControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<QldhTransYeuCauSanXuatWithRelations>(
      {url: `/qldh-trans-yeu-cau-san-xuats/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getQldhTransYeuCauSanXuatControllerFindByIdQueryKey = (id: number,
    params?: QldhTransYeuCauSanXuatControllerFindByIdParams,) => {
    return [`/qldh-trans-yeu-cau-san-xuats/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getQldhTransYeuCauSanXuatControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerFindById>>, TError = ErrorType<unknown>>(id: number,
    params?: QldhTransYeuCauSanXuatControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQldhTransYeuCauSanXuatControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerFindById>>> = ({ signal }) => qldhTransYeuCauSanXuatControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type QldhTransYeuCauSanXuatControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerFindById>>>
export type QldhTransYeuCauSanXuatControllerFindByIdQueryError = ErrorType<unknown>

export const useQldhTransYeuCauSanXuatControllerFindById = <TData = Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerFindById>>, TError = ErrorType<unknown>>(
 id: number,
    params?: QldhTransYeuCauSanXuatControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQldhTransYeuCauSanXuatControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const qldhTransYeuCauSanXuatControllerDeleteById = (
    id: number,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/qldh-trans-yeu-cau-san-xuats/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getQldhTransYeuCauSanXuatControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerDeleteById>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerDeleteById>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  qldhTransYeuCauSanXuatControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QldhTransYeuCauSanXuatControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerDeleteById>>>
    
    export type QldhTransYeuCauSanXuatControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useQldhTransYeuCauSanXuatControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerDeleteById>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getQldhTransYeuCauSanXuatControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const qldhTransYeuCauSanXuatControllerCreate = (
    newQldhTransYeuCauSanXuat: NewQldhTransYeuCauSanXuat,
 ) => {
      
      
      return drcInstance<QldhTransYeuCauSanXuatWithRelations>(
      {url: `/qldh-trans-yeu-cau-san-xuats`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newQldhTransYeuCauSanXuat
    },
      );
    }
  


export const getQldhTransYeuCauSanXuatControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerCreate>>, TError,{data: NewQldhTransYeuCauSanXuat}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerCreate>>, TError,{data: NewQldhTransYeuCauSanXuat}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerCreate>>, {data: NewQldhTransYeuCauSanXuat}> = (props) => {
          const {data} = props ?? {};

          return  qldhTransYeuCauSanXuatControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QldhTransYeuCauSanXuatControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerCreate>>>
    export type QldhTransYeuCauSanXuatControllerCreateMutationBody = NewQldhTransYeuCauSanXuat
    export type QldhTransYeuCauSanXuatControllerCreateMutationError = ErrorType<unknown>

    export const useQldhTransYeuCauSanXuatControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerCreate>>, TError,{data: NewQldhTransYeuCauSanXuat}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerCreate>>,
        TError,
        {data: NewQldhTransYeuCauSanXuat},
        TContext
      > => {

      const mutationOptions = getQldhTransYeuCauSanXuatControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const qldhTransYeuCauSanXuatControllerFind = (
    params?: QldhTransYeuCauSanXuatControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<QldhTransYeuCauSanXuat[]>(
      {url: `/qldh-trans-yeu-cau-san-xuats`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getQldhTransYeuCauSanXuatControllerFindQueryKey = (params?: QldhTransYeuCauSanXuatControllerFindParams,) => {
    return [`/qldh-trans-yeu-cau-san-xuats`, ...(params ? [params]: [])] as const;
    }

    
export const getQldhTransYeuCauSanXuatControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerFind>>, TError = ErrorType<unknown>>(params?: QldhTransYeuCauSanXuatControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQldhTransYeuCauSanXuatControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerFind>>> = ({ signal }) => qldhTransYeuCauSanXuatControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type QldhTransYeuCauSanXuatControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerFind>>>
export type QldhTransYeuCauSanXuatControllerFindQueryError = ErrorType<unknown>

export const useQldhTransYeuCauSanXuatControllerFind = <TData = Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerFind>>, TError = ErrorType<unknown>>(
 params?: QldhTransYeuCauSanXuatControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qldhTransYeuCauSanXuatControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQldhTransYeuCauSanXuatControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



