import { useKhotpTransLenhNhapControllerCreate } from 'main/apis/drc/endpoints/khotp-trans-lenh-nhap-controller/khotp-trans-lenh-nhap-controller';
import {
  KhotpTransLenhNhapRawDetailWithRelations,
  KhotpTransLenhNhapWithRelations,
  KhotpXuatNhapTonControllerFind200,
  KhotpXuatNhapTonControllerFind200DataItem,
} from 'main/apis/drc/models';
import { BasicSpinner, TransparentSpinner } from 'main/components/atoms/spiner';
import EditPermissionAction from 'main/components/molecules/permission-action/EditPermissionAction';
import { Page } from 'main/constants';
import { FieldName, ProductExcelStatus } from 'main/constants/enums';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  selectInventoryTableExcludeSelectedRows,
  updateInventoryTable,
} from 'main/redux/modules/product-store-inventory/product-store-inventory-list-slice';
import { DateUtil } from 'main/utils';
import moment from 'moment';
import { useCallback, useState } from 'react';
import BootstrapTable, { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import tableConfig from './table-config';
import UploadProductInventoryExcelModal from './upload-product-inventory-excel-modal';

type Props = {
  data?: KhotpXuatNhapTonControllerFind200;
  dataIsLoading: boolean;
};

function ProductStoreInventoryTable({
  data: { data, count: dataCount } = { data: [], count: 0 },
  dataIsLoading,
}: Props) {
  // React Router
  const navigate = useNavigate();

  // Redux
  const dispatch = useAppDispatch();
  const { page, sizePerPage } = useAppSelector(selectInventoryTableExcludeSelectedRows);

  // React State
  const [isOpenUploadModal, setOpenUploadModal] = useState(false);

  // React Query
  const { mutateAsync: createdProductStoreExcelMutateAsync, isPending: createdProductStoreExcelIsPending } =
    useKhotpTransLenhNhapControllerCreate();

  // React Function
  const handleTableChange = useCallback(
    (_: TableChangeType, newState: TableChangeState<KhotpXuatNhapTonControllerFind200DataItem>) => {
      const { page, sizePerPage } = newState;

      dispatch(
        updateInventoryTable({
          page,
          sizePerPage,
        }),
      );
    },
    [dispatch],
  );

  const toggleUploadModal = useCallback(() => setOpenUploadModal((isOpenUploadModal) => !isOpenUploadModal), []);

  const handleUploadModalConfirmClickCallback = useCallback(
    (productStoreExcel: KhotpTransLenhNhapWithRelations) => {
      // Close modal
      toggleUploadModal();

      // Create new product store excel
      createdProductStoreExcelMutateAsync({
        data: {
          ...productStoreExcel,
          ngayNhap: DateUtil.toDateISOString(moment()),
          status: ProductExcelStatus.new.id,
          rawDetails: normalizeProductRawDetails(productStoreExcel.rawDetails),
        },
      })
        .then((newProductStoreExcel) => {
          navigate(Page.PRODUCT_STORE_EXCEL_EDIT.PATH.replace(':id', String(newProductStoreExcel?.id)));
        })
        .catch((e) => {
          toast.error(e?.response?.data?.error?.message || e.message);
        });
    },
    [createdProductStoreExcelMutateAsync, navigate, toggleUploadModal],
  );

  return (
    <ToolkitProvider
      data={data}
      keyField={`${FieldName.TEN_NHOM}_${FieldName.MA_SAN_PHAM}_${FieldName.MA_KHO}`}
      columns={tableConfig.columns}>
      {(props: any) => (
        <>
          <div className="d-flex justify-content-end mb-4 pb-1">
            <EditPermissionAction>
              <Button color="primary" onClick={toggleUploadModal}>
                Import tồn kho thành phẩm
              </Button>
            </EditPermissionAction>
          </div>
          <div className="table-responsive">
            <BootstrapTable
              {...props.baseProps}
              remote
              bootstrap4
              bordered={false}
              pagination={paginationFactory({
                ...tableConfig.pagination,
                page: page,
                sizePerPage: sizePerPage,
                totalSize: dataCount,
              })}
              onTableChange={handleTableChange}
              noDataIndication={() => (
                <div className="text-center">
                  {dataIsLoading ? <BasicSpinner /> : 'Không có kết quả nào được tìm thấy'}
                </div>
              )}
            />
          </div>
          <UploadProductInventoryExcelModal
            isOpen={isOpenUploadModal}
            toggle={toggleUploadModal}
            handleConfirmClickCallback={handleUploadModalConfirmClickCallback}
          />
          {createdProductStoreExcelIsPending && <TransparentSpinner />}
        </>
      )}
    </ToolkitProvider>
  );
}

const normalizeProductRawDetails = (productRawDetails?: KhotpTransLenhNhapRawDetailWithRelations[]) => {
  if (!productRawDetails) {
    return productRawDetails;
  }
  const list: KhotpTransLenhNhapRawDetailWithRelations[] = [];
  for (const productRawDetail of productRawDetails) {
    list.push({
      ...productRawDetail,
      soLuong: Number(productRawDetail.soLuong),
      ngayNhap: DateUtil.toDateISOString(productRawDetail.ngayNhap),
    });
  }
  return list;
};

export default ProductStoreInventoryTable;
