import ExcelJS from 'exceljs';
import { KhovtTransPhieuNhapControllerFindDetail200DataItem } from 'main/apis/drc/models';
import { ImportFilter } from 'main/redux/modules/supplies-store-import/supplies-store-import-list-slice';
import { writeDataSheetTHCTLoHang, writeDataSheetTHNhap } from './services';
import AbstractExcelWriter from '../abstract-excel-writer';

class SuppliesStoreImportExcelWriter extends AbstractExcelWriter<{
  filter: ImportFilter;
  content: {
    importItems: KhovtTransPhieuNhapControllerFindDetail200DataItem[];
  };
}> {
  protected async writeDataImpl(): Promise<ExcelJS.Buffer> {
    const workbook = this.workbook;
    if (!workbook) {
      throw new Error('Invalid template');
    }

    // 1. Sheet[01 TH Nhập]
    this.writeDataSheetTHNhap(workbook);

    // 2. Sheet[05 TH CT Lô hàng]
    this.writeDataSheetTHCTLoHang(workbook);

    return await workbook.xlsx.writeBuffer();
  }

  private writeDataSheetTHNhap(workbook: ExcelJS.Workbook): void {
    writeDataSheetTHNhap(workbook, { filter: this.data?.filter, items: this.data?.content.importItems });
  }

  private writeDataSheetTHCTLoHang(workbook: ExcelJS.Workbook): void {
    writeDataSheetTHCTLoHang(workbook, { filter: this.data?.filter, items: this.data?.content.importItems });
  }
}

export default SuppliesStoreImportExcelWriter;
