import { FormikProps } from 'formik';
import { find } from 'lodash';
import { NewQldhTransDonHang, QldhTransDonHangItemExcludingIdWithRelations } from 'main/apis/drc/models';
import { DatePicker } from 'main/components/atoms/date';
import { Select2Box } from 'main/components/molecules/selectbox';
import { FieldName } from 'main/constants/enums';
import { YeuCauGiuHangOptions } from 'main/constants/enums/order-management';
import useProductExportCustomerUnitOptions from 'main/hooks/useProductExportCustomerUnitOptions';
import { generateUUID } from 'main/utils/generateUUID';
import moment from 'moment';
import { ChangeEvent } from 'react';
import { Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';

export type OrderManagementInfo = NewQldhTransDonHang &
  QldhTransDonHangItemExcludingIdWithRelations & {
    khuVucKh?: string;
    idDonHang?: string;
    donHangId?: string;
    maKho?: string;
  };

type Props = {
  formikProps: FormikProps<OrderManagementInfo>;
};

function OrderManagementAddInfo({ formikProps: { values, errors, handleChange, setFieldValue, setValues } }: Props) {
  // React State
  const { productExportCustomersUnitOptions, productExportCustomer } = useProductExportCustomerUnitOptions(false);
  // const { productStoreOptions, productStoresIsLoading } = useProductStoreOptions(false);

  // useEffect(() => {
  //   if (productStoreOptions.length > 0 && !values.maKhoXuat && !productStoresIsLoading) {
  //     const defaultMaKho = productStoreOptions[0].id;

  //     setFieldValue('maKhoXuat', String(defaultMaKho));
  //   }
  // }, [productStoreOptions, values.maKhoXuat, setFieldValue, productStoresIsLoading]);

  const generateOrderID = (maKH: string): string => {
    const year = moment().format('YYYY');
    const month = moment().format('MM');
    const orderNumber = generateUUID().toString();
    return `${year}-${month}-${maKH}-${orderNumber}`;
  };

  return (
    <Form>
      <Row>
        <Col md={12} lg={5}>
          <FormGroup className="row mb-2">
            <Label className="form-control-label" md="4">
              Khách hàng&nbsp;
              <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Col md="8">
              <Select2Box
                className="form-control"
                options={{ placeholder: 'Chọn khách hàng' }}
                value={values.maKH}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  const newValue = e.target.value;
                  const idDonHang = generateOrderID(newValue) as never;
                  setValues({
                    ...values,
                    maKH: newValue,
                    donHangId: idDonHang,
                    // idDonHang: generateOrderID(newValue) || '',
                    khuVucKh: find(productExportCustomer, (item) => item.maKh === newValue)?.khuVucKh || '',
                  });
                }}
                invalid={!!errors.maKH}
                data={productExportCustomersUnitOptions}
              />
              {errors.maKH && <FormText color="danger">{errors.maKH}</FormText>}
            </Col>
          </FormGroup>
        </Col>
        <Col md={12} lg={5}>
          <FormGroup className="row mb-2">
            <Label className="form-control-label" md="4">
              ID đơn hàng
            </Label>
            <Col md="8">
              <Input
                type="text"
                name={FieldName.DON_HANG_ID}
                placeholder="Nhập ID đơn hàng"
                value={values.donHangId || ''}
                onChange={handleChange}
                disabled
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={5}>
          <FormGroup className="row mb-2">
            <Label className="form-control-label" md="4">
              Vùng&nbsp;
              <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Col md="8">
              <Input
                type="text"
                name={FieldName.KHU_VUC_KH_CREATE}
                disabled
                placeholder="Vùng khách hàng"
                value={values.khuVucKh || ''}
                onChange={handleChange}
              />
              {errors.khuVucKh && <FormText color="danger">{errors.khuVucKh}</FormText>}
            </Col>
          </FormGroup>
        </Col>
        <Col md={12} lg={5}>
          <FormGroup className="row mb-2">
            <Label className="form-control-label" md="4">
              Ngày đặt hàng&nbsp;
              <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Col md="8">
              <DatePicker
                inputProps={{
                  placeholder: 'Chọn ngày',
                  className: `form-control ${!!errors.ngayDatHang ? 'is-invalid' : ''}`,
                }}
                // dateFormat={DateFormat.DATE_ONLY_VN}
                value={values.ngayDatHang || ''}
                onChange={(e) => setFieldValue(FieldName.NGAY_DAT_HANG, e ? moment(e) : undefined)}
              />
              {errors.ngayDatHang && <FormText color="danger">{errors.ngayDatHang}</FormText>}
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={5}>
          <FormGroup className="row mb-2">
            <Label className="form-control-label" md="4">
              Yêu cầu giữ hàng &nbsp;
              <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Col md="8">
              <Select2Box
                className="form-control"
                options={{ placeholder: 'Chọn yêu cầu giữ hàng' }}
                // onChange={handleChange}
                // onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                //   setFieldValue(FieldName.YEU_CAU_GIU_HANG, e.target.value)
                // }
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  const newValue = e.target.value;
                  setValues({
                    ...values,
                    yeuCauGiuHang: newValue,
                  });
                }}
                value={values.yeuCauGiuHang}
                data={YeuCauGiuHangOptions}
              />
              {errors.yeuCauGiuHang && <FormText color="danger">{errors.yeuCauGiuHang}</FormText>}
            </Col>
          </FormGroup>
        </Col>
        <Col md={12} lg={5}>
          <FormGroup className="row mb-2">
            <Label className="form-control-label" md="4">
              Người Tạo&nbsp;
              <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Col md="8">
              <Input
                type="text"
                name={FieldName.NGUOI_TAO}
                placeholder="Nhập người tạo phiếu"
                value={values.nguoiTao || ''}
                onChange={handleChange}
                invalid={!!errors.nguoiTao}
              />
              {errors.nguoiTao && <FormText color="danger">{errors.nguoiTao}</FormText>}
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={5}>
          <FormGroup className="row">
            <Label className="form-control-label" md="4">
              Trạng thái
              <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Col md="8">
              <Select2Box
                className="form-control"
                options={{ placeholder: 'Chọn trạng thái' }}
                value={values.trangThai}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setFieldValue(FieldName.TRANG_THAI, e.target.value)}
                data={[
                  { id: 'tam-dung', text: 'Tạm dừng' },
                  { id: 'dang-tien-hanh', text: 'Đang tiến hành' },
                ]}
                invalid={!!errors.trangThai}
              />
              {errors.trangThai && <FormText color="danger">{errors.trangThai}</FormText>}
            </Col>
          </FormGroup>
        </Col>

        <Col md={12} lg={5}>
          <FormGroup className="row mb-2">
            <Label className="form-control-label" md="4">
              Người yêu cầu&nbsp;
              <i className="fa fa-asterisk fa-sm text-danger" />
            </Label>
            <Col md="8">
              <Input
                type="text"
                name={FieldName.NGUOI_YEU_CAU}
                placeholder="Nhập người yêu cầu"
                value={values.nguoiYeuCau}
                onChange={handleChange}
                invalid={!!errors.nguoiYeuCau}
              />
              {errors.nguoiYeuCau && <FormText color="danger">{errors.nguoiYeuCau}</FormText>}
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}

export default OrderManagementAddInfo;
