import { useQldhTransDonHangControllerUpdateStatusById } from 'main/apis/drc/endpoints/qldh-trans-don-hang-controller/qldh-trans-don-hang-controller';
// import { useQldhTransYeuCauSanXuatControllerCreate } from 'main/apis/drc/endpoints/qldh-trans-yeu-cau-san-xuat-controller/qldh-trans-yeu-cau-san-xuat-controller';
import {
  QldhTransDonHangControllerSearchItems200,
  QldhTransDonHangControllerSearchItems200DataItem,
  // QldhTransYeuCauSanXuatWithRelations,
} from 'main/apis/drc/models';
import { BasicSpinner } from 'main/components/atoms/spiner';
import { Page, ToastMessage } from 'main/constants';
import { FieldName } from 'main/constants/enums';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  selectImportTable,
  updateImportTable,
} from 'main/redux/modules/supplies-store-import/supplies-store-import-list-slice';
import { generateColumnStyle } from 'main/utils';
import { useCallback } from 'react';
import BootstrapTable, { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
// import AddEditItemModal from './AddEditItemModal';
import tableConfig from './table-config';
import { OrderManagementInfo } from '../../../order-management-edit/sub-components/order-management-edit-info';

type Props = {
  data?: QldhTransDonHangControllerSearchItems200;
  dataIsLoading: boolean;
  handleRefreshTable: () => void;
};

function OrderManagementTable({
  data: { data, count: dataCount } = { data: [], count: 0 },
  dataIsLoading,
  handleRefreshTable,
}: Props) {
  // React Router
  const navigate = useNavigate();

  // Redux
  const dispatch = useAppDispatch();
  const { page, sizePerPage, sortField, sortOrder } = useAppSelector(selectImportTable);
  // const updateMutation = useQldhTransYeuCauSanXuatControllerCreate();
  const { mutateAsync: updatedStatusOrderManagementMutateAsync, isPending: updatedOrderManagementPending } =
    useQldhTransDonHangControllerUpdateStatusById();

  // React State/Ref
  // const [currentItem, setCurrentItem] = useState<QldhTransDonHangControllerSearchItems200DataItem | null>(null);
  // const [isOpenAddSuppliesModal, setOpenAddSuppliesModal] = useState(false);

  // const toggleAddSuppliesModal = useCallback(
  //   () => setOpenAddSuppliesModal((isOpenAddSuppliesModal) => !isOpenAddSuppliesModal),
  //   [],
  // );

  // React Function
  const handleTableChange = useCallback(
    (_: TableChangeType, newState: TableChangeState<QldhTransDonHangControllerSearchItems200DataItem>) => {
      const { page, sizePerPage, sortField, sortOrder } = newState;

      dispatch(
        updateImportTable({
          page,
          sizePerPage,
          sortField,
          sortOrder,
        }),
      );
    },
    [dispatch],
  );

  // const updateItem = async (updatedItem: QldhTransYeuCauSanXuatWithRelations) => {
  //   updateMutation.mutate(
  //     { data: updatedItem },
  //     {
  //       onSuccess: () => {
  //         toast.success(ToastMessage.UPDATE_SUCCESS);
  //       },
  //     },
  //   );
  // };

  // ... previous code remains the same
  const handleSaveConfirmClick = useCallback(
    (info: OrderManagementInfo) => {
      // Create new bill
      updatedStatusOrderManagementMutateAsync({
        id: Number(info.donHangId), // Convert string to number
      })
        .then((updatedSuppliesImportBill) => {
          toast.success(ToastMessage.UPDATE_SUCCESS);
          navigate(Page.ORDER_MANAGEMENT_LIST.PATH);
          handleRefreshTable();
        })
        .catch((e) => {
          toast.error(e?.response?.data?.error?.message || e.message);
        });
    },
    [handleRefreshTable, navigate, updatedStatusOrderManagementMutateAsync],
  );

  return (
    <ToolkitProvider
      data={data || []}
      keyField={`${FieldName.ID}_${FieldName.DON_HANG_ID}`}
      columns={[
        ...tableConfig.columns,
        {
          dataField: FieldName.ACTION,
          isDummyField: true,
          text: 'Thao tác',
          ...generateColumnStyle(),
          formatter: (_: any, row: QldhTransDonHangControllerSearchItems200DataItem) => {
            return (
              <div className="d-flex">
                <Link className="px-2" to={Page.ORDER_MANAGEMENT_DETAIL.PATH.replace(':id', String(row.donHangId))}>
                  <i className="fa-solid fa-eye" title="Xem" />
                </Link>
                {
                  <Link className="px-2" to={Page.ORDER_MANAGEMENT_EDIT.PATH.replace(':id', String(row.donHangId))}>
                    <i className="fa-solid fa-pen-to-square" title="Sửa" />
                  </Link>
                }
                {/* {
                  <Link
                    className="px-2"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentItem(row);
                      toggleAddSuppliesModal();
                    }}>
                    <i className="fa-solid fa-plus text-blue" title="Yêu cầu sản xuất thêm" />
                  </Link>
                } */}
                {
                  <Link
                    className="px-2"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSaveConfirmClick(row as any);
                    }}>
                    <i className="fa-solid fa-check-circle text-green" title="Tích hoàn thành" />
                  </Link>
                }
              </div>
            );
          },
        },
      ]}>
      {(props: any) => (
        <>
          <div className="d-flex justify-content-end mb-4 pb-1">
            <Button color="primary" onClick={() => navigate(Page.ORDER_MANAGEMENT_ADD.PATH)}>
              Thêm đơn hàng
            </Button>
          </div>
          <div className="table-responsive">
            <BootstrapTable
              {...props.baseProps}
              remote
              bootstrap4
              bordered={false}
              pagination={paginationFactory({
                ...tableConfig.pagination,
                page: page,
                sizePerPage: sizePerPage,
                totalSize: dataCount,
              })}
              sort={
                sortField
                  ? {
                      dataField: sortField,
                      order: sortOrder,
                    }
                  : {}
              }
              onTableChange={handleTableChange}
              noDataIndication={() => (
                <div className="text-center">
                  {dataIsLoading || updatedOrderManagementPending ? (
                    <BasicSpinner />
                  ) : (
                    'Không có kết quả nào được tìm thấy'
                  )}
                </div>
              )}
            />
          </div>
          {/* <AddEditItemModal
            isOpen={isOpenAddSuppliesModal}
            toggle={toggleAddSuppliesModal}
            handleEdit={updateItem}
            currentItem={currentItem as any}
          /> */}
        </>
      )}
    </ToolkitProvider>
  );
}

export default OrderManagementTable;
