import { QldhTransDonHangControllerSearchCanhBao200Item } from 'main/apis/drc/models';
import { BasicSpinner } from 'main/components/atoms/spiner';
import { Page } from 'main/constants';
import { FieldName } from 'main/constants/enums';
import { useAppDispatch } from 'main/redux/hooks';
import { clickSearchImport } from 'main/redux/modules/order-management/order-management-list-slice';
import { generateColumnStyle } from 'main/utils';
import { useCallback } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';
import tableConfig from './table-config';

type Props = {
  data?: QldhTransDonHangControllerSearchCanhBao200Item[];
  dataIsLoading: boolean;
};

function OrderAnalyticsTable({ data, dataIsLoading }: Props) {
  const dispatch = useAppDispatch();
  const handleSearchClick = useCallback(
    (newFilter: any) => {
      const searchSanPham = {
        searchField: 'maSanPham',
        searchKey: newFilter.maSanPham,
      };

      dispatch(clickSearchImport(searchSanPham as any));
    },
    [dispatch],
  );

  return (
    <ToolkitProvider
      data={data || []}
      keyField={`${FieldName.SO_PHIEU_NHAP}_${FieldName.MA_SAN_PHAM}_${FieldName.SO_LO}`}
      columns={[
        ...tableConfig.columns,
        {
          dataField: FieldName.ACTION,
          isDummyField: true,
          text: 'Thao tác',
          ...generateColumnStyle(),
          formatter: (_: any, row: any) => {
            return (
              <div className="d-flex">
                <Link className="px-2" onClick={() => handleSearchClick(row)} to={Page.ORDER_MANAGEMENT_LIST.PATH}>
                  <i className="fa-solid fa-eye" title="Xem" />
                </Link>
              </div>
            );
          },
        },
      ]}>
      {(props: any) => (
        <>
          <div className="table-responsive">
            <BootstrapTable
              {...props.baseProps}
              remote
              bootstrap4
              bordered={false}
              noDataIndication={() => (
                <div className="text-center">
                  {dataIsLoading ? <BasicSpinner /> : 'Không có kết quả nào được tìm thấy'}
                </div>
              )}
            />
          </div>
        </>
      )}
    </ToolkitProvider>
  );
}

export default OrderAnalyticsTable;
