import { KhotpTransLenhNhapWithRelations } from 'main/apis/drc/models';
import { ProductExcelTemplateType } from 'main/constants/enums';
import { AbstractExcelReader } from '.';
import { ExcelUtil } from '..';

const SHEET_RANGE_CONSTANTS: {
  ROW_DATA_BEGGIN_INDEX: number;
  ATTRIBUTE_INDEXS: {
    maKhoNhap: number;
    maSanPham: number;
    tenSanPham: number;
    dvt: number;
    soLuong: number;
    ghiChu: number;
  };
} = {
  ROW_DATA_BEGGIN_INDEX: 11,
  ATTRIBUTE_INDEXS: {
    maKhoNhap: 0,
    maSanPham: 1,
    tenSanPham: 2,
    dvt: 3,
    soLuong: 4, // default col
    ghiChu: 13,
  },
};

class ProductInventoryImportExcelReader extends AbstractExcelReader<KhotpTransLenhNhapWithRelations> {
  protected readDataImpl(): KhotpTransLenhNhapWithRelations {
    if (!this.workbook) {
      throw new Error('Invalid template');
    }

    const dto: KhotpTransLenhNhapWithRelations = {
      loaiLenhNhap: ProductExcelTemplateType['ton-kho'].id,
      rawDetails: [],
    };

    for (const sheetName of this.workbook.SheetNames) {
      const sheetDataRange = ExcelUtil.getSheetRange(this.workbook, sheetName);
      const colBeginIndex = 0;
      const colEndIndex = sheetDataRange.e.c;
      const rowBeginIndex = 0;
      const rowEndIndex = sheetDataRange.e.r;
      const numberOfRecords = rowEndIndex - 1;

      // Load records from excel
      const recordsRawData = ExcelUtil.loadDataByRange(this.workbook, sheetName, {
        colBeginIndex,
        rowBeginIndex,
        colEndIndex,
        rowEndIndex,
      });

      // Convert raw data to dto
      const rowDataBegginIndex = SHEET_RANGE_CONSTANTS.ROW_DATA_BEGGIN_INDEX;
      const attributeIndexs = SHEET_RANGE_CONSTANTS.ATTRIBUTE_INDEXS;
      const quantityCol = this.additionalProps?.quantityCol
        ? ExcelUtil.letterToColumn(this.additionalProps.quantityCol) - 1
        : attributeIndexs.soLuong;
      for (let recordIndex = rowDataBegginIndex; recordIndex < numberOfRecords; recordIndex++) {
        if (
          recordsRawData[recordIndex][attributeIndexs.maSanPham] &&
          recordsRawData[recordIndex][attributeIndexs.tenSanPham]
        ) {
          // Convert to detail dto
          const recordDto: any = {};
          recordDto.maKhoNhap = ExcelUtil.normalizeCellData(recordsRawData[recordIndex][attributeIndexs.maKhoNhap]);
          recordDto.maSanPham = ExcelUtil.normalizeCellData(recordsRawData[recordIndex][attributeIndexs.maSanPham]);
          recordDto.tenSanPham = ExcelUtil.normalizeCellData(recordsRawData[recordIndex][attributeIndexs.tenSanPham]);
          recordDto.dvt = ExcelUtil.normalizeCellData(recordsRawData[recordIndex][attributeIndexs.dvt]);
          recordDto.soLuong = Number(ExcelUtil.normalizeCellData(recordsRawData[recordIndex][quantityCol]));
          recordDto.ghiChu = ExcelUtil.normalizeCellData(recordsRawData[recordIndex][attributeIndexs.ghiChu]);

          // Add detail to array
          dto.rawDetails?.push(recordDto);
        }
      }
    }

    return dto;
  }
}

export default ProductInventoryImportExcelReader;
