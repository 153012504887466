import { DateFormat } from 'main/constants';
import moment, { Moment } from 'moment';

const DateUtil = {
  toISOString(date: moment.MomentInput | undefined, defaultValue?: string) {
    return date ? moment(date).utc(true).toISOString() : defaultValue;
  },
  toDateISOString(date: moment.MomentInput | undefined, defaultValue?: string) {
    return date ? moment(date).utc(true).format(DateFormat.ISO_8601_DATE_ONLY) : defaultValue; // date only & ignore UTC
  },
  toDate(date: Moment | undefined, defaultValue?: Date) {
    return date ? date.toDate() : defaultValue;
  },
};

export default DateUtil;
