const NUOC_NGOAI_ID = 'nuoc-ngoai';
const NUOC_NGOAI_TEXT = 'Nước ngoài';

const NhomKhachHangOptions = [
  { id: '-1', text: 'Tất cả' },
  { id: NUOC_NGOAI_ID, text: NUOC_NGOAI_TEXT },
  { id: 'noi-dia', text: 'Nội địa' },
];

const OrderStatusOptions = [
  { id: 'dang-tien-hanh', text: 'Đang tiến hành' },
  { id: 'tam-dung', text: 'Tạm dừng' },
  { id: 'hoan-thanh', text: 'Hoàn thành' },
];

const YeuCauGiuHangOptions = [
  { id: 'giu-hang', text: 'Giữ hàng' },
  { id: 'khong-giu-hang', text: 'Không giữ hàng' },
];

export { OrderStatusOptions, NhomKhachHangOptions, NUOC_NGOAI_ID, NUOC_NGOAI_TEXT, YeuCauGiuHangOptions };
