import ExcelJS from 'exceljs';
import { KhovtTransPhieuXuatControllerFindDetail200DataItem } from 'main/apis/drc/models';
import { ExportFilter } from 'main/redux/modules/supplies-store-export/supplies-store-export-list-slice';
import { writeDataSheetTHXuat } from './services';
import { AbstractExcelWriter } from '..';

class SuppliesStoreExportExcelWriter extends AbstractExcelWriter<{
  filter: ExportFilter;
  content: {
    exportItems: KhovtTransPhieuXuatControllerFindDetail200DataItem[];
  };
}> {
  protected async writeDataImpl(): Promise<ExcelJS.Buffer> {
    const workbook = this.workbook;
    if (!workbook) {
      throw new Error('Invalid template');
    }

    // 1. Sheet[02 TH Xuất]
    this.writeDataSheetTHXuat(workbook);

    return await workbook.xlsx.writeBuffer();
  }

  private writeDataSheetTHXuat(workbook: ExcelJS.Workbook): void {
    writeDataSheetTHXuat(workbook, { filter: this.data?.filter, items: this.data?.content.exportItems });
  }
}

export default SuppliesStoreExportExcelWriter;
