import { FormikProps } from 'formik';
import { MuiDataGrid } from 'main/components/atoms/mui-data-grid';
import EditPermissionAction from 'main/components/molecules/permission-action/EditPermissionAction';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  initialData,
  insertNewSupplies,
  selectSuppliesDetails,
  selectSuppliesFilter,
  updateSuppliesDetail,
} from 'main/redux/modules/supplies-store-export/supplies-store-export-add-slice';
import { generateUUID } from 'main/utils/generateUUID';
import { useCallback } from 'react';
import { Button } from 'reactstrap';
import useTableConfig from './useTableConfig';
import { SuppliesStoreExportInfo } from '../supplies-store-export-add-info';

type Props = {
  formikProps: FormikProps<SuppliesStoreExportInfo>;
};

function SuppliesStoreExportAddTable({
  formikProps: { values, errors, setValues, handleChange, setFieldValue },
}: Props) {
  // Redux
  const dispatch = useAppDispatch();
  const suppliesDetails = useAppSelector(selectSuppliesDetails);
  const filter = useAppSelector(selectSuppliesFilter);

  const { suppliesIsLoading, columns } = useTableConfig(values.khoXuat || '');

  const handleRowEditStop = useCallback(
    (params: any, event: any) => {
      const { rowId, newValue } = params;

      dispatch(updateSuppliesDetail({ id: rowId, changes: newValue }));

      // Add new row logic (if last row is edited)
      if (params.reason === 'enterKeyDown' && params.row.id === suppliesDetails.length - 1) {
        const newRows = Array.from({ length: 10 }, () => ({
          ...initialData,
          id: generateUUID(),
        }));
        dispatch(insertNewSupplies(newRows));
      }
    },
    [dispatch, suppliesDetails.length],
  );

  const handleAddRow = useCallback(() => {
    const newRows = Array.from({ length: 10 }, () => ({
      ...initialData,
      id: generateUUID(),
    }));

    dispatch(insertNewSupplies(newRows));
  }, [dispatch]);

  const handleRowEdit = useCallback(
    (newRow: any) => {
      const { tonKho, soLuongThucTe } = newRow;
      const updatedRow = {
        ...newRow,
        tonKhoDuKien: Number(soLuongThucTe) > Number(tonKho) ? Number(tonKho) : Number(tonKho) - Number(soLuongThucTe),
        soLuongThucTe: Number(soLuongThucTe) > Number(tonKho) ? 0 : soLuongThucTe,
      };

      dispatch(
        updateSuppliesDetail({
          id: newRow.id,
          changes: {
            ...updatedRow,
          },
        }),
      );

      return updatedRow;
    },
    [dispatch],
  );

  return (
    <>
      <div className="d-flex justify-content-end pb-4">
        <EditPermissionAction>
          <Button color="primary" onClick={handleAddRow} disabled={!filter.suppliesStoreCode}>
            Thêm hàng
          </Button>
        </EditPermissionAction>
      </div>
      <div className="table-responsive">
        <MuiDataGrid
          rows={suppliesDetails}
          columns={columns}
          loading={suppliesIsLoading}
          pageSizeOptions={[100]} // max 100 items/page
          onRowEditStop={handleRowEditStop}
          processRowUpdate={handleRowEdit}
          hideFooter={suppliesDetails.length <= 100} // Ẩn pagination (tối da 100 item/page theo limit của MUI version)
        />
      </div>
    </>
  );
}

export default SuppliesStoreExportAddTable;
