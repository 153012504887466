/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhovtXuatNhapTonControllerFind200,
  KhovtXuatNhapTonControllerFindDetailByLoHang200,
  KhovtXuatNhapTonControllerFindDetailByLoHangParams,
  KhovtXuatNhapTonControllerFindDetailBySoPhieu200,
  KhovtXuatNhapTonControllerFindDetailBySoPhieuParams,
  KhovtXuatNhapTonControllerFindParams,
  KhovtXuatNhapTonControllerSearchCanhBao200Item
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khovtXuatNhapTonControllerSearchCanhBao = (
    
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtXuatNhapTonControllerSearchCanhBao200Item[]>(
      {url: `/khovt-xuat-nhap-tons/search-canh-bao`, method: 'GET', signal
    },
      );
    }
  

export const getKhovtXuatNhapTonControllerSearchCanhBaoQueryKey = () => {
    return [`/khovt-xuat-nhap-tons/search-canh-bao`] as const;
    }

    
export const getKhovtXuatNhapTonControllerSearchCanhBaoQueryOptions = <TData = Awaited<ReturnType<typeof khovtXuatNhapTonControllerSearchCanhBao>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtXuatNhapTonControllerSearchCanhBao>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtXuatNhapTonControllerSearchCanhBaoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtXuatNhapTonControllerSearchCanhBao>>> = ({ signal }) => khovtXuatNhapTonControllerSearchCanhBao(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtXuatNhapTonControllerSearchCanhBao>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtXuatNhapTonControllerSearchCanhBaoQueryResult = NonNullable<Awaited<ReturnType<typeof khovtXuatNhapTonControllerSearchCanhBao>>>
export type KhovtXuatNhapTonControllerSearchCanhBaoQueryError = ErrorType<unknown>

export const useKhovtXuatNhapTonControllerSearchCanhBao = <TData = Awaited<ReturnType<typeof khovtXuatNhapTonControllerSearchCanhBao>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtXuatNhapTonControllerSearchCanhBao>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtXuatNhapTonControllerSearchCanhBaoQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtXuatNhapTonControllerFindDetailByLoHang = (
    params?: KhovtXuatNhapTonControllerFindDetailByLoHangParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtXuatNhapTonControllerFindDetailByLoHang200>(
      {url: `/khovt-xuat-nhap-tons/search-detail-by-lo-hang`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtXuatNhapTonControllerFindDetailByLoHangQueryKey = (params?: KhovtXuatNhapTonControllerFindDetailByLoHangParams,) => {
    return [`/khovt-xuat-nhap-tons/search-detail-by-lo-hang`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtXuatNhapTonControllerFindDetailByLoHangQueryOptions = <TData = Awaited<ReturnType<typeof khovtXuatNhapTonControllerFindDetailByLoHang>>, TError = ErrorType<unknown>>(params?: KhovtXuatNhapTonControllerFindDetailByLoHangParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFindDetailByLoHang>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtXuatNhapTonControllerFindDetailByLoHangQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFindDetailByLoHang>>> = ({ signal }) => khovtXuatNhapTonControllerFindDetailByLoHang(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFindDetailByLoHang>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtXuatNhapTonControllerFindDetailByLoHangQueryResult = NonNullable<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFindDetailByLoHang>>>
export type KhovtXuatNhapTonControllerFindDetailByLoHangQueryError = ErrorType<unknown>

export const useKhovtXuatNhapTonControllerFindDetailByLoHang = <TData = Awaited<ReturnType<typeof khovtXuatNhapTonControllerFindDetailByLoHang>>, TError = ErrorType<unknown>>(
 params?: KhovtXuatNhapTonControllerFindDetailByLoHangParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFindDetailByLoHang>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtXuatNhapTonControllerFindDetailByLoHangQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtXuatNhapTonControllerFindDetailBySoPhieu = (
    params?: KhovtXuatNhapTonControllerFindDetailBySoPhieuParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtXuatNhapTonControllerFindDetailBySoPhieu200>(
      {url: `/khovt-xuat-nhap-tons/search-detail-by-so-phieu`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtXuatNhapTonControllerFindDetailBySoPhieuQueryKey = (params?: KhovtXuatNhapTonControllerFindDetailBySoPhieuParams,) => {
    return [`/khovt-xuat-nhap-tons/search-detail-by-so-phieu`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtXuatNhapTonControllerFindDetailBySoPhieuQueryOptions = <TData = Awaited<ReturnType<typeof khovtXuatNhapTonControllerFindDetailBySoPhieu>>, TError = ErrorType<unknown>>(params?: KhovtXuatNhapTonControllerFindDetailBySoPhieuParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFindDetailBySoPhieu>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtXuatNhapTonControllerFindDetailBySoPhieuQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFindDetailBySoPhieu>>> = ({ signal }) => khovtXuatNhapTonControllerFindDetailBySoPhieu(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFindDetailBySoPhieu>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtXuatNhapTonControllerFindDetailBySoPhieuQueryResult = NonNullable<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFindDetailBySoPhieu>>>
export type KhovtXuatNhapTonControllerFindDetailBySoPhieuQueryError = ErrorType<unknown>

export const useKhovtXuatNhapTonControllerFindDetailBySoPhieu = <TData = Awaited<ReturnType<typeof khovtXuatNhapTonControllerFindDetailBySoPhieu>>, TError = ErrorType<unknown>>(
 params?: KhovtXuatNhapTonControllerFindDetailBySoPhieuParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFindDetailBySoPhieu>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtXuatNhapTonControllerFindDetailBySoPhieuQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khovtXuatNhapTonControllerFind = (
    params?: KhovtXuatNhapTonControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhovtXuatNhapTonControllerFind200>(
      {url: `/khovt-xuat-nhap-tons`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhovtXuatNhapTonControllerFindQueryKey = (params?: KhovtXuatNhapTonControllerFindParams,) => {
    return [`/khovt-xuat-nhap-tons`, ...(params ? [params]: [])] as const;
    }

    
export const getKhovtXuatNhapTonControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khovtXuatNhapTonControllerFind>>, TError = ErrorType<unknown>>(params?: KhovtXuatNhapTonControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhovtXuatNhapTonControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFind>>> = ({ signal }) => khovtXuatNhapTonControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhovtXuatNhapTonControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFind>>>
export type KhovtXuatNhapTonControllerFindQueryError = ErrorType<unknown>

export const useKhovtXuatNhapTonControllerFind = <TData = Awaited<ReturnType<typeof khovtXuatNhapTonControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhovtXuatNhapTonControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khovtXuatNhapTonControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhovtXuatNhapTonControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



