/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult
} from '@tanstack/react-query'
import type {
  FPermission,
  LoopbackCount,
  NewFPermission
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const fPermissionControllerCreate = (
    newFPermission: NewFPermission,
 ) => {
      
      
      return drcInstance<FPermission>(
      {url: `/permissions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newFPermission
    },
      );
    }
  


export const getFPermissionControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fPermissionControllerCreate>>, TError,{data: NewFPermission}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof fPermissionControllerCreate>>, TError,{data: NewFPermission}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fPermissionControllerCreate>>, {data: NewFPermission}> = (props) => {
          const {data} = props ?? {};

          return  fPermissionControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FPermissionControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof fPermissionControllerCreate>>>
    export type FPermissionControllerCreateMutationBody = NewFPermission
    export type FPermissionControllerCreateMutationError = ErrorType<unknown>

    export const useFPermissionControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fPermissionControllerCreate>>, TError,{data: NewFPermission}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof fPermissionControllerCreate>>,
        TError,
        {data: NewFPermission},
        TContext
      > => {

      const mutationOptions = getFPermissionControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const fPermissionControllerUpdateAll = (
    fPermissionControllerUpdateAllBody: unknown,
 ) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/permissions`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: fPermissionControllerUpdateAllBody
    },
      );
    }
  


export const getFPermissionControllerUpdateAllMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fPermissionControllerUpdateAll>>, TError,{data: unknown}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof fPermissionControllerUpdateAll>>, TError,{data: unknown}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fPermissionControllerUpdateAll>>, {data: unknown}> = (props) => {
          const {data} = props ?? {};

          return  fPermissionControllerUpdateAll(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FPermissionControllerUpdateAllMutationResult = NonNullable<Awaited<ReturnType<typeof fPermissionControllerUpdateAll>>>
    export type FPermissionControllerUpdateAllMutationBody = unknown
    export type FPermissionControllerUpdateAllMutationError = ErrorType<unknown>

    export const useFPermissionControllerUpdateAll = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fPermissionControllerUpdateAll>>, TError,{data: unknown}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof fPermissionControllerUpdateAll>>,
        TError,
        {data: unknown},
        TContext
      > => {

      const mutationOptions = getFPermissionControllerUpdateAllMutationOptions(options);

      return useMutation(mutationOptions);
    }
    