/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhotpMasterNhomBtpControllerCountParams,
  KhotpMasterNhomBtpControllerFindByIdParams,
  KhotpMasterNhomBtpControllerFindParams,
  KhotpMasterNhomsp,
  KhotpMasterNhomspPartial,
  KhotpMasterNhomspWithRelations,
  LoopbackCount,
  NewKhotpMasterNhomsp
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khotpMasterNhomBtpControllerCount = (
    params?: KhotpMasterNhomBtpControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-nhombtps/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterNhomBtpControllerCountQueryKey = (params?: KhotpMasterNhomBtpControllerCountParams,) => {
    return [`/khotp-master-nhombtps/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterNhomBtpControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterNhomBtpControllerCount>>, TError = ErrorType<unknown>>(params?: KhotpMasterNhomBtpControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterNhomBtpControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerCount>>> = ({ signal }) => khotpMasterNhomBtpControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterNhomBtpControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerCount>>>
export type KhotpMasterNhomBtpControllerCountQueryError = ErrorType<unknown>

export const useKhotpMasterNhomBtpControllerCount = <TData = Awaited<ReturnType<typeof khotpMasterNhomBtpControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterNhomBtpControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterNhomBtpControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterNhomBtpControllerReplaceById = (
    id: string,
    khotpMasterNhomsp: KhotpMasterNhomsp,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-nhombtps/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterNhomsp
    },
      );
    }
  


export const getKhotpMasterNhomBtpControllerReplaceByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerReplaceById>>, TError,{id: string;data: KhotpMasterNhomsp}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerReplaceById>>, TError,{id: string;data: KhotpMasterNhomsp}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerReplaceById>>, {id: string;data: KhotpMasterNhomsp}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterNhomBtpControllerReplaceById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterNhomBtpControllerReplaceByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerReplaceById>>>
    export type KhotpMasterNhomBtpControllerReplaceByIdMutationBody = KhotpMasterNhomsp
    export type KhotpMasterNhomBtpControllerReplaceByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterNhomBtpControllerReplaceById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerReplaceById>>, TError,{id: string;data: KhotpMasterNhomsp}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterNhomBtpControllerReplaceById>>,
        TError,
        {id: string;data: KhotpMasterNhomsp},
        TContext
      > => {

      const mutationOptions = getKhotpMasterNhomBtpControllerReplaceByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterNhomBtpControllerUpdateById = (
    id: string,
    khotpMasterNhomspPartial: KhotpMasterNhomspPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-nhombtps/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterNhomspPartial
    },
      );
    }
  


export const getKhotpMasterNhomBtpControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerUpdateById>>, TError,{id: string;data: KhotpMasterNhomspPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerUpdateById>>, TError,{id: string;data: KhotpMasterNhomspPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerUpdateById>>, {id: string;data: KhotpMasterNhomspPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterNhomBtpControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterNhomBtpControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerUpdateById>>>
    export type KhotpMasterNhomBtpControllerUpdateByIdMutationBody = KhotpMasterNhomspPartial
    export type KhotpMasterNhomBtpControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterNhomBtpControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerUpdateById>>, TError,{id: string;data: KhotpMasterNhomspPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterNhomBtpControllerUpdateById>>,
        TError,
        {id: string;data: KhotpMasterNhomspPartial},
        TContext
      > => {

      const mutationOptions = getKhotpMasterNhomBtpControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterNhomBtpControllerFindById = (
    id: string,
    params?: KhotpMasterNhomBtpControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterNhomspWithRelations>(
      {url: `/khotp-master-nhombtps/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterNhomBtpControllerFindByIdQueryKey = (id: string,
    params?: KhotpMasterNhomBtpControllerFindByIdParams,) => {
    return [`/khotp-master-nhombtps/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterNhomBtpControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterNhomBtpControllerFindById>>, TError = ErrorType<unknown>>(id: string,
    params?: KhotpMasterNhomBtpControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterNhomBtpControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerFindById>>> = ({ signal }) => khotpMasterNhomBtpControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterNhomBtpControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerFindById>>>
export type KhotpMasterNhomBtpControllerFindByIdQueryError = ErrorType<unknown>

export const useKhotpMasterNhomBtpControllerFindById = <TData = Awaited<ReturnType<typeof khotpMasterNhomBtpControllerFindById>>, TError = ErrorType<unknown>>(
 id: string,
    params?: KhotpMasterNhomBtpControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterNhomBtpControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterNhomBtpControllerDeleteById = (
    id: string,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-nhombtps/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhotpMasterNhomBtpControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerDeleteById>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerDeleteById>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  khotpMasterNhomBtpControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterNhomBtpControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerDeleteById>>>
    
    export type KhotpMasterNhomBtpControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterNhomBtpControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterNhomBtpControllerDeleteById>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getKhotpMasterNhomBtpControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterNhomBtpControllerCreate = (
    newKhotpMasterNhomsp: NewKhotpMasterNhomsp,
 ) => {
      
      
      return drcInstance<KhotpMasterNhomsp>(
      {url: `/khotp-master-nhombtps`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhotpMasterNhomsp
    },
      );
    }
  


export const getKhotpMasterNhomBtpControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerCreate>>, TError,{data: NewKhotpMasterNhomsp}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerCreate>>, TError,{data: NewKhotpMasterNhomsp}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerCreate>>, {data: NewKhotpMasterNhomsp}> = (props) => {
          const {data} = props ?? {};

          return  khotpMasterNhomBtpControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterNhomBtpControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerCreate>>>
    export type KhotpMasterNhomBtpControllerCreateMutationBody = NewKhotpMasterNhomsp
    export type KhotpMasterNhomBtpControllerCreateMutationError = ErrorType<unknown>

    export const useKhotpMasterNhomBtpControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerCreate>>, TError,{data: NewKhotpMasterNhomsp}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterNhomBtpControllerCreate>>,
        TError,
        {data: NewKhotpMasterNhomsp},
        TContext
      > => {

      const mutationOptions = getKhotpMasterNhomBtpControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterNhomBtpControllerFind = (
    params?: KhotpMasterNhomBtpControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterNhomspWithRelations[]>(
      {url: `/khotp-master-nhombtps`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterNhomBtpControllerFindQueryKey = (params?: KhotpMasterNhomBtpControllerFindParams,) => {
    return [`/khotp-master-nhombtps`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterNhomBtpControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterNhomBtpControllerFind>>, TError = ErrorType<unknown>>(params?: KhotpMasterNhomBtpControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterNhomBtpControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerFind>>> = ({ signal }) => khotpMasterNhomBtpControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterNhomBtpControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerFind>>>
export type KhotpMasterNhomBtpControllerFindQueryError = ErrorType<unknown>

export const useKhotpMasterNhomBtpControllerFind = <TData = Awaited<ReturnType<typeof khotpMasterNhomBtpControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterNhomBtpControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterNhomBtpControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterNhomBtpControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



