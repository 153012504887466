import { Formik } from 'formik';
import {
  useKhovtTransPhieuNhapControllerFindById,
  useKhovtTransPhieuNhapControllerUpdateById,
} from 'main/apis/drc/endpoints/khovt-trans-phieu-nhap-controller/khovt-trans-phieu-nhap-controller';
import BasicTemplate from 'main/components/templates/basic-template';
import { Page, ValidationMessage } from 'main/constants';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  loadSuppliesImportBill,
  resetSuppliesStoreImportEditState,
  selectSuppliesStoreImportDetails,
  selectSuppliesStoreImportInfo,
} from 'main/redux/modules/supplies-store-import/supplies-store-import-edit-slice';
import { DateUtil } from 'main/utils';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import SaveImportBillModal from './sub-components/save-import-bill-modal';
import SuppliesStoreImportEditInfo from './sub-components/supplies-store-import-edit-info';
import SuppliesStoreImportEditTable from './sub-components/supplies-store-import-edit-table/supplies-store-import-edit-table';
import { SuppliesStoreImportInfo } from '../supplies-store-import-add/sub-components/supplies-store-import-add-info';
import { normalizeSuppliesDetails } from '../supplies-store-import-add/supplies-store-import-add';

const infoValidationSchema = Yup.object().shape({
  typeNhap: Yup.string().required(ValidationMessage.REQUIRED),
  maKho: Yup.string().required(ValidationMessage.REQUIRED),
  donViNhap: Yup.string().required(ValidationMessage.REQUIRED),
  nguoiNhap: Yup.string().required(ValidationMessage.REQUIRED),
  viTriLuuKho: Yup.string().required(ValidationMessage.REQUIRED),
  ngayNhap: Yup.string().required(ValidationMessage.REQUIRED),
  soHoaDon: Yup.string().required(ValidationMessage.REQUIRED),
});

function SuppliesStoreImportEdit() {
  // React Router
  const { id: suppliesImportBillId } = useParams();
  const navigate = useNavigate();

  // Redux
  const dispatch = useAppDispatch();
  const suppliesStoreImportInfo = useAppSelector(selectSuppliesStoreImportInfo);
  const suppliesStoreImportDetails = useAppSelector(selectSuppliesStoreImportDetails);

  // React State
  const [isOpenSaveModal, setOpenSaveModal] = useState(false);
  const isValidSuppliesDetails = useMemo(() => {
    if (!suppliesStoreImportDetails || suppliesStoreImportDetails.length === 0) {
      return false;
    }
    for (const suppliesStoreImportDetail of suppliesStoreImportDetails) {
      if (
        !suppliesStoreImportDetail.ngayHetHan ||
        !suppliesStoreImportDetail.soLuong ||
        suppliesStoreImportDetail.soLuong < 1
      ) {
        return false;
      }
    }
    return true;
  }, [suppliesStoreImportDetails]);

  // React Query
  const { data: suppliesImportBill, isLoading: suppliesImportBillIsLoading } = useKhovtTransPhieuNhapControllerFindById(
    Number(suppliesImportBillId),
    {
      query: {
        enabled: !!suppliesImportBillId,
      },
    },
  );

  const { isPending: updatedSuppliesImportBillIsPending, mutateAsync: updatedSuppliesImportBillMutateAsync } =
    useKhovtTransPhieuNhapControllerUpdateById();

  // React Effect
  useEffect(() => {
    dispatch(resetSuppliesStoreImportEditState());
  }, [dispatch]);

  useEffect(() => {
    if (!suppliesImportBillIsLoading && suppliesImportBill) {
      dispatch(loadSuppliesImportBill(suppliesImportBill));
    }
  }, [suppliesImportBillIsLoading, suppliesImportBill, dispatch]);

  // React function
  const toggleSaveModal = () => setOpenSaveModal(!isOpenSaveModal);

  const handleSaveConfirmClick = async (info: SuppliesStoreImportInfo) => {
    // Close modal
    toggleSaveModal();

    // Create new bill
    updatedSuppliesImportBillMutateAsync({
      id: Number(suppliesImportBillId),
      data: {
        ...info,
        ngayNhap: DateUtil.toDateISOString(info.ngayNhap),
        ngayHoaDon: DateUtil.toDateISOString(info.ngayHoaDon),
        details: normalizeSuppliesDetails(suppliesStoreImportDetails, info),
      },
    })
      .then((updatedSuppliesImportBill) => {
        navigate(Page.SUPPLIES_STORE_IMPORT_DETAIL.PATH.replace(':id', String(updatedSuppliesImportBill.id)));
      })
      .catch((e) => {
        toast.error(e?.response?.data?.error?.message || e.message);
      });
  };

  return (
    <BasicTemplate
      pageTitle={Page.SUPPLIES_STORE_IMPORT_EDIT.TITLE}
      pageName={Page.SUPPLIES_STORE_IMPORT_EDIT.NAME}
      parentPage={{
        name: Page.SUPPLIES_STORE_IMPORT_LIST.NAME,
        path: Page.SUPPLIES_STORE_IMPORT_LIST.PATH,
      }}
      isLoading={suppliesImportBillIsLoading}>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            {suppliesImportBill && (
              <Formik
                initialValues={suppliesStoreImportInfo}
                enableReinitialize
                validationSchema={infoValidationSchema}
                onSubmit={() => {
                  // do nothing
                }}>
                {(formikProps) => (
                  <Card>
                    <CardHeader>
                      <h3 className="mb-0 text-center">SỬA PHIẾU NHẬP #{suppliesImportBillId}</h3>
                    </CardHeader>
                    <CardBody>
                      <SuppliesStoreImportEditInfo formikProps={formikProps} />
                      <SuppliesStoreImportEditTable />
                      <SaveImportBillModal
                        isOpen={isOpenSaveModal}
                        toggle={toggleSaveModal}
                        handleConfirmClick={() => handleSaveConfirmClick(formikProps.values)}
                      />
                    </CardBody>
                    <CardFooter>
                      <div className="d-flex justify-content-end pb-1">
                        <div>
                          <Button
                            color="primary"
                            onClick={toggleSaveModal}
                            disabled={
                              !formikProps.isValid || !isValidSuppliesDetails || updatedSuppliesImportBillIsPending
                            }>
                            Lưu
                          </Button>
                          <Button color="secondary" onClick={() => navigate(Page.SUPPLIES_STORE_IMPORT_LIST.PATH)}>
                            Hủy
                          </Button>
                        </div>
                      </div>
                    </CardFooter>
                  </Card>
                )}
              </Formik>
            )}
          </div>
        </Row>
      </Container>
    </BasicTemplate>
  );
}

export default SuppliesStoreImportEdit;
