import ExcelJS from 'exceljs';
import { KhovtTransPhieuNhapControllerFindDetail200DataItem } from 'main/apis/drc/models';
import { ExportSuppliesISOReportFilter } from 'main/components/pages/admin/supplies-store-inventory/supplies-store-inventory-list/sub-components/supplies-store-inventory-table/export-supplies-iso-report-modal';
import AbstractExcelWriter from 'main/utils/excel-writer/abstract-excel-writer';
import { writeDataSheetKH10 } from './services';

class SuppliesStoreImportDetailExcelWriter extends AbstractExcelWriter<{
  filter: ExportSuppliesISOReportFilter;
  content: {
    importItems: KhovtTransPhieuNhapControllerFindDetail200DataItem[];
  };
}> {
  protected async writeDataImpl(): Promise<ExcelJS.Buffer> {
    const workbook = this.workbook;
    if (!workbook) {
      throw new Error('Invalid template');
    }

    // 5. Sheet[KH.10]
    this.writeDataSheetKH10(workbook);

    return await workbook.xlsx.writeBuffer();
  }

  private writeDataSheetKH10(workbook: ExcelJS.Workbook): void {
    writeDataSheetKH10(workbook, { filter: this.data?.filter, items: this.data?.content.importItems });
  }
}

export default SuppliesStoreImportDetailExcelWriter;
