/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  LoopbackCount,
  NewQdhdsdMasterTaiLieu,
  QdhdsdMasterTaiLieu,
  QdhdsdMasterTaiLieuControllerCountParams,
  QdhdsdMasterTaiLieuControllerFindByIdParams,
  QdhdsdMasterTaiLieuControllerFindParams,
  QdhdsdMasterTaiLieuControllerUploadBody,
  QdhdsdMasterTaiLieuPartial,
  UploadResponse
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const qdhdsdMasterTaiLieuControllerCount = (
    params?: QdhdsdMasterTaiLieuControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/qdhdsd-master-tailieus/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getQdhdsdMasterTaiLieuControllerCountQueryKey = (params?: QdhdsdMasterTaiLieuControllerCountParams,) => {
    return [`/qdhdsd-master-tailieus/count`, ...(params ? [params]: [])] as const;
    }

    
export const getQdhdsdMasterTaiLieuControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerCount>>, TError = ErrorType<unknown>>(params?: QdhdsdMasterTaiLieuControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQdhdsdMasterTaiLieuControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerCount>>> = ({ signal }) => qdhdsdMasterTaiLieuControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type QdhdsdMasterTaiLieuControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerCount>>>
export type QdhdsdMasterTaiLieuControllerCountQueryError = ErrorType<unknown>

export const useQdhdsdMasterTaiLieuControllerCount = <TData = Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerCount>>, TError = ErrorType<unknown>>(
 params?: QdhdsdMasterTaiLieuControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQdhdsdMasterTaiLieuControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const qdhdsdMasterTaiLieuControllerUpload = (
    qdhdsdMasterTaiLieuControllerUploadBody: QdhdsdMasterTaiLieuControllerUploadBody,
 ) => {
      
      const formData = new FormData();
if(qdhdsdMasterTaiLieuControllerUploadBody.file !== undefined) {
 formData.append('file', qdhdsdMasterTaiLieuControllerUploadBody.file)
 }

      return drcInstance<UploadResponse>(
      {url: `/qdhdsd-master-tailieus/upload`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


export const getQdhdsdMasterTaiLieuControllerUploadMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerUpload>>, TError,{data: QdhdsdMasterTaiLieuControllerUploadBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerUpload>>, TError,{data: QdhdsdMasterTaiLieuControllerUploadBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerUpload>>, {data: QdhdsdMasterTaiLieuControllerUploadBody}> = (props) => {
          const {data} = props ?? {};

          return  qdhdsdMasterTaiLieuControllerUpload(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QdhdsdMasterTaiLieuControllerUploadMutationResult = NonNullable<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerUpload>>>
    export type QdhdsdMasterTaiLieuControllerUploadMutationBody = QdhdsdMasterTaiLieuControllerUploadBody
    export type QdhdsdMasterTaiLieuControllerUploadMutationError = ErrorType<unknown>

    export const useQdhdsdMasterTaiLieuControllerUpload = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerUpload>>, TError,{data: QdhdsdMasterTaiLieuControllerUploadBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerUpload>>,
        TError,
        {data: QdhdsdMasterTaiLieuControllerUploadBody},
        TContext
      > => {

      const mutationOptions = getQdhdsdMasterTaiLieuControllerUploadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const qdhdsdMasterTaiLieuControllerUpdateById = (
    id: number,
    qdhdsdMasterTaiLieuPartial: QdhdsdMasterTaiLieuPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/qdhdsd-master-tailieus/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: qdhdsdMasterTaiLieuPartial
    },
      );
    }
  


export const getQdhdsdMasterTaiLieuControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerUpdateById>>, TError,{id: number;data: QdhdsdMasterTaiLieuPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerUpdateById>>, TError,{id: number;data: QdhdsdMasterTaiLieuPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerUpdateById>>, {id: number;data: QdhdsdMasterTaiLieuPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  qdhdsdMasterTaiLieuControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QdhdsdMasterTaiLieuControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerUpdateById>>>
    export type QdhdsdMasterTaiLieuControllerUpdateByIdMutationBody = QdhdsdMasterTaiLieuPartial
    export type QdhdsdMasterTaiLieuControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useQdhdsdMasterTaiLieuControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerUpdateById>>, TError,{id: number;data: QdhdsdMasterTaiLieuPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerUpdateById>>,
        TError,
        {id: number;data: QdhdsdMasterTaiLieuPartial},
        TContext
      > => {

      const mutationOptions = getQdhdsdMasterTaiLieuControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const qdhdsdMasterTaiLieuControllerFindById = (
    id: number,
    params?: QdhdsdMasterTaiLieuControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<QdhdsdMasterTaiLieu>(
      {url: `/qdhdsd-master-tailieus/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getQdhdsdMasterTaiLieuControllerFindByIdQueryKey = (id: number,
    params?: QdhdsdMasterTaiLieuControllerFindByIdParams,) => {
    return [`/qdhdsd-master-tailieus/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getQdhdsdMasterTaiLieuControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerFindById>>, TError = ErrorType<unknown>>(id: number,
    params?: QdhdsdMasterTaiLieuControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQdhdsdMasterTaiLieuControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerFindById>>> = ({ signal }) => qdhdsdMasterTaiLieuControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type QdhdsdMasterTaiLieuControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerFindById>>>
export type QdhdsdMasterTaiLieuControllerFindByIdQueryError = ErrorType<unknown>

export const useQdhdsdMasterTaiLieuControllerFindById = <TData = Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerFindById>>, TError = ErrorType<unknown>>(
 id: number,
    params?: QdhdsdMasterTaiLieuControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQdhdsdMasterTaiLieuControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const qdhdsdMasterTaiLieuControllerDeleteById = (
    id: number,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/qdhdsd-master-tailieus/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getQdhdsdMasterTaiLieuControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerDeleteById>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerDeleteById>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  qdhdsdMasterTaiLieuControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QdhdsdMasterTaiLieuControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerDeleteById>>>
    
    export type QdhdsdMasterTaiLieuControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useQdhdsdMasterTaiLieuControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerDeleteById>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getQdhdsdMasterTaiLieuControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const qdhdsdMasterTaiLieuControllerCreate = (
    newQdhdsdMasterTaiLieu: NewQdhdsdMasterTaiLieu,
 ) => {
      
      
      return drcInstance<QdhdsdMasterTaiLieu>(
      {url: `/qdhdsd-master-tailieus`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newQdhdsdMasterTaiLieu
    },
      );
    }
  


export const getQdhdsdMasterTaiLieuControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerCreate>>, TError,{data: NewQdhdsdMasterTaiLieu}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerCreate>>, TError,{data: NewQdhdsdMasterTaiLieu}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerCreate>>, {data: NewQdhdsdMasterTaiLieu}> = (props) => {
          const {data} = props ?? {};

          return  qdhdsdMasterTaiLieuControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QdhdsdMasterTaiLieuControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerCreate>>>
    export type QdhdsdMasterTaiLieuControllerCreateMutationBody = NewQdhdsdMasterTaiLieu
    export type QdhdsdMasterTaiLieuControllerCreateMutationError = ErrorType<unknown>

    export const useQdhdsdMasterTaiLieuControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerCreate>>, TError,{data: NewQdhdsdMasterTaiLieu}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerCreate>>,
        TError,
        {data: NewQdhdsdMasterTaiLieu},
        TContext
      > => {

      const mutationOptions = getQdhdsdMasterTaiLieuControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const qdhdsdMasterTaiLieuControllerFind = (
    params?: QdhdsdMasterTaiLieuControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<QdhdsdMasterTaiLieu[]>(
      {url: `/qdhdsd-master-tailieus`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getQdhdsdMasterTaiLieuControllerFindQueryKey = (params?: QdhdsdMasterTaiLieuControllerFindParams,) => {
    return [`/qdhdsd-master-tailieus`, ...(params ? [params]: [])] as const;
    }

    
export const getQdhdsdMasterTaiLieuControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerFind>>, TError = ErrorType<unknown>>(params?: QdhdsdMasterTaiLieuControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQdhdsdMasterTaiLieuControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerFind>>> = ({ signal }) => qdhdsdMasterTaiLieuControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type QdhdsdMasterTaiLieuControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerFind>>>
export type QdhdsdMasterTaiLieuControllerFindQueryError = ErrorType<unknown>

export const useQdhdsdMasterTaiLieuControllerFind = <TData = Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerFind>>, TError = ErrorType<unknown>>(
 params?: QdhdsdMasterTaiLieuControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qdhdsdMasterTaiLieuControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQdhdsdMasterTaiLieuControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



