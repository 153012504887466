import saveAs from 'file-saver';
import { useKhovtXuatNhapTonControllerFind } from 'main/apis/drc/endpoints/khovt-xuat-nhap-ton-controller/khovt-xuat-nhap-ton-controller';
import {
  KhovtXuatNhapTonControllerFind200,
  KhovtXuatNhapTonControllerFind200DataItem,
  KhovtXuatNhapTonControllerFindParams,
} from 'main/apis/drc/models';
import { BasicSpinner } from 'main/components/atoms/spiner';
import { DateFormat } from 'main/constants';
import { FieldName } from 'main/constants/enums';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  InventoryFilter,
  selectInventoryFilter,
  selectInventoryTableExcludeSelectedRows,
  updateInventoryTable,
} from 'main/redux/modules/supplies-store-inventory/supplies-store-inventory-list-slice';
import { SuppliesStoreInventoryExcelWriter } from 'main/utils/excel-writer';
import { useCallback } from 'react';
import BootstrapTable, { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import tableConfig from './table-config';

type Props = {
  data?: KhovtXuatNhapTonControllerFind200;
  dataIsLoading: boolean;
};

function SuppliesStoreInventoryTable({
  data: { data, count: dataCount } = { data: [], count: 0 },
  dataIsLoading,
}: Props) {
  // Redux
  const dispatch = useAppDispatch();
  const { page, sizePerPage } = useAppSelector(selectInventoryTableExcludeSelectedRows);
  const filter = useAppSelector(selectInventoryFilter);

  // React Query
  const { refetch: suppliesInventoryReportRefetch } = useKhovtXuatNhapTonControllerFind(
    getSuppliesInventoryReportParams(filter),
    { query: { enabled: false } },
  );

  // React Function
  const handleTableChange = useCallback(
    (_: TableChangeType, newState: TableChangeState<KhovtXuatNhapTonControllerFind200DataItem>) => {
      const { page, sizePerPage } = newState;

      dispatch(
        updateInventoryTable({
          page,
          sizePerPage,
        }),
      );
    },
    [dispatch],
  );

  const handleExportClick = useCallback(async () => {
    suppliesInventoryReportRefetch()
      .then(async ({ data }) => {
        // Create report file
        const excelBuffer = await new SuppliesStoreInventoryExcelWriter().writeData({
          filter: filter,
          suppliesInventoryItems: data?.data || [],
        });
        // Auto download
        if (excelBuffer) {
          saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), 'Báo-cáo-XNT-Vật-Tư.xlsx');
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.error?.message || e.message);
      });
  }, [filter, suppliesInventoryReportRefetch]);

  return (
    <ToolkitProvider
      data={data}
      keyField={`${FieldName.MA_NHOM}_${FieldName.MA_VT}_${FieldName.MA_KHO}`}
      columns={tableConfig.columns}>
      {(props: any) => (
        <>
          <div className="d-flex justify-content-end mb-4 pb-1">
            <Button color="primary" onClick={handleExportClick}>
              Xuất file báo cáo
            </Button>
          </div>
          <div className="table-responsive">
            <BootstrapTable
              {...props.baseProps}
              remote
              bootstrap4
              bordered={false}
              pagination={paginationFactory({
                ...tableConfig.pagination,
                page: page,
                sizePerPage: sizePerPage,
                totalSize: dataCount,
              })}
              onTableChange={handleTableChange}
              noDataIndication={() => (
                <div className="text-center">
                  {dataIsLoading ? <BasicSpinner /> : 'Không có kết quả nào được tìm thấy'}
                </div>
              )}
            />
          </div>
        </>
      )}
    </ToolkitProvider>
  );
}

const getSuppliesInventoryReportParams = (filter: InventoryFilter): KhovtXuatNhapTonControllerFindParams => {
  const where = getSuppliesInventoryReportCountParams(filter);

  return {
    searchParams: {
      ...where,
    },
  };
};

const getSuppliesInventoryReportCountParams = (filter: InventoryFilter) => {
  const { startDate, endDate, suppliesStore, suppliesGroup, suppliesCode } = filter;

  return {
    maVt: suppliesCode ? suppliesCode : undefined,
    maNhom: suppliesGroup && suppliesGroup !== '-1' ? suppliesGroup : undefined,
    maKho: suppliesStore && suppliesStore !== '-1' ? suppliesStore : undefined,
    fromDate: startDate ? startDate.format(DateFormat.DATE_ONLY) : undefined,
    toDate: endDate ? endDate.format(DateFormat.DATE_ONLY) : undefined,
  };
};

export default SuppliesStoreInventoryTable;
