/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CreateLenhXuatTpRequestPartial,
  KhotpTransLenhXuat,
  KhotpTransLenhXuatControllerCountParams,
  KhotpTransLenhXuatControllerFindByIdParams,
  KhotpTransLenhXuatControllerFindParams,
  KhotpTransLenhXuatControllerSyncDRCResultParams,
  KhotpTransLenhXuatControllerUploadPdfAndCreateBody,
  KhotpTransLenhXuatWithRelations,
  LoopbackCount,
  NewKhotpTransLenhXuat
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khotpTransLenhXuatControllerFindRfid = (
    
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpTransLenhXuatWithRelations[]>(
      {url: `/khotp-trans-lenh-xuats/Rfidlist`, method: 'GET', signal
    },
      );
    }
  

export const getKhotpTransLenhXuatControllerFindRfidQueryKey = () => {
    return [`/khotp-trans-lenh-xuats/Rfidlist`] as const;
    }

    
export const getKhotpTransLenhXuatControllerFindRfidQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransLenhXuatControllerFindRfid>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFindRfid>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransLenhXuatControllerFindRfidQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFindRfid>>> = ({ signal }) => khotpTransLenhXuatControllerFindRfid(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFindRfid>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransLenhXuatControllerFindRfidQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFindRfid>>>
export type KhotpTransLenhXuatControllerFindRfidQueryError = ErrorType<unknown>

export const useKhotpTransLenhXuatControllerFindRfid = <TData = Awaited<ReturnType<typeof khotpTransLenhXuatControllerFindRfid>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFindRfid>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransLenhXuatControllerFindRfidQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpTransLenhXuatControllerCount = (
    params?: KhotpTransLenhXuatControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-trans-lenh-xuats/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpTransLenhXuatControllerCountQueryKey = (params?: KhotpTransLenhXuatControllerCountParams,) => {
    return [`/khotp-trans-lenh-xuats/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpTransLenhXuatControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransLenhXuatControllerCount>>, TError = ErrorType<unknown>>(params?: KhotpTransLenhXuatControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransLenhXuatControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransLenhXuatControllerCount>>> = ({ signal }) => khotpTransLenhXuatControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransLenhXuatControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhXuatControllerCount>>>
export type KhotpTransLenhXuatControllerCountQueryError = ErrorType<unknown>

export const useKhotpTransLenhXuatControllerCount = <TData = Awaited<ReturnType<typeof khotpTransLenhXuatControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhotpTransLenhXuatControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransLenhXuatControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpTransLenhXuatControllerUploadPdfAndCreate = (
    khotpTransLenhXuatControllerUploadPdfAndCreateBody: KhotpTransLenhXuatControllerUploadPdfAndCreateBody,
 ) => {
      
      const formData = new FormData();
if(khotpTransLenhXuatControllerUploadPdfAndCreateBody.file !== undefined) {
 formData.append('file', khotpTransLenhXuatControllerUploadPdfAndCreateBody.file)
 }

      return drcInstance<KhotpTransLenhXuat>(
      {url: `/khotp-trans-lenh-xuats/upload-pdf`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


export const getKhotpTransLenhXuatControllerUploadPdfAndCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerUploadPdfAndCreate>>, TError,{data: KhotpTransLenhXuatControllerUploadPdfAndCreateBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerUploadPdfAndCreate>>, TError,{data: KhotpTransLenhXuatControllerUploadPdfAndCreateBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransLenhXuatControllerUploadPdfAndCreate>>, {data: KhotpTransLenhXuatControllerUploadPdfAndCreateBody}> = (props) => {
          const {data} = props ?? {};

          return  khotpTransLenhXuatControllerUploadPdfAndCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransLenhXuatControllerUploadPdfAndCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhXuatControllerUploadPdfAndCreate>>>
    export type KhotpTransLenhXuatControllerUploadPdfAndCreateMutationBody = KhotpTransLenhXuatControllerUploadPdfAndCreateBody
    export type KhotpTransLenhXuatControllerUploadPdfAndCreateMutationError = ErrorType<unknown>

    export const useKhotpTransLenhXuatControllerUploadPdfAndCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerUploadPdfAndCreate>>, TError,{data: KhotpTransLenhXuatControllerUploadPdfAndCreateBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransLenhXuatControllerUploadPdfAndCreate>>,
        TError,
        {data: KhotpTransLenhXuatControllerUploadPdfAndCreateBody},
        TContext
      > => {

      const mutationOptions = getKhotpTransLenhXuatControllerUploadPdfAndCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransLenhXuatControllerUpdateById = (
    id: number,
    createLenhXuatTpRequestPartial: CreateLenhXuatTpRequestPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-trans-lenh-xuats/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: createLenhXuatTpRequestPartial
    },
      );
    }
  


export const getKhotpTransLenhXuatControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerUpdateById>>, TError,{id: number;data: CreateLenhXuatTpRequestPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerUpdateById>>, TError,{id: number;data: CreateLenhXuatTpRequestPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransLenhXuatControllerUpdateById>>, {id: number;data: CreateLenhXuatTpRequestPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpTransLenhXuatControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransLenhXuatControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhXuatControllerUpdateById>>>
    export type KhotpTransLenhXuatControllerUpdateByIdMutationBody = CreateLenhXuatTpRequestPartial
    export type KhotpTransLenhXuatControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhotpTransLenhXuatControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerUpdateById>>, TError,{id: number;data: CreateLenhXuatTpRequestPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransLenhXuatControllerUpdateById>>,
        TError,
        {id: number;data: CreateLenhXuatTpRequestPartial},
        TContext
      > => {

      const mutationOptions = getKhotpTransLenhXuatControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransLenhXuatControllerFindById = (
    id: number,
    params?: KhotpTransLenhXuatControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpTransLenhXuatWithRelations>(
      {url: `/khotp-trans-lenh-xuats/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpTransLenhXuatControllerFindByIdQueryKey = (id: number,
    params?: KhotpTransLenhXuatControllerFindByIdParams,) => {
    return [`/khotp-trans-lenh-xuats/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpTransLenhXuatControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransLenhXuatControllerFindById>>, TError = ErrorType<unknown>>(id: number,
    params?: KhotpTransLenhXuatControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransLenhXuatControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFindById>>> = ({ signal }) => khotpTransLenhXuatControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransLenhXuatControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFindById>>>
export type KhotpTransLenhXuatControllerFindByIdQueryError = ErrorType<unknown>

export const useKhotpTransLenhXuatControllerFindById = <TData = Awaited<ReturnType<typeof khotpTransLenhXuatControllerFindById>>, TError = ErrorType<unknown>>(
 id: number,
    params?: KhotpTransLenhXuatControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransLenhXuatControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpTransLenhXuatControllerDeleteById = (
    id: number,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-trans-lenh-xuats/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhotpTransLenhXuatControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerDeleteById>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransLenhXuatControllerDeleteById>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  khotpTransLenhXuatControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransLenhXuatControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhXuatControllerDeleteById>>>
    
    export type KhotpTransLenhXuatControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhotpTransLenhXuatControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransLenhXuatControllerDeleteById>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getKhotpTransLenhXuatControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransLenhXuatControllerCreate = (
    newKhotpTransLenhXuat: NewKhotpTransLenhXuat,
 ) => {
      
      
      return drcInstance<KhotpTransLenhXuat>(
      {url: `/khotp-trans-lenh-xuats`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhotpTransLenhXuat
    },
      );
    }
  


export const getKhotpTransLenhXuatControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerCreate>>, TError,{data: NewKhotpTransLenhXuat}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerCreate>>, TError,{data: NewKhotpTransLenhXuat}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransLenhXuatControllerCreate>>, {data: NewKhotpTransLenhXuat}> = (props) => {
          const {data} = props ?? {};

          return  khotpTransLenhXuatControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransLenhXuatControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhXuatControllerCreate>>>
    export type KhotpTransLenhXuatControllerCreateMutationBody = NewKhotpTransLenhXuat
    export type KhotpTransLenhXuatControllerCreateMutationError = ErrorType<unknown>

    export const useKhotpTransLenhXuatControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerCreate>>, TError,{data: NewKhotpTransLenhXuat}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransLenhXuatControllerCreate>>,
        TError,
        {data: NewKhotpTransLenhXuat},
        TContext
      > => {

      const mutationOptions = getKhotpTransLenhXuatControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransLenhXuatControllerFind = (
    params?: KhotpTransLenhXuatControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpTransLenhXuatWithRelations[]>(
      {url: `/khotp-trans-lenh-xuats`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpTransLenhXuatControllerFindQueryKey = (params?: KhotpTransLenhXuatControllerFindParams,) => {
    return [`/khotp-trans-lenh-xuats`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpTransLenhXuatControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransLenhXuatControllerFind>>, TError = ErrorType<unknown>>(params?: KhotpTransLenhXuatControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransLenhXuatControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFind>>> = ({ signal }) => khotpTransLenhXuatControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransLenhXuatControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFind>>>
export type KhotpTransLenhXuatControllerFindQueryError = ErrorType<unknown>

export const useKhotpTransLenhXuatControllerFind = <TData = Awaited<ReturnType<typeof khotpTransLenhXuatControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhotpTransLenhXuatControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransLenhXuatControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpTransLenhXuatControllerSyncDRCResult = (
    id: number,
    params?: KhotpTransLenhXuatControllerSyncDRCResultParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<unknown>(
      {url: `/start-rfid/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpTransLenhXuatControllerSyncDRCResultQueryKey = (id: number,
    params?: KhotpTransLenhXuatControllerSyncDRCResultParams,) => {
    return [`/start-rfid/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpTransLenhXuatControllerSyncDRCResultQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransLenhXuatControllerSyncDRCResult>>, TError = ErrorType<unknown>>(id: number,
    params?: KhotpTransLenhXuatControllerSyncDRCResultParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerSyncDRCResult>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransLenhXuatControllerSyncDRCResultQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransLenhXuatControllerSyncDRCResult>>> = ({ signal }) => khotpTransLenhXuatControllerSyncDRCResult(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerSyncDRCResult>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransLenhXuatControllerSyncDRCResultQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhXuatControllerSyncDRCResult>>>
export type KhotpTransLenhXuatControllerSyncDRCResultQueryError = ErrorType<unknown>

export const useKhotpTransLenhXuatControllerSyncDRCResult = <TData = Awaited<ReturnType<typeof khotpTransLenhXuatControllerSyncDRCResult>>, TError = ErrorType<unknown>>(
 id: number,
    params?: KhotpTransLenhXuatControllerSyncDRCResultParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhXuatControllerSyncDRCResult>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransLenhXuatControllerSyncDRCResultQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



