import { KhotpTransLenhNhapRawDetailWithRelations, KhotpTransLenhNhapWithRelations } from 'main/apis/drc/models';
import { DateFormat } from 'main/constants';
import { ProductExcelTemplateType } from 'main/constants/enums';
import { AbstractExcelReader } from '.';
import { ExcelUtil } from '..';

const SHEET_RANGE_CONSTANTS: {
  [key: string]: {
    ROW_DATA_BEGGIN_INDEX: number;
    ATTRIBUTE_INDEXS: {
      maSanPham: number;
      soLuong?: number;
      maPhieuXuat?: number;
      barCode?: number;
      maKhoNhap?: number;
      maKhoXuat?: number;
      ngayNhap?: number;
      ghiChu?: number;
    };
  };
} = {
  [ProductExcelTemplateType['luyen-bias'].id]: {
    ROW_DATA_BEGGIN_INDEX: 6,
    ATTRIBUTE_INDEXS: {
      maSanPham: 2,
      maPhieuXuat: 3,
      maKhoNhap: 12,
      maKhoXuat: 8,
      barCode: 0,
      ngayNhap: 5,
    },
  },
  [ProductExcelTemplateType['luyen-radial'].id]: {
    ROW_DATA_BEGGIN_INDEX: 1,
    ATTRIBUTE_INDEXS: {
      maSanPham: 2,
      maPhieuXuat: 3,
      maKhoNhap: 13,
      maKhoXuat: 8,
      barCode: 0,
      ngayNhap: 5,
      ghiChu: 16,
    },
  },
  [ProductExcelTemplateType['xdxm'].id]: {
    ROW_DATA_BEGGIN_INDEX: 10,
    ATTRIBUTE_INDEXS: {
      maSanPham: 2,
      maPhieuXuat: 3,
      maKhoNhap: 13,
      maKhoXuat: 8,
      barCode: 0,
      ngayNhap: 5,
      ghiChu: 16,
    },
  },
};

class ProductStoreImportExcelReader extends AbstractExcelReader<KhotpTransLenhNhapWithRelations> {
  protected readDataImpl(): KhotpTransLenhNhapWithRelations {
    if (!this.workbook) {
      throw new Error('Invalid template');
    }

    if (!(this.sheetName in SHEET_RANGE_CONSTANTS)) {
      throw new Error(`Invalid sheet names, sheet name must be in ${Object.keys(SHEET_RANGE_CONSTANTS)}`);
    }

    const sheetName = String(this.sheetName as keyof typeof SHEET_RANGE_CONSTANTS);
    const workbookSheetName = this.workbook.SheetNames[0];
    const sheetDataRange = ExcelUtil.getSheetRange(this.workbook, workbookSheetName);
    const colBeginIndex = 0;
    const colEndIndex = sheetDataRange.e.c;
    const rowBeginIndex = 0;
    const rowEndIndex = sheetDataRange.e.r;
    const numberOfRecords = rowEndIndex - 1;

    // Load records from excel
    const recordsRawData = ExcelUtil.loadDataByRange(this.workbook, workbookSheetName, {
      colBeginIndex,
      rowBeginIndex,
      colEndIndex,
      rowEndIndex,
    });

    // Convert raw data to dto
    const dto: KhotpTransLenhNhapWithRelations = {
      loaiLenhNhap: sheetName,
      rawDetails: [],
    };
    const rowDataBegginIndex = SHEET_RANGE_CONSTANTS[sheetName].ROW_DATA_BEGGIN_INDEX;
    const attributeIndexs = SHEET_RANGE_CONSTANTS[sheetName].ATTRIBUTE_INDEXS;
    for (let recordIndex = rowDataBegginIndex; recordIndex < numberOfRecords; recordIndex++) {
      if (recordsRawData[recordIndex][attributeIndexs.maSanPham]) {
        // Convert to detail dto
        const recordDto: KhotpTransLenhNhapRawDetailWithRelations = {};
        recordDto.maSanPham = ExcelUtil.normalizeCellData(recordsRawData[recordIndex][attributeIndexs.maSanPham]);
        if (attributeIndexs.soLuong !== undefined) {
          recordDto.soLuong = Number(ExcelUtil.normalizeCellData(recordsRawData[recordIndex][attributeIndexs.soLuong]));
        } else {
          recordDto.soLuong = 1;
        }
        if (attributeIndexs.maPhieuXuat !== undefined) {
          recordDto.maPhieuXuat = ExcelUtil.normalizeCellData(recordsRawData[recordIndex][attributeIndexs.maPhieuXuat]);
        }
        if (attributeIndexs.maKhoNhap !== undefined) {
          recordDto.maKhoNhap = ExcelUtil.normalizeCellData(recordsRawData[recordIndex][attributeIndexs.maKhoNhap]);
        }
        if (attributeIndexs.maKhoXuat !== undefined) {
          recordDto.maKhoXuat = ExcelUtil.normalizeCellData(recordsRawData[recordIndex][attributeIndexs.maKhoXuat]);
        }
        if (attributeIndexs.barCode !== undefined) {
          recordDto.barCode = ExcelUtil.normalizeCellData(recordsRawData[recordIndex][attributeIndexs.barCode]);
        }
        if (attributeIndexs.ngayNhap !== undefined) {
          recordDto.ngayNhap = ExcelUtil.normalizeCellData(
            recordsRawData[recordIndex][attributeIndexs.ngayNhap],
            DateFormat.DATE_ONLY_VN,
          );
        }
        if (attributeIndexs.ghiChu !== undefined) {
          recordDto.ghiChu = ExcelUtil.normalizeCellData(recordsRawData[recordIndex][attributeIndexs.ghiChu]);
        }

        // Add detail to array
        dto.rawDetails?.push(recordDto);
      }
    }

    return dto;
  }
}

export default ProductStoreImportExcelReader;
