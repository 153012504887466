import { CircularProgress } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AutocompleteCell from 'main/components/atoms/autocomplete/AutocompleteCell';
import { DateFormat } from 'main/constants';
import { FieldName } from 'main/constants/enums';
import useSuppliesOptions, { SuppliesFilter } from 'main/hooks/useSuppliesOptions';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  deleteSuppliesDetail,
  selectSuppliesDetails,
  updateSuppliesDetail,
} from 'main/redux/modules/supplies-store-import/supplies-store-import-add-slice';
import { generateColumnStyle } from 'main/utils';
import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormGroup, Input } from 'reactstrap';

const useTableConfig = () => {
  const dispatch = useAppDispatch();
  const suppliesDetails = useAppSelector(selectSuppliesDetails);
  const { suppliesOptions, suppliesIsLoading } = useSuppliesOptions(false);
  const [deletingRows, setDeletingRows] = useState<number[]>([]);

  const handleDeleteSuppliesDetails = async (id: number) => {
    if (deletingRows.includes(id)) {
      return;
    }

    setDeletingRows((prev) => [...prev, id]);
    try {
      await dispatch(deleteSuppliesDetail(id));
    } catch (error) {
    } finally {
      setDeletingRows((prev) => prev.filter((rowId) => rowId !== id));
    }
  };

  const updateSuppliesDetailWithDates = (params: any, newValue: SuppliesFilter) => {
    const currentDate = moment();
    const thoiHanLuuKho = newValue.thoiHanLuuKho || 0;

    const ngayThiNghiem = moment(currentDate).add(1, 'month').format(DateFormat.DATE_ONLY_VN);
    const ngayHetHanLK = moment(currentDate).add(thoiHanLuuKho, 'months').format(DateFormat.DATE_ONLY_VN);

    dispatch(
      updateSuppliesDetail({
        id: params.row?.id,
        changes: {
          maVt: newValue.maVt,
          tenVt: newValue.tenVt,
          dvt: newValue.dvt,
          ghiChu: newValue.ghiChu,
          ngayThiNghiem,
          ngayHetHanLK,
        },
      }),
    );
  };

  const columns: GridColDef[] = [
    {
      field: FieldName.STT,
      headerName: 'STT',
      editable: false,
      sortable: false,
      flex: 0.5,
      cellClassName: 'disable-gray',
      renderCell: (params: any) => {
        const index = suppliesDetails.findIndex((row) => row?.id === params?.id);
        return index !== -1 ? index + 1 : '';
      },
    },
    {
      field: FieldName.MA_VT,
      headerName: 'Vật tư',
      flex: 3,
      renderCell: (params) =>
        suppliesOptions?.length > 0 && (
          <AutocompleteCell
            value={params.value}
            options={suppliesOptions}
            getOptionLabel={(option) => (option ? `${option.maVt} - ${option.tenVt}` : '')}
            onChange={(newValue) => {
              if (newValue) {
                updateSuppliesDetailWithDates(params, newValue);
              }
            }}
          />
        ),
    },
    {
      sortable: false,
      field: FieldName.DVT,
      headerName: 'ĐVT',
      cellClassName: 'disable-gray',
      flex: 0.5,
      editable: false,
    },
    {
      field: FieldName.NGAY_SX,
      headerName: 'Ngày sản xuất',
      flex: 1.5,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={params.value ? moment(params.value) : null}
            format={DateFormat.DATE_ONLY_VN}
            onChange={(newDate) => {
              const serializedDate = newDate ? moment(newDate).format(DateFormat.DATE_ONLY_VN) : null;
              dispatch(
                updateSuppliesDetail({
                  id: params.row?.id,
                  changes: {
                    ngaySx: serializedDate,
                  },
                }),
              );
            }}
            onAccept={(newDate) => {
              const serializedDate = newDate ? moment(newDate).format(DateFormat.DATE_ONLY_VN) : null;
              dispatch(
                updateSuppliesDetail({
                  id: params.row?.id,
                  changes: {
                    ngaySx: serializedDate,
                  },
                }),
              );
            }}
            className="custom-datepicker"
            reduceAnimations
            slotProps={{ inputAdornment: { hidden: true } }}
          />
        </LocalizationProvider>
      ),
    },
    {
      sortable: false,
      field: FieldName.NGAY_HET_HAN,
      headerName: 'Hạn sử dụng',
      flex: 1.5,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={params.value ? moment(params.value) : null}
            format={DateFormat.DATE_ONLY_VN}
            onChange={(newDate) => {
              const serializedDate = newDate ? moment(newDate).format(DateFormat.DATE_ONLY_VN) : null;
              dispatch(
                updateSuppliesDetail({
                  id: params.row?.id,
                  changes: {
                    ngayHetHan: serializedDate,
                  },
                }),
              );
            }}
            onAccept={(newDate: any) => {
              const serializedDate = newDate ? moment(newDate).format(DateFormat.DATE_ONLY_VN) : null;
              dispatch(
                updateSuppliesDetail({
                  id: params.row?.id,
                  changes: {
                    ngayHetHan: serializedDate,
                  },
                }),
              );
            }}
            className="custom-datepicker"
            reduceAnimations
            slotProps={{ inputAdornment: { hidden: true } }}
          />
        </LocalizationProvider>
      ),
      ...generateColumnStyle(),
    },
    {
      sortable: false,
      field: FieldName.NGAY_THI_NGHIEM,
      headerName: 'Hạn thí nghiệm',
      flex: 1.5,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={params.value ? moment(params.value, DateFormat.DATE_ONLY_VN) : null}
            format={DateFormat.DATE_ONLY_VN}
            onChange={(newDate) => {
              dispatch(
                updateSuppliesDetail({
                  id: params.row?.id,
                  changes: {
                    ngayThiNghiem: newDate,
                  },
                }),
              );
            }}
            onAccept={(newDate: any) => {
              dispatch(
                updateSuppliesDetail({
                  id: params.row?.id,
                  changes: {
                    ngayThiNghiem: newDate,
                  },
                }),
              );
            }}
            className="custom-datepicker"
            reduceAnimations
            slotProps={{ inputAdornment: { hidden: true } }}
          />
        </LocalizationProvider>
      ),
      ...generateColumnStyle(),
    },
    {
      sortable: false,
      field: FieldName.NGAY_HET_HAN_LK,
      headerName: 'Hạn lưu kho',
      flex: 1.5,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={params.value ? moment(params.value, DateFormat.DATE_ONLY_VN) : null}
            format={DateFormat.DATE_ONLY_VN}
            onChange={(newDate: any) => {
              dispatch(
                updateSuppliesDetail({
                  id: params.row?.id,
                  changes: {
                    ngayHetHanLK: newDate,
                  },
                }),
              );
            }}
            onAccept={(newDate: any) => {
              dispatch(
                updateSuppliesDetail({
                  id: params.row?.id,
                  changes: {
                    ngayHetHanLK: newDate,
                  },
                }),
              );
            }}
            className="custom-datepicker"
            reduceAnimations
            slotProps={{ inputAdornment: { hidden: true } }}
          />
        </LocalizationProvider>
      ),
    },
    {
      sortable: false,
      field: FieldName.SO_LUONG,
      headerName: 'Số lượng',
      flex: 1,
      editable: true,
      renderCell: (params) => {
        // const isQuantityValid = validateQuantity(params.value);
        return (
          <FormGroup>
            <Input
              type="number"
              required
              value={params.value || ''}
              // invalid={!isQuantityValid}
              onChange={(e) => {
                const value = e.target.value;
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value,
                });
              }}
            />
            {/* {!isQuantityValid && <FormFeedback>Số lượng phải lớn hơn 0</FormFeedback>} */}
          </FormGroup>
        );
      },
    },
    {
      sortable: false,
      field: FieldName.GHI_CHU,
      headerName: 'Ghi chú',
      flex: 1,
      editable: true,
      type: 'string',
      renderCell: (params) => (
        <FormGroup>
          <Input
            type="text"
            value={params.value || ''}
            onChange={(e) => {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              });
            }}
          />
        </FormGroup>
      ),
    },
    {
      sortable: false,
      field: 'actions',
      headerName: '⋮⋮⋮',
      headerAlign: 'center',
      flex: 0.1,
      align: 'center',
      renderCell: (params) => {
        const isDeleting = deletingRows.includes(params?.row?.id);
        return (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              if (!isDeleting) {
                handleDeleteSuppliesDetails(params?.row?.id);
              }
            }}
            style={{ pointerEvents: isDeleting ? 'none' : 'auto', opacity: isDeleting ? 0.5 : 1 }}>
            {isDeleting ? <CircularProgress size={10} /> : <i className="fa-solid fa-trash" title="Xóa" />}
          </Link>
        );
      },
    },
  ];

  return { columns, suppliesIsLoading };
};

export default useTableConfig;
