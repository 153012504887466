const Page = {
  /** Dashboard Pages */
  DASHBOARD: {
    NAME: 'Dashboard',
    PATH: '/admin/dashboard',
  },
  /** Supplies Store Import Pages */
  SUPPLIES_STORE_IMPORT_LIST: {
    TITLE: 'Quản lý nhập kho vật tư',
    NAME: 'Tổng hợp nhập vật tư',
    PATH: '/admin/supplies-store-import/list',
  },
  SUPPLIES_STORE_IMPORT_ADD: {
    TITLE: 'Quản lý nhập kho vật tư',
    NAME: 'Tạo phiếu nhập',
    PATH: '/admin/supplies-store-import/add',
  },
  SUPPLIES_STORE_IMPORT_DETAIL: {
    TITLE: 'Quản lý nhập kho vật tư',
    NAME: 'Chi tiết phiếu nhập',
    PATH: '/admin/supplies-store-import/detail/:id',
  },
  SUPPLIES_STORE_IMPORT_EDIT: {
    TITLE: 'Quản lý nhập kho vật tư',
    NAME: 'Sửa phiếu nhập',
    PATH: '/admin/supplies-store-import/edit/:id',
  },
  /** Supplies Store Export Pages */
  SUPPLIES_STORE_EXPORT_LIST: {
    TITLE: 'Quản lý xuất kho vật tư',
    NAME: 'Tổng hợp xuất vật tư',
    PATH: '/admin/supplies-store-export/list',
  },
  SUPPLIES_STORE_EXPORT_ADD: {
    TITLE: 'Quản lý xuất kho vật tư',
    NAME: 'Tạo phiếu xuất',
    PATH: '/admin/supplies-store-export/add',
  },
  SUPPLIES_STORE_EXPORT_DETAIL: {
    TITLE: 'Quản lý xuất kho vật tư',
    NAME: 'Chi tiết phiếu xuất',
    PATH: '/admin/supplies-store-export/detail/:id',
  },
  /** Supplies Store Inventory Pages */
  SUPPLIES_STORE_INVENTORY_LIST: {
    TITLE: 'Quản lý tồn kho vật tư',
    NAME: 'Tổng hợp xuất nhập tồn',
    PATH: '/admin/supplies-store-inventory/list',
  },
  SUPPLIES_STORE_INVENTORY_WARNING: {
    TITLE: 'Quản lý tồn kho vật tư',
    NAME: 'Cảnh báo tồn kho',
    PATH: '/admin/supplies-store-inventory/warning',
  },
  SUPPLIES_STORE_INVENTORY_DASHBOARD: {
    TITLE: 'Quản lý tồn kho vật tư',
    NAME: 'Dashboard tổng hợp tồn kho',
    PATH: '/admin/supplies-store-inventory/dashboard',
  },
  SUPPLIES_STORE_INVENTORY_CARD: {
    TITLE: 'Quản lý tồn kho vật tư',
    NAME: 'Tổng hợp thẻ kho',
    PATH: '/admin/supplies-store-inventory/card',
  },
  /** Supplies Management Pages */
  SUPPLIES_MANAGEMENT_LIST: {
    TITLE: 'Quản lý vật tư',
    NAME: 'Quản lý vật tư',
    PATH: '/admin/supplies-management/list',
  },
  /** Supplies WareHouse Pages */
  SUPPLIES_WAREHOUSE_LIST: {
    TITLE: 'Quản lý kho vật tư',
    NAME: 'Quản lý kho vật tư',
    PATH: '/admin/supplies-warehouse/list',
  },
  /** Supplies Group Pages */
  SUPPLIES_GROUP_LIST: {
    TITLE: 'Quản lý nhóm vật tư',
    NAME: 'Quản lý nhóm vật tư',
    PATH: '/admin/supplies-group/list',
  },
  /** Supplies Origin Pages */
  SUPPLIES_ORIGIN_LIST: {
    TITLE: 'Quản lý nhà sản xuất',
    NAME: 'Quản lý nhà sản xuất',
    PATH: '/admin/supplies-origin/list',
  },
  /** Supplies Category Pages */
  SUPPLIES_CATEGORY_LIST: {
    NAME: 'Quản lý loại vật tư',
    PATH: '/admin/supplies-category/list',
  },
  SUPPLIES_CATEGORY_EDIT: {
    NAME: 'Quản lý loại vật tư',
    PATH: '/admin/supplies-category/edit',
  },

  /** -------------------------- */
  /** Product Store Import Pages */
  PRODUCT_STORE_IMPORT_LIST: {
    TITLE: 'Quản lý nhập kho sản phẩm',
    NAME: 'Tổng hợp nhập sản phẩm',
    PATH: '/admin/product-store-import/list',
  },
  PRODUCT_STORE_IMPORT_ADD: {
    TITLE: 'Quản lý nhập kho sản phẩm',
    NAME: 'Tạo phiếu nhập',
    PATH: '/admin/product-store-import/add',
  },
  PRODUCT_STORE_IMPORT_DETAIL: {
    TITLE: 'Quản lý nhập kho sản phẩm',
    NAME: 'Chi tiết phiếu nhập',
    PATH: '/admin/product-store-import/detail/:id',
  },
  PRODUCT_STORE_IMPORT_EDIT: {
    TITLE: 'Quản lý nhập kho sản phẩm',
    NAME: 'Sửa phiếu nhập',
    PATH: '/admin/product-store-import/edit/:id',
  },
  /** Product Command Export Pages */
  PRODUCT_COMMAND_EXPORT_LIST: {
    TITLE: 'Quản lý lệnh xuất kho',
    NAME: 'Tổng hợp lệnh xuất',
    PATH: '/admin/product-command-export/list',
  },
  PRODUCT_COMMAND_EXPORT_DETAIL: {
    TITLE: 'Quản lý lệnh xuất kho',
    NAME: 'Chi tiết lệnh xuất',
    PATH: '/admin/product-command-export/detail/:id',
  },
  PRODUCT_COMMAND_EXPORT_EDIT: {
    TITLE: 'Quản lý lệnh xuất kho',
    NAME: 'Sửa lệnh xuất',
    PATH: '/admin/product-command-export/edit/:id',
  },
  /** Product Store Excel Pages */
  PRODUCT_STORE_EXCEL_LIST: {
    TITLE: 'Quản lý lệnh nhập kho',
    NAME: 'Tổng hợp lệnh nhập',
    PATH: '/admin/product-store-excel/list',
  },
  PRODUCT_STORE_EXCEL_DETAIL: {
    TITLE: 'Quản lý lệnh nhập kho',
    NAME: 'Chi tiết lệnh nhập',
    PATH: '/admin/product-store-excel/detail/:id',
  },
  PRODUCT_STORE_EXCEL_EDIT: {
    TITLE: 'Quản lý lệnh nhập kho',
    NAME: 'Sửa lệnh nhập',
    PATH: '/admin/product-store-excel/edit/:id',
  },
  /** Supplies WareHouse Pages */
  PRODUCTS_WAREHOUSE_LIST: {
    TITLE: 'Quản lý kho thành phẩm',
    NAME: 'Quản lý kho thành phẩm',
    PATH: '/admin/product-warehouse/list',
  },

  /** Supplies Group Pages */
  PRODUCTS_GROUP_LIST: {
    TITLE: 'Quản lý nhóm sản phẩm',
    NAME: 'Quản lý nhóm sản phẩm',
    PATH: '/admin/product-group/list',
  },

  /** Product sales product Pages */
  PRODUCTS_SALES_GROUP_LIST: {
    TITLE: 'Quản lý nhóm bán thành phẩm',
    NAME: 'Quản lý nhóm bán thành phẩm',
    PATH: '/admin/product-semi-finished-group/list',
  },

  PRODUCTS_SEMI_FINISHED_LIST: {
    TITLE: 'Quản lý bán thành phẩm',
    NAME: 'Quản lý bán thành phẩm',
    PATH: '/admin/product-semi-finished/list',
  },

  /** Supplies Enterprise Pages */
  PRODUCTS_ENTERPRISE_LIST: {
    TITLE: 'Quản lý đơn vị xí nhiệp',
    NAME: 'Quản lý đơn vị xí nhiệp',
    PATH: '/admin/product-enterprise/list',
  },
  /** Supplies Products Pages */
  PRODUCTS_STOCKS_LIST: {
    TITLE: 'Quản lý sản phẩm',
    NAME: 'Quản lý sản phẩm',
    PATH: '/admin/product/list',
  },

  /** Supplies Customer Pages */
  PRODUCTS_CUSTOMER_LIST: {
    TITLE: 'Quản lý khách hàng',
    NAME: 'Quản lý khách hàng',
    PATH: '/admin/product-customer/list',
  },

  /** Order ManagementPages */
  ORDER_DASHBOARD: {
    TITLE: 'Dashboard đơn đặt hàng',
    NAME: 'Dashboard đơn đặt hàng',
    PATH: '/admin/order-management/list',
  },
  ORDER_MANAGEMENT_LIST: {
    TITLE: 'Quản lý đơn hàng',
    NAME: 'Quản lý đơn hàng',
    PATH: '/admin/order-management/list',
  },
  ORDER_MANAGEMENT_ADD: {
    TITLE: 'Quản lý đơn hàng',
    NAME: 'Tạo đơn hàng',
    PATH: '/admin/order-management/add',
  },
  ORDER_MANAGEMENT_DETAIL: {
    TITLE: 'Quản lý đơn hàng',
    NAME: 'Xem đơn hàng',
    PATH: '/admin/order-management/detail/:id',
  },
  ORDER_MANAGEMENT_EDIT: {
    TITLE: 'Quản lý đơn hàng',
    NAME: 'Sửa đơn hàng',
    PATH: '/admin/order-management/edit/:id',
  },
  ORDER_MANAGEMENT_WARNING: {
    TITLE: 'Quản lý đơn hàng',
    NAME: 'Cảnh báo đơn hàng',
    PATH: '/admin/order-management/warning',
  },

  ORDER_ANALYTICS: {
    TITLE: 'Thống kê đơn hàng',
    NAME: 'Thống kê đơn hàng',
    PATH: 'order-analytics/list',
  },

  /** product Store Export Pages */
  PRODUCT_STORE_EXPORT_LIST: {
    TITLE: 'TỔNG HỢP XUẤT SẢN PHẨM',
    NAME: 'Tổng hợp xuất sản phẩm',
    PATH: '/admin/product-store-export/list',
  },
  PRODUCT_STORE_EXPORT_ADD: {
    TITLE: 'TỔNG HỢP XUẤT SẢN PHẨM',
    NAME: 'Thêm sản phẩm',
    PATH: '/admin/product-store-export/add',
  },
  PRODUCT_STORE_EXPORT_DETAIL: {
    TITLE: 'TỔNG HỢP XUẤT SẢN PHẨM',
    NAME: 'Tổng hợp xuất sản phẩm',
    PATH: '/admin/product-store-export/detail/:id',
  },
  PRODUCT_STORE_EXPORT_EDIT: {
    TITLE: 'TỔNG HỢP XUẤT SẢN PHẨM',
    NAME: 'Tổng hợp xuất sản phẩm',
    PATH: '/admin/product-store-export/edit/:id',
  },
  PRODUCT_STORE_EXPORT_CANCEL: {
    TITLE: 'TỔNG HỢP XUẤT SẢN PHẨM',
    NAME: 'Tổng hợp xuất sản phẩm',
    PATH: '/admin/product-store-export/detail/:id',
  },
  PRODUCT_STORE_INVENTORY_LIST: {
    TITLE: 'Quản lý tồn kho sản phẩm',
    NAME: 'Tổng hợp xuất nhập tồn',
    PATH: '/admin/product-store-inventory/list',
  },
  PRODUCT_STORE_INVENTORY_WARNING: {
    TITLE: 'Quản lý tồn kho sản phẩm',
    NAME: 'Cảnh báo tồn kho',
    PATH: '/admin/product-store-inventory/warning',
  },
  /** Requirements Standard Set Pages */
  REQUIREMENTS_STANDARD_SET_LIST: {
    TITLE: 'Quản lý định mức',
    NAME: 'Quản lý bộ định mức',
    PATH: '/admin/requirements-standard-set/list',
  },
  REQUIREMENTS_STANDARD_SET_DETAIL: {
    TITLE: 'Quản lý định mức',
    NAME: 'Chi tiết bộ định mức',
    PATH: '/admin/requirements-standard-set/detail/:id',
  },
  REQUIREMENTS_STANDARD_SET_COMPARE: {
    TITLE: 'Quản lý định mức',
    NAME: 'So sánh bộ định mức',
    PATH: '/admin/requirements-standard-set/compare',
  },
  /** Terms And Conditions Pages */
  TERMS_AND_CONDITIONS_LIST: {
    TITLE: 'Quy định và hướng dẫn sử dụng',
    NAME: 'Quy định và hướng dẫn sử dụng',
    PATH: '/admin/terms-and-conditions/list',
  },
  /** Authentication Pages */
  LOGIN: {
    TITLE: 'Đăng nhập',
    NAME: 'Đăng nhập',
    PATH: '/auth/login',
  },
  /** User Pages */
  USER_MANAGEMENT: {
    TITLE: 'Hệ thống - Quản lý người dùng',
    NAME: 'Quản lý người dùng',
    PATH: '/admin/system/user',
  },
  /** Title Pages */
  TITLE_MANAGEMENT: {
    TITLE: 'Hệ thống - Quản lý chức danh',
    NAME: 'Quản lý chức danh',
    PATH: '/admin/system/title',
  },
  /** My-Profile Pages */
  MY_PROFILE: {
    TITLE: 'Hồ sơ của tôi',
    NAME: 'Hồ sơ của tôi',
    PATH: '/admin/my-profile',
  },
  /** Error Pages */
  SYSTEM_ERROR: {
    NAME: 'Lỗi',
    PATH: '/error/system-error',
  },
  URL_NOT_FOUND: {
    NAME: 'Lỗi',
    PATH: '/error/url-not-found',
  },
  ACCESS_DENIED: {
    NAME: 'Lỗi',
    PATH: '/error/access-denied',
  },
};

export default Page;
