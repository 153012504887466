/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhotpMasterKhachHang,
  KhotpMasterKhachHangControllerCountParams,
  KhotpMasterKhachHangControllerFindByIdParams,
  KhotpMasterKhachHangControllerFindParams,
  KhotpMasterKhachHangControllerUpdateAllParams,
  KhotpMasterKhachHangPartial,
  KhotpMasterKhachHangWithRelations,
  LoopbackCount,
  NewKhotpMasterKhachHang
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khotpMasterKhachHangControllerCount = (
    params?: KhotpMasterKhachHangControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-khach-hangs/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterKhachHangControllerCountQueryKey = (params?: KhotpMasterKhachHangControllerCountParams,) => {
    return [`/khotp-master-khach-hangs/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterKhachHangControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterKhachHangControllerCount>>, TError = ErrorType<unknown>>(params?: KhotpMasterKhachHangControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterKhachHangControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterKhachHangControllerCount>>> = ({ signal }) => khotpMasterKhachHangControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterKhachHangControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhachHangControllerCount>>>
export type KhotpMasterKhachHangControllerCountQueryError = ErrorType<unknown>

export const useKhotpMasterKhachHangControllerCount = <TData = Awaited<ReturnType<typeof khotpMasterKhachHangControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterKhachHangControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterKhachHangControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterKhachHangControllerImport = (
    khotpMasterKhachHang: KhotpMasterKhachHang[],
 ) => {
      
      
      return drcInstance<KhotpMasterKhachHang[]>(
      {url: `/khotp-master-khach-hangs/import`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterKhachHang
    },
      );
    }
  


export const getKhotpMasterKhachHangControllerImportMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerImport>>, TError,{data: KhotpMasterKhachHang[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerImport>>, TError,{data: KhotpMasterKhachHang[]}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterKhachHangControllerImport>>, {data: KhotpMasterKhachHang[]}> = (props) => {
          const {data} = props ?? {};

          return  khotpMasterKhachHangControllerImport(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterKhachHangControllerImportMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhachHangControllerImport>>>
    export type KhotpMasterKhachHangControllerImportMutationBody = KhotpMasterKhachHang[]
    export type KhotpMasterKhachHangControllerImportMutationError = ErrorType<unknown>

    export const useKhotpMasterKhachHangControllerImport = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerImport>>, TError,{data: KhotpMasterKhachHang[]}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterKhachHangControllerImport>>,
        TError,
        {data: KhotpMasterKhachHang[]},
        TContext
      > => {

      const mutationOptions = getKhotpMasterKhachHangControllerImportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterKhachHangControllerReplaceById = (
    id: string,
    khotpMasterKhachHang: KhotpMasterKhachHang,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-khach-hangs/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterKhachHang
    },
      );
    }
  


export const getKhotpMasterKhachHangControllerReplaceByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerReplaceById>>, TError,{id: string;data: KhotpMasterKhachHang}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerReplaceById>>, TError,{id: string;data: KhotpMasterKhachHang}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterKhachHangControllerReplaceById>>, {id: string;data: KhotpMasterKhachHang}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterKhachHangControllerReplaceById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterKhachHangControllerReplaceByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhachHangControllerReplaceById>>>
    export type KhotpMasterKhachHangControllerReplaceByIdMutationBody = KhotpMasterKhachHang
    export type KhotpMasterKhachHangControllerReplaceByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterKhachHangControllerReplaceById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerReplaceById>>, TError,{id: string;data: KhotpMasterKhachHang}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterKhachHangControllerReplaceById>>,
        TError,
        {id: string;data: KhotpMasterKhachHang},
        TContext
      > => {

      const mutationOptions = getKhotpMasterKhachHangControllerReplaceByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterKhachHangControllerUpdateById = (
    id: string,
    khotpMasterKhachHangPartial: KhotpMasterKhachHangPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-khach-hangs/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterKhachHangPartial
    },
      );
    }
  


export const getKhotpMasterKhachHangControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerUpdateById>>, TError,{id: string;data: KhotpMasterKhachHangPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerUpdateById>>, TError,{id: string;data: KhotpMasterKhachHangPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterKhachHangControllerUpdateById>>, {id: string;data: KhotpMasterKhachHangPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterKhachHangControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterKhachHangControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhachHangControllerUpdateById>>>
    export type KhotpMasterKhachHangControllerUpdateByIdMutationBody = KhotpMasterKhachHangPartial
    export type KhotpMasterKhachHangControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterKhachHangControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerUpdateById>>, TError,{id: string;data: KhotpMasterKhachHangPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterKhachHangControllerUpdateById>>,
        TError,
        {id: string;data: KhotpMasterKhachHangPartial},
        TContext
      > => {

      const mutationOptions = getKhotpMasterKhachHangControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterKhachHangControllerFindById = (
    id: string,
    params?: KhotpMasterKhachHangControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterKhachHangWithRelations>(
      {url: `/khotp-master-khach-hangs/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterKhachHangControllerFindByIdQueryKey = (id: string,
    params?: KhotpMasterKhachHangControllerFindByIdParams,) => {
    return [`/khotp-master-khach-hangs/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterKhachHangControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterKhachHangControllerFindById>>, TError = ErrorType<unknown>>(id: string,
    params?: KhotpMasterKhachHangControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterKhachHangControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterKhachHangControllerFindById>>> = ({ signal }) => khotpMasterKhachHangControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterKhachHangControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhachHangControllerFindById>>>
export type KhotpMasterKhachHangControllerFindByIdQueryError = ErrorType<unknown>

export const useKhotpMasterKhachHangControllerFindById = <TData = Awaited<ReturnType<typeof khotpMasterKhachHangControllerFindById>>, TError = ErrorType<unknown>>(
 id: string,
    params?: KhotpMasterKhachHangControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterKhachHangControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterKhachHangControllerDeleteById = (
    id: string,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-khach-hangs/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhotpMasterKhachHangControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerDeleteById>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterKhachHangControllerDeleteById>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  khotpMasterKhachHangControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterKhachHangControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhachHangControllerDeleteById>>>
    
    export type KhotpMasterKhachHangControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterKhachHangControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterKhachHangControllerDeleteById>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getKhotpMasterKhachHangControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterKhachHangControllerCreate = (
    newKhotpMasterKhachHang: NewKhotpMasterKhachHang,
 ) => {
      
      
      return drcInstance<KhotpMasterKhachHang>(
      {url: `/khotp-master-khach-hangs`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhotpMasterKhachHang
    },
      );
    }
  


export const getKhotpMasterKhachHangControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerCreate>>, TError,{data: NewKhotpMasterKhachHang}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerCreate>>, TError,{data: NewKhotpMasterKhachHang}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterKhachHangControllerCreate>>, {data: NewKhotpMasterKhachHang}> = (props) => {
          const {data} = props ?? {};

          return  khotpMasterKhachHangControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterKhachHangControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhachHangControllerCreate>>>
    export type KhotpMasterKhachHangControllerCreateMutationBody = NewKhotpMasterKhachHang
    export type KhotpMasterKhachHangControllerCreateMutationError = ErrorType<unknown>

    export const useKhotpMasterKhachHangControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerCreate>>, TError,{data: NewKhotpMasterKhachHang}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterKhachHangControllerCreate>>,
        TError,
        {data: NewKhotpMasterKhachHang},
        TContext
      > => {

      const mutationOptions = getKhotpMasterKhachHangControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterKhachHangControllerUpdateAll = (
    khotpMasterKhachHangPartial: KhotpMasterKhachHangPartial,
    params?: KhotpMasterKhachHangControllerUpdateAllParams,
 ) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-khach-hangs`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterKhachHangPartial,
        params
    },
      );
    }
  


export const getKhotpMasterKhachHangControllerUpdateAllMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerUpdateAll>>, TError,{data: KhotpMasterKhachHangPartial;params?: KhotpMasterKhachHangControllerUpdateAllParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerUpdateAll>>, TError,{data: KhotpMasterKhachHangPartial;params?: KhotpMasterKhachHangControllerUpdateAllParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterKhachHangControllerUpdateAll>>, {data: KhotpMasterKhachHangPartial;params?: KhotpMasterKhachHangControllerUpdateAllParams}> = (props) => {
          const {data,params} = props ?? {};

          return  khotpMasterKhachHangControllerUpdateAll(data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterKhachHangControllerUpdateAllMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhachHangControllerUpdateAll>>>
    export type KhotpMasterKhachHangControllerUpdateAllMutationBody = KhotpMasterKhachHangPartial
    export type KhotpMasterKhachHangControllerUpdateAllMutationError = ErrorType<unknown>

    export const useKhotpMasterKhachHangControllerUpdateAll = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerUpdateAll>>, TError,{data: KhotpMasterKhachHangPartial;params?: KhotpMasterKhachHangControllerUpdateAllParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterKhachHangControllerUpdateAll>>,
        TError,
        {data: KhotpMasterKhachHangPartial;params?: KhotpMasterKhachHangControllerUpdateAllParams},
        TContext
      > => {

      const mutationOptions = getKhotpMasterKhachHangControllerUpdateAllMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterKhachHangControllerFind = (
    params?: KhotpMasterKhachHangControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterKhachHangWithRelations[]>(
      {url: `/khotp-master-khach-hangs`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterKhachHangControllerFindQueryKey = (params?: KhotpMasterKhachHangControllerFindParams,) => {
    return [`/khotp-master-khach-hangs`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterKhachHangControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterKhachHangControllerFind>>, TError = ErrorType<unknown>>(params?: KhotpMasterKhachHangControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterKhachHangControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterKhachHangControllerFind>>> = ({ signal }) => khotpMasterKhachHangControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterKhachHangControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterKhachHangControllerFind>>>
export type KhotpMasterKhachHangControllerFindQueryError = ErrorType<unknown>

export const useKhotpMasterKhachHangControllerFind = <TData = Awaited<ReturnType<typeof khotpMasterKhachHangControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterKhachHangControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterKhachHangControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterKhachHangControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



