import ExcelJS from 'exceljs';
import { KhovtTransPhieuXuatControllerFindDetail200DataItem } from 'main/apis/drc/models';
import { DateFormat } from 'main/constants';
import { ExportFilter } from 'main/redux/modules/supplies-store-export/supplies-store-export-list-slice';
import { Moment } from 'moment';

const SHEET_RANGE_CONSTANTS = {
  originCell: [8, 1],
  columns: {
    STT: { alignment: { horizontal: 'center', vertical: 'middle' } },
    'Mã VT': {},
    'Tên VT': {},
    ĐVT: { alignment: { horizontal: 'center', vertical: 'middle' } },
    'Số Lượng': { alignment: { horizontal: 'center', vertical: 'middle' } },
    'Ghi chú': {},
    'Kho Xuất': {},
  },
};

type JsonDataType = {
  [key in keyof typeof SHEET_RANGE_CONSTANTS.columns]: any;
};

export function writeDataSheetTHXuat(
  workbook: ExcelJS.Workbook,
  data?: {
    filter?: ExportFilter;
    items?: KhovtTransPhieuXuatControllerFindDetail200DataItem[];
  },
): void {
  if (!data) {
    return;
  }
  const { filter, items } = data;
  const sheetName = '02 TH Xuất';
  const worksheet = workbook.getWorksheet(sheetName);
  const originCell = SHEET_RANGE_CONSTANTS.originCell;
  const columns = SHEET_RANGE_CONSTANTS.columns;
  const jsonData = transformData(items);
  const headers = Object.keys(columns);

  if (!worksheet || !filter || !items) {
    throw new Error('Invalid template');
  }
  // Write data
  writeSectionOrigin(worksheet, filter.importStore !== '-1' ? items?.[0]?.tenDonVi : 'All');
  writeSectionFromToDate(worksheet, filter.startDate, filter.endDate);
  writeSectionJsonData(worksheet, originCell, headers, columns, jsonData);
}

function transformData(data?: KhovtTransPhieuXuatControllerFindDetail200DataItem[]): JsonDataType[] {
  if (!data || data.length < 1) {
    return [];
  }
  return data.map((item: any, index) => ({
    STT: index + 1,
    'Mã VT': item.maVt,
    'Tên VT': item.tenVt,
    ĐVT: item.dvt,
    'Số Lượng': item.soLuongThucTe,
    'Ghi chú': item.ghiChu,
    'Kho Xuất': item.tenKho,
  }));
}

function writeSectionOrigin(worksheet: ExcelJS.Worksheet, origin?: string) {
  const cell = worksheet.getCell('A4');
  cell.value = `Đơn vị nhận: ${origin}`;
}

function writeSectionFromToDate(worksheet: ExcelJS.Worksheet, fromDate?: Moment, toDate?: Moment) {
  const cell = worksheet.getCell('A5');
  if (fromDate && toDate) {
    cell.value = `Từ ngày: ${fromDate?.format(DateFormat.DATE_ONLY_VN)} Đến ngày: ${toDate?.format(DateFormat.DATE_ONLY_VN)}`;
  } else {
    cell.value = 'Từ trước đến bây giờ';
  }
}

function writeSectionJsonData(
  worksheet: ExcelJS.Worksheet,
  originCell: number[],
  headers: any[],
  columns: any,
  jsonData: JsonDataType[],
) {
  jsonData.forEach((data, rowIndex) => {
    headers.forEach((key, colIndex) => {
      const cell = worksheet.getCell(originCell[0] + rowIndex, originCell[1] + colIndex);
      cell.value = data[key as keyof typeof data];
      const alignment = columns[key as keyof typeof columns].alignment;
      if (alignment) {
        cell.style.alignment = alignment as Partial<ExcelJS.Alignment>;
      }
      cell.style.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  });
}
