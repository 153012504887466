import { KhotpMasterSanPhamWithRelations } from 'main/apis/drc/models';
import { BasicSpinner } from 'main/components/atoms/spiner';
import { FieldName } from 'main/constants/enums';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  selectProductStoreImportDetails,
  selectProductTable,
  selectProductTableSelectedRowIds,
  setProductTableSelectedRows,
  updateProductTable,
} from 'main/redux/modules/product-store-import/product-store-import-edit-slice';
import { useCallback, useEffect } from 'react';
import BootstrapTable, { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import tableConfig from './table-config';

type Props = {
  data?: KhotpMasterSanPhamWithRelations[];
  dataIsLoading: boolean;
  dataCount?: number;
  dataCountIsLoading: boolean;
};

function AddProductModalTable({ data, dataIsLoading, dataCount, dataCountIsLoading }: Props) {
  // Redux
  const dispatch = useAppDispatch();
  const selectedRowIds = useAppSelector(selectProductTableSelectedRowIds);
  const { page, sizePerPage, sortField, sortOrder, selectedRows } = useAppSelector(selectProductTable);
  const productDetails = useAppSelector(selectProductStoreImportDetails);

  // Luôn select lại những row đã được add vào table
  useEffect(() => {
    const tableItemSelectedList: KhotpMasterSanPhamWithRelations[] = productDetails.map((pd) => ({
      maSanPham: pd.maSanPham ?? '-1', // '-1' là value ảo cho trường hợp null/undefined
    }));
    dispatch(setProductTableSelectedRows(tableItemSelectedList ?? []));
  }, [dispatch, productDetails, page, sizePerPage]);

  // React Function
  const handleOnSelect = useCallback(
    (row: KhotpMasterSanPhamWithRelations, isSelect: boolean) => {
      if (!row || !row.maSanPham || !data) {
        return;
      }

      // Sync selected rows with data table
      const newSelectedRows = getSyncSelectedRows(data, selectedRows);

      if (isSelect) {
        dispatch(setProductTableSelectedRows([...newSelectedRows, row]));
      } else {
        dispatch(setProductTableSelectedRows(newSelectedRows.filter((item) => item.maSanPham !== row.maSanPham)));
      }
    },
    [data, dispatch, selectedRows],
  );

  const handleOnSelectAll = useCallback(
    (isSelect: boolean, rows: KhotpMasterSanPhamWithRelations[]) => {
      if (isSelect) {
        dispatch(setProductTableSelectedRows(rows));
      } else {
        dispatch(setProductTableSelectedRows([]));
      }
    },
    [dispatch],
  );

  const handleTableChange = useCallback(
    (_: TableChangeType, newState: TableChangeState<KhotpMasterSanPhamWithRelations>) => {
      const { page, sizePerPage, sortField, sortOrder } = newState;

      dispatch(
        updateProductTable({
          page,
          sizePerPage,
          sortField,
          sortOrder,
          selectedRows: getSyncSelectedRows(data, selectedRows),
        }),
      );
    },
    [data, dispatch, selectedRows],
  );

  return (
    <ToolkitProvider data={data || []} keyField={FieldName.MA_SAN_PHAM} columns={tableConfig.columns}>
      {(props: any) => (
        <>
          <div className="table-responsive">
            <BootstrapTable
              {...props.baseProps}
              remote
              bootstrap4
              bordered={false}
              pagination={paginationFactory({
                ...tableConfig.pagination,
                page: page,
                sizePerPage: sizePerPage,
                totalSize: dataCount,
              })}
              sort={
                sortField
                  ? {
                      dataField: sortField,
                      order: sortOrder,
                    }
                  : {}
              }
              selectRow={{
                ...tableConfig.selectRow,
                selected: selectedRowIds,
                onSelect: handleOnSelect,
                onSelectAll: handleOnSelectAll,
              }}
              onTableChange={handleTableChange}
              noDataIndication={() => (
                <div className="text-center">
                  {dataIsLoading || dataCountIsLoading ? <BasicSpinner /> : 'Không có kết quả nào được tìm thấy'}
                </div>
              )}
            />
          </div>
        </>
      )}
    </ToolkitProvider>
  );
}

const getSyncSelectedRows = (
  data?: KhotpMasterSanPhamWithRelations[],
  selectedRows?: KhotpMasterSanPhamWithRelations[],
) => {
  if (!selectedRows || !data) {
    return [];
  }
  const newSelectedRows = [...selectedRows];
  for (const selectedRow of selectedRows) {
    const rowDataIndex = data.findIndex((item) => item.maSanPham === selectedRow.maSanPham);
    if (rowDataIndex === -1) {
      const removedIndex = newSelectedRows.findIndex((item) => selectedRow.maSanPham === item.maSanPham);
      newSelectedRows.splice(removedIndex, 1);
    }
  }
  return newSelectedRows;
};

export default AddProductModalTable;
