import { FormikProps } from 'formik';
import { MuiDataGrid } from 'main/components/atoms/mui-data-grid';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  initialData,
  insertNewOrders,
  selectOrdersDetails,
  updateOrdersDetails,
} from 'main/redux/modules/order-management/order-management-add-slice';
import { generateUUID } from 'main/utils/generateUUID';
import { calculateTonKhoDuKien } from 'main/utils/order-management-utils';
import { useCallback } from 'react';
import { Button } from 'reactstrap';
import useTableConfig from './useTableConfig';
import { OrderManagementInfo } from '../order-management-add-info';

type Props = {
  formikProps: FormikProps<OrderManagementInfo>;
};

function OrderManagementAddTable(formikProps: Props) {
  const dispatch = useAppDispatch();
  const suppliesDetails = useAppSelector(selectOrdersDetails);

  const { IsLoadingProductData, columns } = useTableConfig(formikProps);

  const handleRowEditStop = useCallback(
    (params: any, event: any) => {
      const { rowId, newValue } = params;

      // Dispatch an action to update the row data in Redux
      dispatch(updateOrdersDetails({ id: rowId, changes: newValue }));

      // Add new row logic (if last row is edited)
      if (params.reason === 'enterKeyDown' && params.row.id === suppliesDetails.length - 1) {
        const newRows = Array.from({ length: 10 }, () => ({
          ...initialData,
          id: generateUUID(),
        }));
        dispatch(insertNewOrders(newRows));
      }
    },
    [dispatch, suppliesDetails.length],
  );

  const handleAddRow = useCallback(() => {
    const newRows = Array.from({ length: 10 }, () => ({
      ...initialData,
      id: generateUUID(),
    }));

    dispatch(insertNewOrders(newRows));
  }, [dispatch]);

  const handleRowEdit = useCallback(
    (newRow: any) => {
      const { tonKho, soLuong } = newRow;
      const updatedRow = {
        ...newRow,
        soLuong: (newRow.soLuong && Number(newRow.soLuong)) || 0,
        tonKhoDuKien: calculateTonKhoDuKien(soLuong, tonKho),
        // soLuongThucTe: calculateSoLuongThucTe(soLuongThucTe, tonKho),
      };

      dispatch(
        updateOrdersDetails({
          id: newRow.id,
          changes: {
            ...updatedRow,
          },
        }),
      );

      return updatedRow;
    },
    [dispatch],
  );

  return (
    <>
      <div className="d-flex justify-content-end pb-4">
        <Button color="primary" onClick={handleAddRow}>
          Thêm hàng
        </Button>
      </div>
      {/* <div style={{ height: 650, width: '100%' }}> */}
      <MuiDataGrid
        rows={suppliesDetails}
        columns={columns}
        loading={IsLoadingProductData}
        pageSizeOptions={[10, 25, 50, 100]}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={handleRowEdit}
      />
    </>
  );
}

export default OrderManagementAddTable;
