import { DateFormat } from 'main/constants';
import { FieldName } from 'main/constants/enums';
import { cellDateFormatter, generateColumnStyle } from 'main/utils';
import { calculateTonKhoDuKien } from 'main/utils/order-management-utils';

const columns = [
  {
    dataField: FieldName.MA_SAN_PHAM,
    text: 'Mã sản phẩm',
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: FieldName.DVT,
    text: 'Đơn vị tính',
    ...generateColumnStyle(undefined, true),
    formatter: (_: string, row: any) => {
      return `${row?.sanPham?.dvt || ''}`;
    },
  },
  {
    dataField: FieldName.SO_LUONG,
    text: 'Số lượng',
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: 'soLuongGiuHang',
    text: 'Số lượng giữ hàng',
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: FieldName.TON_KHO,
    text: 'Tồn kho hiện tại',
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: FieldName.TON_KHO_DU_KIEN,
    text: 'Tồn kho dự kiến',
    formatter: (_: string, row: any) => {
      return `${calculateTonKhoDuKien(row.soLuong, row.soLuongGiuHang, row.tonKho)}`;
    },
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: FieldName.NGAY_GIAO_DU_KIEN,
    text: 'Ngày giao hàng dự kiến',
    formatter: cellDateFormatter(DateFormat.DATE_ONLY_VN),
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: FieldName.NGAY_GIAO_THUC_TE,
    text: 'Ngày giao hàng thực tế',
    formatter: cellDateFormatter(DateFormat.DATE_ONLY_VN),
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: FieldName.YEU_CAU_DONG_GOI,
    text: 'Yêu cầu đóng gói',
    ...generateColumnStyle(undefined, true),
  },
  {
    dataField: FieldName.GHI_CHU,
    text: 'Ghi chú',
    ...generateColumnStyle(undefined, true),
  },
];

const tableConfig = { columns };

export default tableConfig;
