enum FieldName {
  ACTION = 'action',
  BATCH_CODE = 'batchCode',
  CONTROLLER_ID = 'controllerId',
  CONTROLLER_NAME = 'controllerName',
  CREATE_TIME = 'createTime',
  DETAIL = 'detail',
  DETAILS = 'details',
  DINH_MUC = 'dinhMuc',
  DINH_MUC_ITEMS = 'dinhMucItems',
  DINH_MUC_VALUE = 'dinhMucValue',
  DINH_MUC_VALUE1 = 'dinhMucValue1',
  DINH_MUC_VALUE2 = 'dinhMucValue2',
  DOCUMENT_CODE = 'documentCode',
  DOCUMENT_NAME = 'documentName',
  DON_VI_NHAP = 'donViNhap',
  DVT = 'dvt',
  END_DATE = 'endDate',
  EXPORT_BILL_NO = 'exportBillNo',
  EXPORT_STATUS = 'exportStatus',
  EXPORT_STORE = 'exportStore',
  GHI_CHU = 'ghiChu',
  HAN_SU_DUNG = 'hanSuDung',
  ID = 'id',
  ID_NHAP = 'idNhap',
  IMPORT_STATUS = 'importStatus',
  IMPORT_STORE = 'importStore',
  IMPORT_TYPE = 'importType',
  KHO_NHAP = 'khoNhap',
  KHO_XUAT = 'khoXuat',
  KHO_XUAT_2 = 'KhoXuat',
  LAN_BAN_HANH = 'lanBanHanh',
  LEFT_ID = 'leftId',
  LOAI_BO_DINH_MUC = 'loaiBoDinhMuc',
  LOAI_LENH_NHAP = 'loaiLenhNhap',
  LOAI_PHIEU = 'loaiPhieu',
  LOAI_XE = 'loaiXe',
  LY_DO_TRA_HANG = 'lyDoTraHang',
  LY_DO_XUAT = 'lyDoXuat',
  MA_BO_DINH_MUC = 'maBoDinhMuc',
  MA_ITEM = 'maItem',
  MA_KHO = 'maKho',
  MA_KHO_NHAP = 'maKhoNhap',
  MA_KHO_NHAP_DETAIL = 'maKhoNhapDetail',
  MA_LO = 'maLo',
  MA_LOAI = 'maLoai',
  MA_NHOM = 'maNhom',
  MA_PHIEU = 'maPhieu',
  MA_SAN_PHAM = 'maSanPham',
  MA_TAI_LIEU = 'maTaiLieu',
  MA_THANH_PHAM = 'maThanhPham',
  MA_VT = 'maVt',
  MONTH = 'month',
  NGAY_BAN_HANH = 'ngayBanHanh',
  NGAY_HET_HAN = 'ngayHetHan',
  NGAY_HET_HAN_LK = 'ngayHetHanLK',
  NGAY_HOA_DON = 'ngayHoaDon',
  NGAY_NHAP = 'ngayNhap',
  NGAY_SAN_XUAT = 'ngaySanXuat',
  NGAY_SX = 'ngaySx',
  NGAY_THI_NGHIEM = 'ngayThiNghiem',
  NGAY_TRA_HANG = 'ngayTraHang',
  NGAY_XUAT = 'ngayXuat',
  NGAYSX = 'ngaySX',
  NGUOI_BAN_HANH = 'nguoiBanHanh',
  NGUOI_NHAN_HANG = 'nguoiNhanHang',
  NGUOI_NHAP = 'nguoiNhap',
  NGUOI_TAO_PHIEU = 'nguoiTaoPhieu',
  NGUOI_THUC_HIEN = 'nguoiThucHien',
  NGUON_GOC = 'nguonGoc',
  NHOM_VT = 'nhomVt',
  NHOMSP_TEN_NHOM = 'nhomsp.tenNhom',
  NHOMVT_TEN_NHOM = 'nhomvt.tenNhom',
  ORIGIN = 'origin',
  PAGE = 'page',
  PHIEU_NHAP_ID = 'phieuNhapId',
  PRODUCT_CODE = 'productCode',
  PRODUCT_EXCEL_TEMPLATE_TYPE = 'productExcelTemplateType',
  PRODUCT_GROUP = 'productGroup',
  PRODUCT_NAME = 'productName',
  PRODUCT_STORE = 'productStore',
  PRODUCT_STORE_EXCEL_CODE = 'productStoreExcelCode',
  PRODUCT_STORE_EXCEL_FILE = 'productStoreExcelFile',
  SUPPLIES_EXCEL_FILE = 'suppliesExcelFile',
  CUSTOMER_EXCEL_FILE = 'customerExcelFile',
  PRODUCT_INVENTORY_EXCEL_FILE = 'productInventoryExcelFile',
  PRODUCT_INVENTORY_QUANTITY_COLUMN = 'productInventoryQuantityColumn',
  FILE_TAI_LIEU = 'fileTaiLieu',
  PRODUCT_SUGGESTIVE_NAME = 'productSuggestiveName',
  PRODUCT_TYPE = 'productType',
  REQUIREMENTS_STANDARD_SET_CODE = 'requirementsStandardSetCode',
  REQUIREMENTS_STANDARD_SET_FILE = 'requirementsStandardSetFile',
  REQUIREMENTS_STANDARD_SET_TYPE = 'requirementsStandardSetType',
  RIGHT_ID = 'rightId',
  ROLE = 'role',
  SEARCH_FIELD = 'searchField',
  SEARCH_KEY = 'searchKey',
  SIZE_PER_PAGE = 'sizePerPage',
  SO_HOA_DON = 'soHoaDon',
  SO_LO = 'soLo',
  SO_LUONG = 'soLuong',
  SO_LUONG_NHAP = 'soLuongNhap',
  SO_LUONG_NHAP_MONTH = 'soLuongNhapMonth',
  SO_LUONG_NHAP_PREVIOUS_MONTH = 'soLuongNhapPreviousMonth',
  SO_LUONG_NHAP_FROM_TO = 'soLuongNhapFromTo',
  TON_KHO_DAU_KY = 'tonKhoDauKy',
  TON_KHO_CUOI_KY = 'tonKhoCuoiKy',
  SO_LUONG_THUC_TE = 'soLuongThucTe',
  SO_LUONG_TRA_HANG = 'soLuongTraHang',
  SO_LUONG_XUAT = 'soLuongXuat',
  SO_LUONG_XUAT_MONTH = 'soLuongXuatMonth',
  SO_LUONG_XUAT_PREVIOUS_MONTH = 'soLuongXuatPreviousMonth',
  SO_LUONG_XUAT_FROM_TO = 'soLuongXuatFromTo',
  SO_LUONG_YEU_CAU = 'soLuongYeuCau',
  SO_PHIEU = 'soPhieu',
  SO_PHIEU_NHAP = 'soPhieuNhap',
  SO_XE = 'soXe',
  SORT_BY = 'sortBy',
  SORT_TYPE = 'sortType',
  START_DATE = 'startDate',
  STATUS = 'status',
  STT = 'stt',
  SUPPLIES_CODE = 'suppliesCode',
  SUPPLIES_GROUP = 'suppliesGroup',
  SUPPLIES_NAME = 'suppliesName',
  SUPPLIES_STORE = 'suppliesStore',
  SUPPLIES_SUGGESTIVE_NAME = 'suppliesSuggestiveName',
  TEN_DON_VI = 'tenDonVi',
  TEN_GOI_NHO = 'tenGoiNho',
  TEN_KHO = 'tenKho',
  TEN_NHA_SX = 'tenNhaSX',
  TEN_NHOM = 'tenNhom',
  TEN_SAN_PHAM = 'tenSanPham',
  TEN_TAI_LIEU = 'tenTaiLieu',
  TEN_VT = 'tenVt',
  THOI_HAN_LUU_KHO = 'thoiHanLuuKho',
  THOI_HAN_LUU_KHO_2 = 'ThoiHanLuuKho',
  THONG_TIN_CANH_BAO = 'thongTinCanhBao',
  TON_KHO = 'tonKho',
  TON_KHO_DU_KIEN = 'tonKhoDuKien',
  TYPE_NHAP = 'typeNhap',
  UNIQUE_KEY = 'uniqueKey',
  UPDATE_END_DATE = 'updateEndDate',
  UPDATE_START_DATE = 'updateStartDate',
  URL_TAI_LIEU = 'urlTaiLieu',
  VI_TRI = 'viTri',
  VI_TRI_LUU_KHO = 'viTriLuuKho',
  XE_VAN_CHUYEN = 'xeVanChuyen',
  KHO_NHAP_XUAT = 'khoNhapXuat',

  // product
  DIA_CHI = 'diaChi',
  DIA_CHI_KH = 'diaChiKh',
  DT_KH = 'dtKh',
  FAX_KH = 'faxKh',
  HAN_LUU_KHO = 'hanSD',
  HINH_THUC_THANH_TOAN = 'hinhThucThanhToan',
  ID_PHIEU_XUAT = 'idPhieuXuat',
  KHACH_HANG = 'khachHang',
  LH_KH = 'lienHeKh',
  MA_KH = 'maKh',
  MA_KHO_XUAT = 'maKhoXuat',
  MA_SO_THUE = 'maSoThue',
  MA_SO_THUE_KH = 'maSoThueKh',
  MST_KH = 'mstKh',
  NGAY_CHUNG_TU = 'ngayChungTu',
  NGAY_XUAT_KHO = 'ngayXuatKho',
  NGUOI_VAN_CHUYEN = 'nguoiVanChuyen',
  NGUOI_XUAT_HANG = 'nguoiXuatHang',
  PRODUCT_COMMAND_EXPORT_FILE = 'productCommandExportFile',
  SDT = 'sdt',
  SO_CHUNG_TU = 'soChungTu',
  SO_TAI_KHOAN = 'soTaiKhoan',
  TEN_KH = 'tenKh',
  THANG_NHAP_KHO = 'thangNhapKho',
  THUC_XUAT = 'thucXuat',
  YEU_CAU_XUAT = 'yeuCauXuat',

  // STORAGE KEY
  AUTH_TOKEN_KEY = 'authToken',
  USER_ID = 'userId',
  USERNAME_KEY = 'username',
  DS_KHO_VT_PERMISSION = 'dsKhoVtPermission',
  DS_KHO_TP_PERMISSION = 'dsKhoTpPermission',

  // Quan Ly Don Hang
  CREATE_USER = 'createUser',
  DON_HANG_ID = 'donHangId',
  IS_BTP = 'isBTP',
  KHU_VUC_KH = 'khuVucKH',
  KHU_VUC_KH_CREATE = 'khuVucKh',
  LIEN_HE_KH = 'lienHeKH',
  LUUKHO = 'luuKho',
  MA_DON_HANG = 'maDonHang',
  NGAY_DAT_HANG = 'ngayDatHang',
  NGAY_GIAO_DU_KIEN = 'ngayGiaoDuKien',
  NGAY_GIAO_THUC_TE = 'ngayGiaoThucTe',
  NGAY_YEU_CAU = 'ngayYeuCau',
  NGUOI_NHAN_YEU_CAU = 'nguoiNhanYeuCau',
  NGUOI_TAO = 'nguoiTao',
  NGUOI_YEU_CAU = 'nguoiYeuCau',
  TEN_KH_QL = 'tenKH',
  TRANG_THAI = 'trangThai',
  UPDATE_TIME = 'updateTime',
  UPDATE_USER = 'updateUser',
  YEU_CAU_DONG_GOI = 'yeuCauDongGoi',
  YEU_CAU_GIU_HANG = 'yeuCauGiuHang ',
  SO_LUONG_GIU_HANG = 'soLuongGiuHang',
}

export default FieldName;
