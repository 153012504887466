/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhotpMasterSanPham,
  KhotpMasterSanPhamControllerCountParams,
  KhotpMasterSanPhamControllerFindByIdParams,
  KhotpMasterSanPhamControllerFindParams,
  KhotpMasterSanPhamPartial,
  KhotpMasterSanPhamWithRelations,
  LoopbackCount,
  NewKhotpMasterSanPham
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khotpMasterSanPhamControllerCount = (
    params?: KhotpMasterSanPhamControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-master-san-phams/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterSanPhamControllerCountQueryKey = (params?: KhotpMasterSanPhamControllerCountParams,) => {
    return [`/khotp-master-san-phams/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterSanPhamControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterSanPhamControllerCount>>, TError = ErrorType<unknown>>(params?: KhotpMasterSanPhamControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterSanPhamControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterSanPhamControllerCount>>> = ({ signal }) => khotpMasterSanPhamControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterSanPhamControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterSanPhamControllerCount>>>
export type KhotpMasterSanPhamControllerCountQueryError = ErrorType<unknown>

export const useKhotpMasterSanPhamControllerCount = <TData = Awaited<ReturnType<typeof khotpMasterSanPhamControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterSanPhamControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterSanPhamControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterSanPhamControllerImport = (
    khotpMasterSanPhamWithRelations: KhotpMasterSanPhamWithRelations[],
 ) => {
      
      
      return drcInstance<KhotpMasterSanPham[]>(
      {url: `/khotp-master-san-phams/import`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterSanPhamWithRelations
    },
      );
    }
  


export const getKhotpMasterSanPhamControllerImportMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerImport>>, TError,{data: KhotpMasterSanPhamWithRelations[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerImport>>, TError,{data: KhotpMasterSanPhamWithRelations[]}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterSanPhamControllerImport>>, {data: KhotpMasterSanPhamWithRelations[]}> = (props) => {
          const {data} = props ?? {};

          return  khotpMasterSanPhamControllerImport(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterSanPhamControllerImportMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterSanPhamControllerImport>>>
    export type KhotpMasterSanPhamControllerImportMutationBody = KhotpMasterSanPhamWithRelations[]
    export type KhotpMasterSanPhamControllerImportMutationError = ErrorType<unknown>

    export const useKhotpMasterSanPhamControllerImport = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerImport>>, TError,{data: KhotpMasterSanPhamWithRelations[]}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterSanPhamControllerImport>>,
        TError,
        {data: KhotpMasterSanPhamWithRelations[]},
        TContext
      > => {

      const mutationOptions = getKhotpMasterSanPhamControllerImportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterSanPhamControllerReplaceById = (
    id: string,
    khotpMasterSanPham: KhotpMasterSanPham,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-san-phams/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterSanPham
    },
      );
    }
  


export const getKhotpMasterSanPhamControllerReplaceByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerReplaceById>>, TError,{id: string;data: KhotpMasterSanPham}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerReplaceById>>, TError,{id: string;data: KhotpMasterSanPham}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterSanPhamControllerReplaceById>>, {id: string;data: KhotpMasterSanPham}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterSanPhamControllerReplaceById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterSanPhamControllerReplaceByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterSanPhamControllerReplaceById>>>
    export type KhotpMasterSanPhamControllerReplaceByIdMutationBody = KhotpMasterSanPham
    export type KhotpMasterSanPhamControllerReplaceByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterSanPhamControllerReplaceById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerReplaceById>>, TError,{id: string;data: KhotpMasterSanPham}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterSanPhamControllerReplaceById>>,
        TError,
        {id: string;data: KhotpMasterSanPham},
        TContext
      > => {

      const mutationOptions = getKhotpMasterSanPhamControllerReplaceByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterSanPhamControllerUpdateById = (
    id: string,
    khotpMasterSanPhamPartial: KhotpMasterSanPhamPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-san-phams/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpMasterSanPhamPartial
    },
      );
    }
  


export const getKhotpMasterSanPhamControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerUpdateById>>, TError,{id: string;data: KhotpMasterSanPhamPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerUpdateById>>, TError,{id: string;data: KhotpMasterSanPhamPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterSanPhamControllerUpdateById>>, {id: string;data: KhotpMasterSanPhamPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpMasterSanPhamControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterSanPhamControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterSanPhamControllerUpdateById>>>
    export type KhotpMasterSanPhamControllerUpdateByIdMutationBody = KhotpMasterSanPhamPartial
    export type KhotpMasterSanPhamControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterSanPhamControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerUpdateById>>, TError,{id: string;data: KhotpMasterSanPhamPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterSanPhamControllerUpdateById>>,
        TError,
        {id: string;data: KhotpMasterSanPhamPartial},
        TContext
      > => {

      const mutationOptions = getKhotpMasterSanPhamControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterSanPhamControllerFindById = (
    id: string,
    params?: KhotpMasterSanPhamControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterSanPhamWithRelations>(
      {url: `/khotp-master-san-phams/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterSanPhamControllerFindByIdQueryKey = (id: string,
    params?: KhotpMasterSanPhamControllerFindByIdParams,) => {
    return [`/khotp-master-san-phams/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterSanPhamControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterSanPhamControllerFindById>>, TError = ErrorType<unknown>>(id: string,
    params?: KhotpMasterSanPhamControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterSanPhamControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterSanPhamControllerFindById>>> = ({ signal }) => khotpMasterSanPhamControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterSanPhamControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterSanPhamControllerFindById>>>
export type KhotpMasterSanPhamControllerFindByIdQueryError = ErrorType<unknown>

export const useKhotpMasterSanPhamControllerFindById = <TData = Awaited<ReturnType<typeof khotpMasterSanPhamControllerFindById>>, TError = ErrorType<unknown>>(
 id: string,
    params?: KhotpMasterSanPhamControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterSanPhamControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpMasterSanPhamControllerDeleteById = (
    id: string,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-master-san-phams/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhotpMasterSanPhamControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerDeleteById>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterSanPhamControllerDeleteById>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  khotpMasterSanPhamControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterSanPhamControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterSanPhamControllerDeleteById>>>
    
    export type KhotpMasterSanPhamControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhotpMasterSanPhamControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerDeleteById>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterSanPhamControllerDeleteById>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getKhotpMasterSanPhamControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterSanPhamControllerCreate = (
    newKhotpMasterSanPham: NewKhotpMasterSanPham,
 ) => {
      
      
      return drcInstance<KhotpMasterSanPham>(
      {url: `/khotp-master-san-phams`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhotpMasterSanPham
    },
      );
    }
  


export const getKhotpMasterSanPhamControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerCreate>>, TError,{data: NewKhotpMasterSanPham}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerCreate>>, TError,{data: NewKhotpMasterSanPham}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpMasterSanPhamControllerCreate>>, {data: NewKhotpMasterSanPham}> = (props) => {
          const {data} = props ?? {};

          return  khotpMasterSanPhamControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpMasterSanPhamControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpMasterSanPhamControllerCreate>>>
    export type KhotpMasterSanPhamControllerCreateMutationBody = NewKhotpMasterSanPham
    export type KhotpMasterSanPhamControllerCreateMutationError = ErrorType<unknown>

    export const useKhotpMasterSanPhamControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerCreate>>, TError,{data: NewKhotpMasterSanPham}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpMasterSanPhamControllerCreate>>,
        TError,
        {data: NewKhotpMasterSanPham},
        TContext
      > => {

      const mutationOptions = getKhotpMasterSanPhamControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpMasterSanPhamControllerFind = (
    params?: KhotpMasterSanPhamControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpMasterSanPhamWithRelations[]>(
      {url: `/khotp-master-san-phams`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpMasterSanPhamControllerFindQueryKey = (params?: KhotpMasterSanPhamControllerFindParams,) => {
    return [`/khotp-master-san-phams`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpMasterSanPhamControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khotpMasterSanPhamControllerFind>>, TError = ErrorType<unknown>>(params?: KhotpMasterSanPhamControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpMasterSanPhamControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpMasterSanPhamControllerFind>>> = ({ signal }) => khotpMasterSanPhamControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpMasterSanPhamControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khotpMasterSanPhamControllerFind>>>
export type KhotpMasterSanPhamControllerFindQueryError = ErrorType<unknown>

export const useKhotpMasterSanPhamControllerFind = <TData = Awaited<ReturnType<typeof khotpMasterSanPhamControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhotpMasterSanPhamControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpMasterSanPhamControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpMasterSanPhamControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



