import ExcelJS from 'exceljs';

abstract class AbstractExcelWriter<T> {
  protected data: T | undefined;

  async writeData(data?: T) {
    if (!data) {
      return null;
    }
    this.data = data;
    return this.writeDataImpl();
  }

  protected abstract writeDataImpl(): Promise<ExcelJS.Buffer>;
}

export default AbstractExcelWriter;
