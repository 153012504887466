/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CreatePhieuXuatTpRequestPartial,
  KhotpTransPhieuXuat,
  KhotpTransPhieuXuatControllerCountParams,
  KhotpTransPhieuXuatControllerFindById200,
  KhotpTransPhieuXuatControllerFindDetail200,
  KhotpTransPhieuXuatControllerFindDetailParams,
  KhotpTransPhieuXuatControllerFindParams,
  KhotpTransPhieuXuatControllerUploadPdfAndCreate200,
  KhotpTransPhieuXuatControllerUploadPdfAndCreateBody,
  KhotpTransPhieuXuatWithRelations,
  LoopbackCount,
  NewKhotpTransPhieuXuat
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khotpTransPhieuXuatControllerCount = (
    params?: KhotpTransPhieuXuatControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-trans-phieu-xuats/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpTransPhieuXuatControllerCountQueryKey = (params?: KhotpTransPhieuXuatControllerCountParams,) => {
    return [`/khotp-trans-phieu-xuats/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpTransPhieuXuatControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransPhieuXuatControllerCount>>, TError = ErrorType<unknown>>(params?: KhotpTransPhieuXuatControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransPhieuXuatControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerCount>>> = ({ signal }) => khotpTransPhieuXuatControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransPhieuXuatControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerCount>>>
export type KhotpTransPhieuXuatControllerCountQueryError = ErrorType<unknown>

export const useKhotpTransPhieuXuatControllerCount = <TData = Awaited<ReturnType<typeof khotpTransPhieuXuatControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhotpTransPhieuXuatControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransPhieuXuatControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpTransPhieuXuatControllerFindDetail = (
    params?: KhotpTransPhieuXuatControllerFindDetailParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpTransPhieuXuatControllerFindDetail200>(
      {url: `/khotp-trans-phieu-xuats/search-details`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpTransPhieuXuatControllerFindDetailQueryKey = (params?: KhotpTransPhieuXuatControllerFindDetailParams,) => {
    return [`/khotp-trans-phieu-xuats/search-details`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpTransPhieuXuatControllerFindDetailQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFindDetail>>, TError = ErrorType<unknown>>(params?: KhotpTransPhieuXuatControllerFindDetailParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFindDetail>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransPhieuXuatControllerFindDetailQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFindDetail>>> = ({ signal }) => khotpTransPhieuXuatControllerFindDetail(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFindDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransPhieuXuatControllerFindDetailQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFindDetail>>>
export type KhotpTransPhieuXuatControllerFindDetailQueryError = ErrorType<unknown>

export const useKhotpTransPhieuXuatControllerFindDetail = <TData = Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFindDetail>>, TError = ErrorType<unknown>>(
 params?: KhotpTransPhieuXuatControllerFindDetailParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFindDetail>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransPhieuXuatControllerFindDetailQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpTransPhieuXuatControllerUploadPdfAndCreate = (
    khotpTransPhieuXuatControllerUploadPdfAndCreateBody: KhotpTransPhieuXuatControllerUploadPdfAndCreateBody,
 ) => {
      
      const formData = new FormData();
if(khotpTransPhieuXuatControllerUploadPdfAndCreateBody.file !== undefined) {
 formData.append('file', khotpTransPhieuXuatControllerUploadPdfAndCreateBody.file)
 }

      return drcInstance<KhotpTransPhieuXuatControllerUploadPdfAndCreate200>(
      {url: `/khotp-trans-phieu-xuats/upload-pdf`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


export const getKhotpTransPhieuXuatControllerUploadPdfAndCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerUploadPdfAndCreate>>, TError,{data: KhotpTransPhieuXuatControllerUploadPdfAndCreateBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerUploadPdfAndCreate>>, TError,{data: KhotpTransPhieuXuatControllerUploadPdfAndCreateBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerUploadPdfAndCreate>>, {data: KhotpTransPhieuXuatControllerUploadPdfAndCreateBody}> = (props) => {
          const {data} = props ?? {};

          return  khotpTransPhieuXuatControllerUploadPdfAndCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransPhieuXuatControllerUploadPdfAndCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerUploadPdfAndCreate>>>
    export type KhotpTransPhieuXuatControllerUploadPdfAndCreateMutationBody = KhotpTransPhieuXuatControllerUploadPdfAndCreateBody
    export type KhotpTransPhieuXuatControllerUploadPdfAndCreateMutationError = ErrorType<unknown>

    export const useKhotpTransPhieuXuatControllerUploadPdfAndCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerUploadPdfAndCreate>>, TError,{data: KhotpTransPhieuXuatControllerUploadPdfAndCreateBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransPhieuXuatControllerUploadPdfAndCreate>>,
        TError,
        {data: KhotpTransPhieuXuatControllerUploadPdfAndCreateBody},
        TContext
      > => {

      const mutationOptions = getKhotpTransPhieuXuatControllerUploadPdfAndCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransPhieuXuatControllerUpdateById = (
    id: number,
    createPhieuXuatTpRequestPartial: CreatePhieuXuatTpRequestPartial,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-trans-phieu-xuats/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: createPhieuXuatTpRequestPartial
    },
      );
    }
  


export const getKhotpTransPhieuXuatControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerUpdateById>>, TError,{id: number;data: CreatePhieuXuatTpRequestPartial}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerUpdateById>>, TError,{id: number;data: CreatePhieuXuatTpRequestPartial}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerUpdateById>>, {id: number;data: CreatePhieuXuatTpRequestPartial}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpTransPhieuXuatControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransPhieuXuatControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerUpdateById>>>
    export type KhotpTransPhieuXuatControllerUpdateByIdMutationBody = CreatePhieuXuatTpRequestPartial
    export type KhotpTransPhieuXuatControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhotpTransPhieuXuatControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerUpdateById>>, TError,{id: number;data: CreatePhieuXuatTpRequestPartial}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransPhieuXuatControllerUpdateById>>,
        TError,
        {id: number;data: CreatePhieuXuatTpRequestPartial},
        TContext
      > => {

      const mutationOptions = getKhotpTransPhieuXuatControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransPhieuXuatControllerFindById = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpTransPhieuXuatControllerFindById200>(
      {url: `/khotp-trans-phieu-xuats/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getKhotpTransPhieuXuatControllerFindByIdQueryKey = (id: number,) => {
    return [`/khotp-trans-phieu-xuats/${id}`] as const;
    }

    
export const getKhotpTransPhieuXuatControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFindById>>, TError = ErrorType<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransPhieuXuatControllerFindByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFindById>>> = ({ signal }) => khotpTransPhieuXuatControllerFindById(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransPhieuXuatControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFindById>>>
export type KhotpTransPhieuXuatControllerFindByIdQueryError = ErrorType<unknown>

export const useKhotpTransPhieuXuatControllerFindById = <TData = Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFindById>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransPhieuXuatControllerFindByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpTransPhieuXuatControllerDeleteById = (
    id: number,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-trans-phieu-xuats/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhotpTransPhieuXuatControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerDeleteById>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerDeleteById>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  khotpTransPhieuXuatControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransPhieuXuatControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerDeleteById>>>
    
    export type KhotpTransPhieuXuatControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhotpTransPhieuXuatControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransPhieuXuatControllerDeleteById>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getKhotpTransPhieuXuatControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransPhieuXuatControllerCreate = (
    newKhotpTransPhieuXuat: NewKhotpTransPhieuXuat,
 ) => {
      
      
      return drcInstance<KhotpTransPhieuXuat>(
      {url: `/khotp-trans-phieu-xuats`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhotpTransPhieuXuat
    },
      );
    }
  


export const getKhotpTransPhieuXuatControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerCreate>>, TError,{data: NewKhotpTransPhieuXuat}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerCreate>>, TError,{data: NewKhotpTransPhieuXuat}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerCreate>>, {data: NewKhotpTransPhieuXuat}> = (props) => {
          const {data} = props ?? {};

          return  khotpTransPhieuXuatControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransPhieuXuatControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerCreate>>>
    export type KhotpTransPhieuXuatControllerCreateMutationBody = NewKhotpTransPhieuXuat
    export type KhotpTransPhieuXuatControllerCreateMutationError = ErrorType<unknown>

    export const useKhotpTransPhieuXuatControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerCreate>>, TError,{data: NewKhotpTransPhieuXuat}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransPhieuXuatControllerCreate>>,
        TError,
        {data: NewKhotpTransPhieuXuat},
        TContext
      > => {

      const mutationOptions = getKhotpTransPhieuXuatControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransPhieuXuatControllerFind = (
    params?: KhotpTransPhieuXuatControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpTransPhieuXuatWithRelations[]>(
      {url: `/khotp-trans-phieu-xuats`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpTransPhieuXuatControllerFindQueryKey = (params?: KhotpTransPhieuXuatControllerFindParams,) => {
    return [`/khotp-trans-phieu-xuats`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpTransPhieuXuatControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFind>>, TError = ErrorType<unknown>>(params?: KhotpTransPhieuXuatControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransPhieuXuatControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFind>>> = ({ signal }) => khotpTransPhieuXuatControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransPhieuXuatControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFind>>>
export type KhotpTransPhieuXuatControllerFindQueryError = ErrorType<unknown>

export const useKhotpTransPhieuXuatControllerFind = <TData = Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhotpTransPhieuXuatControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransPhieuXuatControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransPhieuXuatControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



