import ExcelJS from 'exceljs';
import { KhovtXuatNhapTonControllerFind200DataItem } from 'main/apis/drc/models';
import { DateFormat } from 'main/constants';
import { InventoryFilter } from 'main/redux/modules/supplies-store-inventory/supplies-store-inventory-list-slice';
import moment, { Moment } from 'moment';

const SHEET_RANGE_CONSTANTS = {
  originCell: [8, 1],
  columns: {
    STT: { width: 8, alignment: { horizontal: 'center', vertical: 'middle' } },
    'Mã vật tư': { width: 16 },
    'Tên vật tư': { width: 32 },
    ĐVT: { width: 8 },
    'Tồn đầu kỳ': { width: 24, alignment: { horizontal: 'right', vertical: 'middle' } },
    'Tổng thực nhập': { width: 24, alignment: { horizontal: 'right', vertical: 'middle' } },
    'Tổng thực xuất': { width: 24, alignment: { horizontal: 'right', vertical: 'middle' } },
    'Tồn cuối kỳ': { width: 24, alignment: { horizontal: 'right', vertical: 'middle' } },
  },
  rows: {
    header: { height: 32 },
  },
  data: [
    {
      STT: '1',
      'Mã vật tư': 'CD.220',
      'Tên vật tư': 'Than N220',
      ĐVT: 'KG',
      'Tồn đầu kỳ': '118800',
      'Tổng thực nhập': '',
      'Tổng thực xuất': '59400',
      'Tồn cuối kỳ': '59400',
    },
    {
      STT: '2',
      'Mã vật tư': 'CD.234',
      'Tên vật tư': 'Than N234',
      ĐVT: 'KG',
      'Tồn đầu kỳ': '524800',
      'Tổng thực nhập': '130900',
      'Tổng thực xuất': '172800',
      'Tồn cuối kỳ': '482900',
    },
    {
      STT: '3',
      'Mã vật tư': 'CD.330',
      'Tên vật tư': 'Than N330',
      ĐVT: 'KG',
      'Tồn đầu kỳ': '561900',
      'Tổng thực nhập': '41800',
      'Tổng thực xuất': '167450',
      'Tồn cuối kỳ': '436250',
    },
  ],
};

type JsonDataType = {
  [key in keyof typeof SHEET_RANGE_CONSTANTS.columns]: any;
};

export function writeDataSheet1(
  workbook: ExcelJS.Workbook,
  data?: {
    filter: InventoryFilter;
    suppliesInventoryItems: KhovtXuatNhapTonControllerFind200DataItem[];
  },
): void {
  if (!data) {
    return;
  }
  const { filter, suppliesInventoryItems } = data;
  const sub = filter.suppliesStore !== '-1' ? suppliesInventoryItems[0]?.khoNhapXuat || '' : 'All';
  const sheetName = '1';
  const worksheet = workbook.addWorksheet(sheetName);
  const originCell = SHEET_RANGE_CONSTANTS.originCell;
  const columns = SHEET_RANGE_CONSTANTS.columns;
  const rows = SHEET_RANGE_CONSTANTS.rows;
  const jsonData = transformData(suppliesInventoryItems);
  const headers = Object.keys(jsonData[0]);

  // 1. Write title
  writeSectionCompany(worksheet, 'Công ty CP Cao Su Đà Nẵng');
  writeSectionAddress(worksheet, 'Lô G, Tạ Quang Bửu, P. Hòa Hiệp Bắc - Q. Liên chiểu - Đà Nẵng - VN');
  writeSectionTitle(worksheet, 'BÁO CÁO TỔNG HỢP NHẬP XUẤT TỒN SỐ LƯỢNG');
  writeSectionFromToDate(worksheet, filter.startDate, filter.endDate);
  writeSectionStoreType(worksheet, '02 - Kho nguyên vật liệu');
  writeSectionOrg(worksheet, '110 - Tổng kho vật tư công ty');
  writeSectionSub(worksheet, sub);
  writeSectionItem(worksheet, 'All');
  writeSectionGeneric(worksheet, 'All');

  // 2. Write headers
  writeSectionHeaders(worksheet, originCell, headers, rows, columns);

  // 3. Write sum data (header)
  writeSectionSumOrg(worksheet, originCell, headers, '110 - Tổng kho vật tư công ty', jsonData);
  writeSectionSumStoreType(worksheet, originCell, headers, '02 - Kho nguyên vật liệu', jsonData);
  writeSectionSumSub(worksheet, originCell, headers, sub, jsonData);
  writeSectionSumMainSupplies(worksheet, originCell, headers, '152100 - Nguyên vật liệu chính', jsonData);

  // 4. Write data
  writeSectionJsonData(worksheet, originCell, headers, columns, jsonData);

  // 5. Write sum data (footer)
  writeSectionSumAll(worksheet, originCell, headers, jsonData);

  // 6. Write footer
  writeSectionFooterSpace(worksheet, originCell, jsonData);
  writeSectionStorekeeper(worksheet, originCell, jsonData);
  writeSectionAccountant(worksheet, originCell, jsonData);
  writeSectionStorekeeperSign(worksheet, originCell, jsonData);
  writeSectionAccountantSign(worksheet, originCell, jsonData);
  writeSectionPrint(worksheet, originCell, jsonData);
}

function transformData(data?: KhovtXuatNhapTonControllerFind200DataItem[]): JsonDataType[] {
  if (!data || data.length < 1) {
    return [];
  }
  return data.map((item, index) => ({
    STT: index + 1,
    'Mã vật tư': item.maVt,
    'Tên vật tư': item.tenVt,
    ĐVT: item.dvt,
    'Tồn đầu kỳ': item.tonKhoDauKy,
    'Tổng thực nhập': item.soLuongNhapFromTo,
    'Tổng thực xuất': item.soLuongXuatFromTo,
    'Tồn cuối kỳ': item.tonKhoCuoiKy,
  }));
}

function writeSectionCompany(worksheet: ExcelJS.Worksheet, company: string) {
  worksheet.mergeCells('A1:H1');
  const cell = worksheet.getCell('A1');
  cell.value = company;
  cell.style.font = { bold: true };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };
}

function writeSectionAddress(worksheet: ExcelJS.Worksheet, address: string) {
  worksheet.mergeCells('A2:H2');
  const cell = worksheet.getCell('A2');
  cell.value = address;
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };
}

function writeSectionTitle(worksheet: ExcelJS.Worksheet, title: string) {
  worksheet.mergeCells('A3:H3');
  const cell = worksheet.getCell('A3');
  cell.value = title;
  cell.style.font = { bold: true, size: 16 };
  cell.style.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };
}

function writeSectionFromToDate(worksheet: ExcelJS.Worksheet, fromDate?: Moment, toDate?: Moment) {
  worksheet.mergeCells('A4:H4');
  const cell = worksheet.getCell('A4');
  cell.value = `Từ ngày: ${fromDate?.format(DateFormat.DATE_ONLY_VN)} đến ngày: ${toDate?.format(DateFormat.DATE_ONLY_VN)}`;
  cell.style.font = { bold: true };
  cell.style.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };
}

function writeSectionStoreType(worksheet: ExcelJS.Worksheet, storeType: string) {
  worksheet.mergeCells('A5:D5');
  const cell = worksheet.getCell('A5');
  cell.value = {
    richText: [{ text: 'Loại kho: ' }, { font: { bold: true }, text: storeType }],
  };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };

  worksheet.mergeCells('E5:H5');
  const cellEmpty = worksheet.getCell('E5');
  cellEmpty.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };
}

function writeSectionOrg(worksheet: ExcelJS.Worksheet, org: string) {
  worksheet.mergeCells('A6:D6');
  const cell = worksheet.getCell('A6');
  cell.value = {
    richText: [{ text: 'Org: ' }, { font: { bold: true }, text: org }],
  };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };
}

function writeSectionSub(worksheet: ExcelJS.Worksheet, sub: string) {
  worksheet.mergeCells('E6:H6');
  const cell = worksheet.getCell('E6');
  cell.value = {
    richText: [{ text: 'Sub: ' }, { font: { bold: true }, text: sub }],
  };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };
}

function writeSectionItem(worksheet: ExcelJS.Worksheet, item: string) {
  worksheet.mergeCells('A7:D7');
  const cell = worksheet.getCell('A7');
  cell.value = {
    richText: [{ text: 'Item: ' }, { font: { bold: true }, text: item }],
  };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };
}

function writeSectionGeneric(worksheet: ExcelJS.Worksheet, generic: string) {
  worksheet.mergeCells('E7:H7');
  const cellE7 = worksheet.getCell('E7');
  cellE7.value = {
    richText: [{ text: 'Chủng loại: ' }, { font: { bold: true }, text: generic }],
  };
  cellE7.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };
}

function writeSectionHeaders(
  worksheet: ExcelJS.Worksheet,
  originCell: number[],
  headers: any[],
  rows: any,
  columns: any,
) {
  // Set headers data
  worksheet.getRow(originCell[0]).height = rows.header.height;
  headers.forEach((header, index) => {
    worksheet.getColumn(index + 1).width = columns[header as keyof typeof columns].width;

    const cell = worksheet.getCell(originCell[0], originCell[1] + index);
    cell.value = header;
    cell.style.font = { bold: true };
    cell.style.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.style.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    cell.style.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCC99' },
    };
  });
}

function writeSectionSumOrg(
  worksheet: ExcelJS.Worksheet,
  originCell: number[],
  headers: any[],
  org: string,
  jsonData: any[],
) {
  const originSumOrgCell = [originCell[0] + 1, originCell[1]];

  // Set org
  worksheet.mergeCells(`A${originSumOrgCell[0]}:D${originSumOrgCell[0]}`);
  const cell = worksheet.getCell(`A${originSumOrgCell[0]}`);
  cell.value = org;
  cell.style.font = { bold: true };
  cell.style.border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };

  // Set org data
  headers.forEach((key, colIndex) => {
    if (['STT', 'Mã vật tư', 'Tên vật tư', 'ĐVT'].includes(key)) {
      return;
    }
    const cell = worksheet.getCell(originSumOrgCell[0], originSumOrgCell[1] + colIndex);
    cell.value = jsonData.reduce((sum, data) => sum + Number(data[key as keyof typeof data]), 0);
    cell.style.font = { bold: true };
    cell.style.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  });
}

function writeSectionSumStoreType(
  worksheet: ExcelJS.Worksheet,
  originCell: number[],
  headers: any[],
  storeType: string,
  jsonData: any[],
) {
  const originSumStoreTypeCell = [originCell[0] + 2, originCell[1]];

  // Set store type
  worksheet.mergeCells(`A${originSumStoreTypeCell[0]}:D${originSumStoreTypeCell[0]}`);
  const cell = worksheet.getCell(`A${originSumStoreTypeCell[0]}`);
  cell.value = storeType;
  cell.style.font = { bold: true };
  cell.style.border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FDE9D9' },
  };

  // Set store type data
  headers.forEach((key, colIndex) => {
    if (['STT', 'Mã vật tư', 'Tên vật tư', 'ĐVT'].includes(key)) {
      return;
    }
    const cell = worksheet.getCell(originSumStoreTypeCell[0], originSumStoreTypeCell[1] + colIndex);
    cell.value = jsonData.reduce((sum, data) => sum + Number(data[key as keyof typeof data]), 0);
    cell.style.font = { bold: true };
    cell.style.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  });
}

function writeSectionSumSub(
  worksheet: ExcelJS.Worksheet,
  originCell: number[],
  headers: any[],
  sub: string,
  jsonData: any[],
) {
  const originSumSubCell = [originCell[0] + 3, originCell[1]];

  // Set sub
  worksheet.mergeCells(`A${originSumSubCell[0]}:D${originSumSubCell[0]}`);
  const cell = worksheet.getCell(`A${originSumSubCell[0]}`);
  cell.value = sub;
  cell.style.font = { bold: true };
  cell.style.border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'DBE5F1' },
  };

  // Set sub data
  headers.forEach((key, colIndex) => {
    if (['STT', 'Mã vật tư', 'Tên vật tư', 'ĐVT'].includes(key)) {
      return;
    }
    const cell = worksheet.getCell(originSumSubCell[0], originSumSubCell[1] + colIndex);
    cell.value = jsonData.reduce((sum, data) => sum + Number(data[key as keyof typeof data]), 0);
    cell.style.font = { bold: true };
    cell.style.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  });
}

function writeSectionSumMainSupplies(
  worksheet: ExcelJS.Worksheet,
  originCell: number[],
  headers: any[],
  mainSupplies: string,
  jsonData: any[],
) {
  const originSumMainSuppliesCell = [originCell[0] + 4, originCell[1]];

  // Set main supplies
  worksheet.mergeCells(`A${originSumMainSuppliesCell[0]}:D${originSumMainSuppliesCell[0]}`);
  const cell = worksheet.getCell(`A${originSumMainSuppliesCell[0]}`);
  cell.value = mainSupplies;
  cell.style.font = { bold: true };
  cell.style.border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'DDD9C3' },
  };

  // Set main supplies data
  headers.forEach((key, colIndex) => {
    if (['STT', 'Mã vật tư', 'Tên vật tư', 'ĐVT'].includes(key)) {
      return;
    }
    const cell = worksheet.getCell(originSumMainSuppliesCell[0], originSumMainSuppliesCell[1] + colIndex);
    cell.value = jsonData.reduce((sum, data) => sum + Number(data[key as keyof typeof data]), 0);
    cell.style.font = { bold: true };
    cell.style.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  });
}

function writeSectionJsonData(
  worksheet: ExcelJS.Worksheet,
  originCell: number[],
  headers: any[],
  columns: any,
  jsonData: any[],
) {
  const originJsonDataCell = [originCell[0] + 5, originCell[1]];

  // Set json data
  jsonData.forEach((data, rowIndex) => {
    headers.forEach((key, colIndex) => {
      const cell = worksheet.getCell(originJsonDataCell[0] + rowIndex, originJsonDataCell[1] + colIndex);
      cell.value = data[key as keyof typeof data];
      const alignment = columns[key as keyof typeof columns].alignment;
      if (alignment) {
        cell.style.alignment = alignment as Partial<ExcelJS.Alignment>;
      }
      cell.style.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  });
}

function writeSectionSumAll(worksheet: ExcelJS.Worksheet, originCell: number[], headers: any[], jsonData: any[]) {
  const originSumAllCell = [originCell[0] + jsonData.length + 5, originCell[1]];

  // Set sum all
  worksheet.mergeCells(`A${originSumAllCell[0]}:D${originSumAllCell[0]}`);
  const cell = worksheet.getCell(`A${originSumAllCell[0]}`);
  cell.value = 'Tổng cộng:';
  cell.style.font = { bold: true };
  cell.style.border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };

  // Set sum all data
  headers.forEach((key, colIndex) => {
    if (['STT', 'Mã vật tư', 'Tên vật tư', 'ĐVT'].includes(key)) {
      return;
    }
    const cell = worksheet.getCell(originSumAllCell[0], originSumAllCell[1] + colIndex);
    cell.value = jsonData.reduce((sum: number, data: any) => sum + Number(data[key as keyof typeof data]), 0);
    cell.style.font = { bold: true };
    cell.style.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  });
}

function writeSectionFooterSpace(worksheet: ExcelJS.Worksheet, originCell: number[], jsonData: any[]) {
  const originFooterSpaceCell = [originCell[0] + jsonData.length + 6, originCell[1]];

  // Set footer space data
  let i = 0;
  while (i < 4) {
    worksheet.mergeCells(`A${originFooterSpaceCell[0] + i}:H${originFooterSpaceCell[0] + i}`);
    const cell = worksheet.getCell(`A${originFooterSpaceCell[0] + i}`);
    cell.style.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFFF' },
    };
    i++;
  }
}

function writeSectionStorekeeper(worksheet: ExcelJS.Worksheet, originCell: number[], jsonData: any[]) {
  const originStorekeeperCell = [originCell[0] + jsonData.length + 10, originCell[1]];

  // Set storekeeper data
  worksheet.mergeCells(`A${originStorekeeperCell[0]}:C${originStorekeeperCell[0]}`);
  const cell = worksheet.getCell(`A${originStorekeeperCell[0]}`);
  cell.value = 'Thủ kho';
  cell.style.font = { bold: true };
  cell.style.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };
}

function writeSectionAccountant(worksheet: ExcelJS.Worksheet, originCell: number[], jsonData: any[]) {
  const originAccountantCell = [originCell[0] + jsonData.length + 10, originCell[1]];

  // Set accountant data
  worksheet.mergeCells(`D${originAccountantCell[0]}:E${originAccountantCell[0]}`);
  const cell = worksheet.getCell(`D${originAccountantCell[0]}`);
  cell.value = 'Kế toán xuất';
  cell.style.font = { bold: true };
  cell.style.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };

  // Set empty data
  worksheet.mergeCells(`F${originAccountantCell[0]}:H${originAccountantCell[0]}`);
  const cellEmpty = worksheet.getCell(`F${originAccountantCell[0]}`);
  cellEmpty.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };
}

function writeSectionStorekeeperSign(worksheet: ExcelJS.Worksheet, originCell: number[], jsonData: any[]) {
  const originStorekeeperSignCell = [originCell[0] + jsonData.length + 11, originCell[1]];

  // Set storekeeper sign data
  worksheet.mergeCells(`A${originStorekeeperSignCell[0]}:C${originStorekeeperSignCell[0]}`);
  const cell = worksheet.getCell(`A${originStorekeeperSignCell[0]}`);
  cell.value = '(Ký, họ tên)';
  cell.style.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };
}

function writeSectionAccountantSign(worksheet: ExcelJS.Worksheet, originCell: number[], jsonData: any[]) {
  const originAccountantSignCell = [originCell[0] + jsonData.length + 11, originCell[1]];

  // Set accountant data
  worksheet.mergeCells(`D${originAccountantSignCell[0]}:E${originAccountantSignCell[0]}`);
  const cell = worksheet.getCell(`D${originAccountantSignCell[0]}`);
  cell.value = '(Ký, họ tên)';
  cell.style.alignment = { horizontal: 'center', vertical: 'middle' };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };

  // Set empty data
  worksheet.mergeCells(`F${originAccountantSignCell[0]}:H${originAccountantSignCell[0]}`);
  const cellEmpty = worksheet.getCell(`F${originAccountantSignCell[0]}`);
  cellEmpty.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };
}

function writeSectionPrint(worksheet: ExcelJS.Worksheet, originCell: number[], jsonData: any[]) {
  const originPrintCell = [originCell[0] + jsonData.length + 12, originCell[1]];

  // Set print data
  worksheet.mergeCells(`A${originPrintCell[0]}:H${originPrintCell[0]}`);
  const cell = worksheet.getCell(`A${originPrintCell[0]}`);
  cell.value = `IN0201 In ngày ${moment().format(DateFormat.DATE_TIME_VN)}`;
  cell.style.font = { size: 8 };
  cell.style.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF' },
  };
}
