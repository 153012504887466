import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { generateUUID } from 'main/utils/generateUUID';
import React from 'react';
import './index.css';

interface DataGridProps {
  columns: GridColDef[];
  rows: GridRowsProp;
  loading: boolean;
  onRowEditStop?: (params: any, event: any) => void;
  processRowUpdate?: (newRow: any) => any;
  onAddRow?: () => void;
  pageSizeOptions?: number[];
  defaultPageSize?: number;
}

const MuiDataGrid: React.FC<DataGridProps> = ({
  columns,
  rows,
  loading,
  onRowEditStop,
  processRowUpdate,
  onAddRow,
  pageSizeOptions = [10, 25, 50, 100],
  defaultPageSize = 10,
  ...rest
}) => {
  const { data } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 500,
    maxColumns: 6,
  });

  return (
    <div className="custom-cell-editable-false custom-pagination" style={{ minHeight: 713, width: '100%' }}>
      <DataGrid
        {...data}
        {...rest}
        rows={rows}
        columns={columns}
        loading={loading}
        pageSizeOptions={pageSizeOptions}
        onRowEditStop={onRowEditStop}
        processRowUpdate={processRowUpdate}
        disableRowSelectionOnClick
        getRowId={(row) => row.id || generateUUID()}
        rowHeight={60}
        disableColumnMenu={true}
        editMode="cell"
        initialState={{
          ...data.initialState,
          pagination: {
            ...data.initialState?.pagination,
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600',
            color: '#525f7f',
          },
          '& .MuiDataGrid-cell': {
            fontWeight: '400',
          },
        }}
      />
    </div>
  );
};

export default MuiDataGrid;
