/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ChangePasswordModel,
  FUserControllerCountParams,
  FUserControllerFindByIdParams,
  FUserControllerFindParams,
  LoopbackCount,
  UserExcludingId,
  UserExcludingPassword,
  UserPartialExcludingIdUsername
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const fUserControllerCount = (
    params?: FUserControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/users/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getFUserControllerCountQueryKey = (params?: FUserControllerCountParams,) => {
    return [`/users/count`, ...(params ? [params]: [])] as const;
    }

    
export const getFUserControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof fUserControllerCount>>, TError = ErrorType<unknown>>(params?: FUserControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fUserControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFUserControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fUserControllerCount>>> = ({ signal }) => fUserControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fUserControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type FUserControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof fUserControllerCount>>>
export type FUserControllerCountQueryError = ErrorType<unknown>

export const useFUserControllerCount = <TData = Awaited<ReturnType<typeof fUserControllerCount>>, TError = ErrorType<unknown>>(
 params?: FUserControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fUserControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFUserControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const fUserControllerChangePasswordById = (
    id: number,
    changePasswordModel: ChangePasswordModel,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/users/${id}/pasword`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: changePasswordModel
    },
      );
    }
  


export const getFUserControllerChangePasswordByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fUserControllerChangePasswordById>>, TError,{id: number;data: ChangePasswordModel}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof fUserControllerChangePasswordById>>, TError,{id: number;data: ChangePasswordModel}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fUserControllerChangePasswordById>>, {id: number;data: ChangePasswordModel}> = (props) => {
          const {id,data} = props ?? {};

          return  fUserControllerChangePasswordById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FUserControllerChangePasswordByIdMutationResult = NonNullable<Awaited<ReturnType<typeof fUserControllerChangePasswordById>>>
    export type FUserControllerChangePasswordByIdMutationBody = ChangePasswordModel
    export type FUserControllerChangePasswordByIdMutationError = ErrorType<unknown>

    export const useFUserControllerChangePasswordById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fUserControllerChangePasswordById>>, TError,{id: number;data: ChangePasswordModel}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof fUserControllerChangePasswordById>>,
        TError,
        {id: number;data: ChangePasswordModel},
        TContext
      > => {

      const mutationOptions = getFUserControllerChangePasswordByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const fUserControllerUpdateById = (
    id: number,
    userPartialExcludingIdUsername: UserPartialExcludingIdUsername,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/users/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: userPartialExcludingIdUsername
    },
      );
    }
  


export const getFUserControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fUserControllerUpdateById>>, TError,{id: number;data: UserPartialExcludingIdUsername}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof fUserControllerUpdateById>>, TError,{id: number;data: UserPartialExcludingIdUsername}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fUserControllerUpdateById>>, {id: number;data: UserPartialExcludingIdUsername}> = (props) => {
          const {id,data} = props ?? {};

          return  fUserControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FUserControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof fUserControllerUpdateById>>>
    export type FUserControllerUpdateByIdMutationBody = UserPartialExcludingIdUsername
    export type FUserControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useFUserControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fUserControllerUpdateById>>, TError,{id: number;data: UserPartialExcludingIdUsername}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof fUserControllerUpdateById>>,
        TError,
        {id: number;data: UserPartialExcludingIdUsername},
        TContext
      > => {

      const mutationOptions = getFUserControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const fUserControllerFindById = (
    id: number,
    params?: FUserControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<UserExcludingPassword>(
      {url: `/users/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getFUserControllerFindByIdQueryKey = (id: number,
    params?: FUserControllerFindByIdParams,) => {
    return [`/users/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getFUserControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof fUserControllerFindById>>, TError = ErrorType<unknown>>(id: number,
    params?: FUserControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fUserControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFUserControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fUserControllerFindById>>> = ({ signal }) => fUserControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fUserControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type FUserControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof fUserControllerFindById>>>
export type FUserControllerFindByIdQueryError = ErrorType<unknown>

export const useFUserControllerFindById = <TData = Awaited<ReturnType<typeof fUserControllerFindById>>, TError = ErrorType<unknown>>(
 id: number,
    params?: FUserControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fUserControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFUserControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const fUserControllerDeleteById = (
    id: number,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/users/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getFUserControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fUserControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof fUserControllerDeleteById>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fUserControllerDeleteById>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  fUserControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FUserControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof fUserControllerDeleteById>>>
    
    export type FUserControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useFUserControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fUserControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof fUserControllerDeleteById>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getFUserControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const fUserControllerCreate = (
    userExcludingId: UserExcludingId,
 ) => {
      
      
      return drcInstance<UserExcludingPassword>(
      {url: `/users`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userExcludingId
    },
      );
    }
  


export const getFUserControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fUserControllerCreate>>, TError,{data: UserExcludingId}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof fUserControllerCreate>>, TError,{data: UserExcludingId}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fUserControllerCreate>>, {data: UserExcludingId}> = (props) => {
          const {data} = props ?? {};

          return  fUserControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FUserControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof fUserControllerCreate>>>
    export type FUserControllerCreateMutationBody = UserExcludingId
    export type FUserControllerCreateMutationError = ErrorType<unknown>

    export const useFUserControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fUserControllerCreate>>, TError,{data: UserExcludingId}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof fUserControllerCreate>>,
        TError,
        {data: UserExcludingId},
        TContext
      > => {

      const mutationOptions = getFUserControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const fUserControllerFind = (
    params?: FUserControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<UserExcludingPassword[]>(
      {url: `/users`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getFUserControllerFindQueryKey = (params?: FUserControllerFindParams,) => {
    return [`/users`, ...(params ? [params]: [])] as const;
    }

    
export const getFUserControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof fUserControllerFind>>, TError = ErrorType<unknown>>(params?: FUserControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fUserControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFUserControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fUserControllerFind>>> = ({ signal }) => fUserControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fUserControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type FUserControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof fUserControllerFind>>>
export type FUserControllerFindQueryError = ErrorType<unknown>

export const useFUserControllerFind = <TData = Awaited<ReturnType<typeof fUserControllerFind>>, TError = ErrorType<unknown>>(
 params?: FUserControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fUserControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFUserControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



