export type UrlPath =
  | '/admin/dashboard'
  | '/admin/supplies-store-import/list'
  | '/admin/supplies-store-export/list'
  | '/admin/supplies-store-export/detail'
  | '/admin/supplies-store-inventory/list'
  | '/admin/supplies-store-inventory/warning'
  | '/admin/supplies-management/list'
  | '/admin/supplies-warehouse/list'
  | '/admin/supplies-group/list'
  | '/admin/supplies-origin/list'
  | '/admin/product-store-import/list'
  | '/admin/product-store-import/detail'
  | '/admin/product-store-excel/list'
  | '/admin/product-store-export/list'
  | '/admin/product-command-export/list'
  | '/admin/product-store-inventory/list'
  | '/admin/product-store-inventory/warning'
  | '/admin/product-warehouse/list'
  | '/admin/product-enterprise/list'
  | '/admin/product-group/list'
  | '/admin/product/list'
  | '/admin/product-customer/list'
  | '/admin/requirements-standard-set/list'
  | '/admin/requirements-standard-set/compare'
  | '/admin/terms-and-conditions/list'
  | '/admin/system/user/list'
  | '/admin/system/title/list'
  | '/admin/product-semi-finished-group/list'
  | '/admin/product-semi-finished/list'
  | '/admin/order-management/list'
  | '/admin/order-management/warning'
  | '/admin/order-analytics/list';

export type MenuPermission = {
  path: UrlPath;
  controller: string;
};

export const menuPermissions: MenuPermission[] = [
  { path: '/admin/supplies-store-import/list', controller: 'KhovtTransPhieuNhapController' },
  { path: '/admin/supplies-store-export/list', controller: 'KhovtTransPhieuXuatController' },
  { path: '/admin/supplies-store-export/detail', controller: 'KhovtTransPhieuXuatController' },
  { path: '/admin/supplies-store-inventory/list', controller: 'KhovtXuatNhapTonController' },
  { path: '/admin/supplies-store-inventory/warning', controller: 'KhovtXuatNhapTonController' },
  { path: '/admin/supplies-management/list', controller: 'KhovtMasterVtController' },
  { path: '/admin/supplies-warehouse/list', controller: 'KhovtMasterKhoController' },
  { path: '/admin/supplies-group/list', controller: 'KhovtNhomController' },
  { path: '/admin/supplies-origin/list', controller: 'KhovtMasterNguonGocController' },
  { path: '/admin/product-store-import/list', controller: 'KhotpTransPhieuNhapController' },
  { path: '/admin/product-store-import/detail', controller: 'KhotpTransPhieuNhapController' },
  { path: '/admin/product-store-excel/list', controller: 'KhotpTransLenhNhapController' },
  { path: '/admin/product-store-export/list', controller: 'KhotpTransPhieuXuatController' },
  { path: '/admin/product-command-export/list', controller: 'KhotpTransLenhXuatController' },
  { path: '/admin/product-store-inventory/list', controller: 'KhotpXuatNhapTonController' },
  { path: '/admin/product-store-inventory/warning', controller: 'KhotpXuatNhapTonController' },
  { path: '/admin/product-warehouse/list', controller: 'KhotpMasterKhoController' },
  { path: '/admin/product-enterprise/list', controller: 'KhotpMasterDonViController' },
  { path: '/admin/product-group/list', controller: 'KhotpMasterNhomSpController' },
  { path: '/admin/product/list', controller: 'KhotpMasterSanPhamController' },
  { path: '/admin/product-customer/list', controller: 'KhotpMasterSanPhamController' },
  { path: '/admin/requirements-standard-set/list', controller: 'DmMasterBoDinhMucController' },
  { path: '/admin/requirements-standard-set/compare', controller: 'DmMasterBoDinhMucController' },
  { path: '/admin/terms-and-conditions/list', controller: 'QdhdsdMasterTaiLieuController' },
  { path: '/admin/system/user/list', controller: 'FUserController' },
  { path: '/admin/system/title/list', controller: 'FTitleController' },
  { path: '/admin/system/title/list', controller: 'FTitleController' },
  { path: '/admin/order-management/list', controller: 'QldhTransDonHangController' },
  { path: '/admin/order-management/warning', controller: 'QldhTransDonHangController' },
  { path: '/admin/order-analytics/list', controller: 'QldhTransDonHangController' },
  { path: '/admin/product-semi-finished/list', controller: 'KhotpMasterBtpController' },
  { path: '/admin/product-semi-finished-group/list', controller: 'KhotpMasterNhomSpController' },
];
