/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * pkh-api
 * pkh api
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  KhotpTransLenhNhap,
  KhotpTransLenhNhapControllerCountParams,
  KhotpTransLenhNhapControllerFindByIdParams,
  KhotpTransLenhNhapControllerFindParams,
  KhotpTransLenhNhapWithRelations,
  KhotpTransPhieuNhapWithRelations,
  LoopbackCount,
  NewKhotpTransLenhNhap
} from '../../models'
import { drcInstance } from '../../../configs/mutator/drc-instance';
import type { ErrorType } from '../../../configs/mutator/drc-instance';



export const khotpTransLenhNhapControllerCount = (
    params?: KhotpTransLenhNhapControllerCountParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<LoopbackCount>(
      {url: `/khotp-trans-lenh-nhap/count`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpTransLenhNhapControllerCountQueryKey = (params?: KhotpTransLenhNhapControllerCountParams,) => {
    return [`/khotp-trans-lenh-nhap/count`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpTransLenhNhapControllerCountQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransLenhNhapControllerCount>>, TError = ErrorType<unknown>>(params?: KhotpTransLenhNhapControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransLenhNhapControllerCountQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCount>>> = ({ signal }) => khotpTransLenhNhapControllerCount(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCount>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransLenhNhapControllerCountQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCount>>>
export type KhotpTransLenhNhapControllerCountQueryError = ErrorType<unknown>

export const useKhotpTransLenhNhapControllerCount = <TData = Awaited<ReturnType<typeof khotpTransLenhNhapControllerCount>>, TError = ErrorType<unknown>>(
 params?: KhotpTransLenhNhapControllerCountParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransLenhNhapControllerCountQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpTransLenhNhapControllerCreateImportBill = (
    id: number,
 ) => {
      
      
      return drcInstance<KhotpTransPhieuNhapWithRelations[]>(
      {url: `/khotp-trans-lenh-nhap/tao-phieu/${id}`, method: 'POST'
    },
      );
    }
  


export const getKhotpTransLenhNhapControllerCreateImportBillMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCreateImportBill>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCreateImportBill>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCreateImportBill>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  khotpTransLenhNhapControllerCreateImportBill(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransLenhNhapControllerCreateImportBillMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCreateImportBill>>>
    
    export type KhotpTransLenhNhapControllerCreateImportBillMutationError = ErrorType<unknown>

    export const useKhotpTransLenhNhapControllerCreateImportBill = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCreateImportBill>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransLenhNhapControllerCreateImportBill>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getKhotpTransLenhNhapControllerCreateImportBillMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransLenhNhapControllerUpdateById = (
    id: number,
    khotpTransLenhNhapWithRelations: KhotpTransLenhNhapWithRelations,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-trans-lenh-nhap/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: khotpTransLenhNhapWithRelations
    },
      );
    }
  


export const getKhotpTransLenhNhapControllerUpdateByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerUpdateById>>, TError,{id: number;data: KhotpTransLenhNhapWithRelations}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerUpdateById>>, TError,{id: number;data: KhotpTransLenhNhapWithRelations}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransLenhNhapControllerUpdateById>>, {id: number;data: KhotpTransLenhNhapWithRelations}> = (props) => {
          const {id,data} = props ?? {};

          return  khotpTransLenhNhapControllerUpdateById(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransLenhNhapControllerUpdateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhNhapControllerUpdateById>>>
    export type KhotpTransLenhNhapControllerUpdateByIdMutationBody = KhotpTransLenhNhapWithRelations
    export type KhotpTransLenhNhapControllerUpdateByIdMutationError = ErrorType<unknown>

    export const useKhotpTransLenhNhapControllerUpdateById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerUpdateById>>, TError,{id: number;data: KhotpTransLenhNhapWithRelations}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransLenhNhapControllerUpdateById>>,
        TError,
        {id: number;data: KhotpTransLenhNhapWithRelations},
        TContext
      > => {

      const mutationOptions = getKhotpTransLenhNhapControllerUpdateByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransLenhNhapControllerFindById = (
    id: number,
    params?: KhotpTransLenhNhapControllerFindByIdParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpTransLenhNhapWithRelations>(
      {url: `/khotp-trans-lenh-nhap/${id}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpTransLenhNhapControllerFindByIdQueryKey = (id: number,
    params?: KhotpTransLenhNhapControllerFindByIdParams,) => {
    return [`/khotp-trans-lenh-nhap/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpTransLenhNhapControllerFindByIdQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransLenhNhapControllerFindById>>, TError = ErrorType<unknown>>(id: number,
    params?: KhotpTransLenhNhapControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerFindById>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransLenhNhapControllerFindByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransLenhNhapControllerFindById>>> = ({ signal }) => khotpTransLenhNhapControllerFindById(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerFindById>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransLenhNhapControllerFindByIdQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhNhapControllerFindById>>>
export type KhotpTransLenhNhapControllerFindByIdQueryError = ErrorType<unknown>

export const useKhotpTransLenhNhapControllerFindById = <TData = Awaited<ReturnType<typeof khotpTransLenhNhapControllerFindById>>, TError = ErrorType<unknown>>(
 id: number,
    params?: KhotpTransLenhNhapControllerFindByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerFindById>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransLenhNhapControllerFindByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const khotpTransLenhNhapControllerDeleteById = (
    id: number,
 ) => {
      
      
      return drcInstance<unknown>(
      {url: `/khotp-trans-lenh-nhap/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getKhotpTransLenhNhapControllerDeleteByIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerDeleteById>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransLenhNhapControllerDeleteById>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  khotpTransLenhNhapControllerDeleteById(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransLenhNhapControllerDeleteByIdMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhNhapControllerDeleteById>>>
    
    export type KhotpTransLenhNhapControllerDeleteByIdMutationError = ErrorType<unknown>

    export const useKhotpTransLenhNhapControllerDeleteById = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerDeleteById>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransLenhNhapControllerDeleteById>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getKhotpTransLenhNhapControllerDeleteByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransLenhNhapControllerCreate = (
    newKhotpTransLenhNhap: NewKhotpTransLenhNhap,
 ) => {
      
      
      return drcInstance<KhotpTransLenhNhapWithRelations>(
      {url: `/khotp-trans-lenh-nhap`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newKhotpTransLenhNhap
    },
      );
    }
  


export const getKhotpTransLenhNhapControllerCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCreate>>, TError,{data: NewKhotpTransLenhNhap}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCreate>>, TError,{data: NewKhotpTransLenhNhap}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCreate>>, {data: NewKhotpTransLenhNhap}> = (props) => {
          const {data} = props ?? {};

          return  khotpTransLenhNhapControllerCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type KhotpTransLenhNhapControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCreate>>>
    export type KhotpTransLenhNhapControllerCreateMutationBody = NewKhotpTransLenhNhap
    export type KhotpTransLenhNhapControllerCreateMutationError = ErrorType<unknown>

    export const useKhotpTransLenhNhapControllerCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerCreate>>, TError,{data: NewKhotpTransLenhNhap}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof khotpTransLenhNhapControllerCreate>>,
        TError,
        {data: NewKhotpTransLenhNhap},
        TContext
      > => {

      const mutationOptions = getKhotpTransLenhNhapControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const khotpTransLenhNhapControllerFind = (
    params?: KhotpTransLenhNhapControllerFindParams,
 signal?: AbortSignal
) => {
      
      
      return drcInstance<KhotpTransLenhNhap[]>(
      {url: `/khotp-trans-lenh-nhap`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getKhotpTransLenhNhapControllerFindQueryKey = (params?: KhotpTransLenhNhapControllerFindParams,) => {
    return [`/khotp-trans-lenh-nhap`, ...(params ? [params]: [])] as const;
    }

    
export const getKhotpTransLenhNhapControllerFindQueryOptions = <TData = Awaited<ReturnType<typeof khotpTransLenhNhapControllerFind>>, TError = ErrorType<unknown>>(params?: KhotpTransLenhNhapControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerFind>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getKhotpTransLenhNhapControllerFindQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof khotpTransLenhNhapControllerFind>>> = ({ signal }) => khotpTransLenhNhapControllerFind(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerFind>>, TError, TData> & { queryKey: QueryKey }
}

export type KhotpTransLenhNhapControllerFindQueryResult = NonNullable<Awaited<ReturnType<typeof khotpTransLenhNhapControllerFind>>>
export type KhotpTransLenhNhapControllerFindQueryError = ErrorType<unknown>

export const useKhotpTransLenhNhapControllerFind = <TData = Awaited<ReturnType<typeof khotpTransLenhNhapControllerFind>>, TError = ErrorType<unknown>>(
 params?: KhotpTransLenhNhapControllerFindParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof khotpTransLenhNhapControllerFind>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getKhotpTransLenhNhapControllerFindQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



