import { CircularProgress } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { find } from 'lodash';
import { useKhotpXuatNhapTonControllerFind } from 'main/apis/drc/endpoints/khotp-xuat-nhap-ton-controller/khotp-xuat-nhap-ton-controller';
import { QldhTransDonHangItemExcludingIdWithRelations } from 'main/apis/drc/models/qldhTransDonHangItemExcludingIdWithRelations';
import AutocompleteCell from 'main/components/atoms/autocomplete/AutocompleteCell';
import { DateFormat } from 'main/constants';
import { FieldName } from 'main/constants/enums';
import useProductOrderManagementOptions from 'main/hooks/useProductOrderManagementOptions';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  deleteOrdersDetails,
  QldhTransDonHangItemExcludingIdWithRelationsIds,
  selectOrdersDetails,
  updateOrdersDetails,
} from 'main/redux/modules/order-management/order-management-add-slice';
import { generateColumnStyle } from 'main/utils';
import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormGroup, Input } from 'reactstrap';

export type OrderManagementMaSanPhamType = QldhTransDonHangItemExcludingIdWithRelations & {
  id?: number;
  dvt?: string;
  luuKho?: number;
  tonKho?: number;
  tonKhoDuKien?: number;
  thoiHanLuuKho?: number;
  soLuongGiuHang?: number;
  maKho?: string;
};

type Props = {
  // productDataOptions?: SelectOption[];
  orderManagementInfoDetails?: QldhTransDonHangItemExcludingIdWithRelationsIds[];
};

const useTableConfig = ({ orderManagementInfoDetails }: Props) => {
  const dispatch = useAppDispatch();
  const ordersDetails = useAppSelector(selectOrdersDetails);
  const { productDataOptions, productStoreOptions } = useProductOrderManagementOptions(false);

  const [deletingRows, setDeletingRows] = useState<number[]>([]);

  const handleDeleteSuppliesDetails = async (id: number) => {
    if (deletingRows.includes(id)) {
      return;
    }

    setDeletingRows((prev) => [...prev, id]);
    try {
      await dispatch(deleteOrdersDetails(id));
    } catch (error) {
    } finally {
      setDeletingRows((prev) => prev.filter((rowId) => rowId !== id));
    }
  };

  const { data: orderManagementDetailsData, isLoading: IsLoadingProductData } = useKhotpXuatNhapTonControllerFind({});

  const findTonKhoData = (maSanPhamData: string | undefined, maKhoData: string | undefined) => {
    if (maSanPhamData && maKhoData) {
      return (
        find(orderManagementDetailsData?.data, (item) => item?.maSanPham === maSanPhamData && item?.maKho === maKhoData)
          ?.tonKho || 0
      );
    }
    return 0;
  };

  const updateOrdersDetailsSanPham = (params: any, newValue: OrderManagementMaSanPhamType) => {
    const currentDate = moment();

    // const thoiHanLuuKho = newValue.thoiHanLuuKho || 0;
    const ngayGiaoDuKien = moment(currentDate).add(1, 'month').format(DateFormat.DATE_ONLY_VN);
    const ngayGiaoThucTe = moment(currentDate).add(1, 'month').format(DateFormat.DATE_ONLY_VN);

    const maKho = ordersDetails.find((order) => order.id === params.id)?.maKho;

    const tonKhoData = findTonKhoData(newValue.maSanPham, maKho);

    dispatch(
      updateOrdersDetails({
        id: params.row?.id,
        changes: {
          maSanPham: newValue.maSanPham,
          maKho: maKho,
          tonKho: Number(tonKhoData),
          tonKhoDuKien: params?.row.soLuong
            ? Number(newValue.tonKho) - Number(params?.row.soLuong)
            : Number(newValue.tonKho),
          ghiChu: newValue.ghiChu,
          dvt: newValue.dvt,
          ngayGiaoDuKien,
          ngayGiaoThucTe,
        },
      }),
    );
  };

  const valueFromDetail = (value: any): string => {
    const option = productDataOptions?.find((option) => option.id === value);

    return value ? `${option?.id} - ${option?.text}` : '';
  };

  const valueFromDetailStore = (value: any): string => {
    const option = productStoreOptions?.find((option) => option.id === value);

    return value ? `${option?.id} - ${option?.text}` : '';
  };

  const updateOrdersDetailsKhoXuat = (params: any, newValue: OrderManagementMaSanPhamType) => {
    const maSanPham = ordersDetails.find((order) => order.id === params.id)?.maSanPham;

    const tonKhoData = findTonKhoData(maSanPham, newValue.maKho);

    dispatch(
      updateOrdersDetails({
        id: params.row?.id,
        changes: {
          maKho: newValue.maKho,
          tonKho: Number(tonKhoData),
        },
      }),
    );
  };

  const columns: GridColDef[] = [
    {
      field: FieldName.STT,
      headerName: 'STT',
      editable: false,
      sortable: false,
      flex: 0.5,
      cellClassName: 'disable-gray',
      renderCell: (params: any) => {
        const index = orderManagementInfoDetails?.findIndex((row) => row?.id === params?.id);
        return index !== -1 ? Number(index) + 1 : '';
      },
    },
    {
      field: FieldName.MA_KHO_XUAT,
      headerName: 'Kho Xuất',
      flex: 3,
      renderCell: (params) =>
        params &&
        params.value &&
        productStoreOptions &&
        productStoreOptions?.length > 0 && (
          <AutocompleteCell
            value={params.value}
            options={productStoreOptions as any}
            placeholder={valueFromDetailStore(params.value)}
            getOptionLabel={(option: any) => {
              if (typeof option === 'object' && option !== null && 'id' in option && 'text' in option) {
                return `${option.id} - ${option.text}`;
              }
              return valueFromDetailStore(option);
            }}
            onChange={(newValue) => {
              if (newValue) {
                updateOrdersDetailsKhoXuat(params, newValue as unknown as OrderManagementMaSanPhamType);
              }
            }}
          />
        ),
    },
    {
      field: FieldName.MA_SAN_PHAM,
      headerName: 'Mã sản phẩm',
      flex: 3,
      renderCell: (params) =>
        params &&
        params.value &&
        productDataOptions &&
        productDataOptions?.length > 0 && (
          <AutocompleteCell
            value={params.value}
            options={productDataOptions as any}
            placeholder={valueFromDetail(params.value)}
            getOptionLabel={(option: any) => {
              if (typeof option === 'object' && option !== null && 'id' in option && 'text' in option) {
                return `${option.id} - ${option.text}`;
              }
              return valueFromDetail(option);
            }}
            onChange={(newValue) => {
              if (newValue) {
                updateOrdersDetailsSanPham(params, newValue as unknown as OrderManagementMaSanPhamType);
              }
            }}
          />
        ),
    },
    {
      sortable: false,
      field: FieldName.DVT,
      headerName: 'ĐVT',
      cellClassName: 'disable-gray',
      flex: 0.5,
      editable: false,
    },
    {
      sortable: false,
      field: FieldName.SO_LUONG,
      headerName: 'Số lượng',
      flex: 1,
      editable: true,
      renderCell: (params) => {
        // const isQuantityValid = validateQuantity(params.value);
        return (
          <FormGroup>
            <Input
              type="number"
              required
              value={params.value || ''}
              // invalid={!isQuantityValid}
              onChange={(e) => {
                const value = e.target.value;
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: Number(value),
                });
              }}
            />
            {/* {!isQuantityValid && <FormFeedback>Số lượng phải lớn hơn 0</FormFeedback>} */}
          </FormGroup>
        );
      },
    },
    {
      sortable: false,
      field: 'soLuongGiuHang',
      headerName: 'Số lượng giữ hàng',
      flex: 1.5,
      editable: true,
      type: 'number',
      renderCell: (params) => (
        <FormGroup>
          <Input
            type="number"
            value={params.value}
            onChange={(e) => {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: Number(e.target.value),
              });
            }}
          />
        </FormGroup>
      ),
    },
    {
      field: FieldName.TON_KHO,
      headerName: 'Tồn kho hiện tại',
      flex: 1.5,
      editable: true,
      sortable: false,
      cellClassName: 'disable-gray',
      renderCell: (params) => (
        <FormGroup>
          <Input
            type="number"
            disabled
            className="disable-gray"
            value={params.value || ''}
            onChange={(e) =>
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              })
            }
          />
        </FormGroup>
      ),
    },
    {
      editable: false,
      cellClassName: 'disable-gray',
      field: FieldName.TON_KHO_DU_KIEN,
      headerName: 'Tồn kho dự kiến',
      flex: 1.5,
      renderCell: (params) => (
        <FormGroup>
          <Input
            type="number"
            value={params.value || ''}
            disabled
            className="disable-gray"
            onChange={(e) =>
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              })
            }
          />
        </FormGroup>
      ),
    },
    {
      field: FieldName.NGAY_GIAO_DU_KIEN,
      headerName: 'Ngày giao hàng dự kiến',
      flex: 2,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            // value={params.value ? moment(params.value, DateFormat.DATE_ONLY_VN) : null}
            value={params.value ? moment.utc(params.value) : null}
            format={DateFormat.DATE_ONLY_VN}
            onChange={(newDate) => {
              // const serializedDate = newDate ? moment(newDate).format(DateFormat.DATE_ONLY_VN) : null;
              dispatch(
                updateOrdersDetails({
                  id: params.row?.id,
                  changes: {
                    ngayGiaoDuKien: newDate as any,
                  },
                }),
              );
            }}
            onAccept={(newDate) => {
              // const serializedDate = newDate ? moment(newDate).format(DateFormat.DATE_ONLY_VN) : null;
              dispatch(
                updateOrdersDetails({
                  id: params.row?.id,
                  changes: {
                    ngayGiaoDuKien: newDate as any,
                  },
                }),
              );
            }}
            className="custom-datepicker"
            reduceAnimations
            slotProps={{ inputAdornment: { hidden: true } }}
          />
        </LocalizationProvider>
      ),
    },
    {
      sortable: false,
      field: FieldName.NGAY_GIAO_THUC_TE,
      headerName: 'Ngày giao hàng thực tế',
      flex: 1.5,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={params.value ? moment.utc(params.value) : null}
            // value={params.value ? moment(params.value, DateFormat.DATE_ONLY_VN) : null}
            format={DateFormat.DATE_ONLY_VN}
            onChange={(newDate) => {
              // const serializedDate = newDate ? moment(newDate).format(DateFormat.DATE_ONLY_VN) : null;
              dispatch(
                updateOrdersDetails({
                  id: params.row?.id,
                  changes: {
                    ngayGiaoThucTe: newDate as any,
                    // ngayGiaoThucTe: serializedDate,
                  },
                }),
              );
            }}
            onAccept={(newDate: any) => {
              // const serializedDate = newDate ? moment(newDate).format(DateFormat.DATE_ONLY_VN) : null;
              dispatch(
                updateOrdersDetails({
                  id: params.row?.id,
                  changes: {
                    ngayGiaoThucTe: newDate as any,
                  },
                }),
              );
            }}
            className="custom-datepicker"
            reduceAnimations
            slotProps={{ inputAdornment: { hidden: true } }}
          />
        </LocalizationProvider>
      ),
      ...generateColumnStyle(),
    },
    {
      sortable: false,
      field: FieldName.YEU_CAU_DONG_GOI,
      headerName: 'Yêu cầu đóng gói',
      flex: 1.5,
      editable: true,
      type: 'string',
      renderCell: (params) => (
        <FormGroup>
          <Input
            type="text"
            value={params.value || ''}
            onChange={(e) => {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              });
            }}
          />
        </FormGroup>
      ),
    },

    {
      sortable: false,
      field: FieldName.GHI_CHU,
      headerName: 'Ghi chú',
      flex: 1,
      editable: true,
      type: 'string',
      renderCell: (params) => (
        <FormGroup>
          <Input
            type="text"
            value={params.value || ''}
            onChange={(e) => {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              });
            }}
          />
        </FormGroup>
      ),
    },
    {
      sortable: false,
      field: 'actions',
      headerName: '⋮⋮⋮',
      headerAlign: 'center',
      flex: 0.1,
      align: 'center',
      renderCell: (params) => {
        const isDeleting = deletingRows.includes(params?.row?.id);
        return (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              if (!isDeleting) {
                handleDeleteSuppliesDetails(params?.row?.id);
              }
            }}
            style={{ pointerEvents: isDeleting ? 'none' : 'auto', opacity: isDeleting ? 0.5 : 1 }}>
            {isDeleting ? <CircularProgress size={10} /> : <i className="fa-solid fa-trash" title="Xóa" />}
          </Link>
        );
      },
    },
  ];

  return { columns, IsLoadingProductData };
};

export default useTableConfig;
