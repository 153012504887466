// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-datepicker .rdtPicker {
  position: fixed;
  z-index: 1300 !important;
}

.custom-datepicker .rdtPicker {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.custom-datepicker {
  overflow: visible;
}
`, "",{"version":3,"sources":["webpack://./src/main/components/atoms/date/index.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".custom-datepicker .rdtPicker {\n  position: fixed;\n  z-index: 1300 !important;\n}\n\n.custom-datepicker .rdtPicker {\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.custom-datepicker {\n  overflow: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
