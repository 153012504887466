import { NewQldhTransDonHang } from 'main/apis/drc/models';
import { DatePicker } from 'main/components/atoms/date';
import moment from 'moment';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

type Props = {
  orderManagementDetails: NewQldhTransDonHang;
};

function OrderManagementDetailInfo({
  orderManagementDetails: { yeuCauGiuHang, donHangId, ngayDatHang, nguoiTao, nguoiYeuCau, trangThai, khachHang },
}: Props) {
  return (
    <Form>
      <Row>
        <Col md={12} lg={5}>
          <FormGroup className="row">
            <Label className="form-control-label" md="4">
              Khách hàng
            </Label>
            <Col md="8">
              <Input type="text" value={khachHang?.tenKh || ''} disabled />
            </Col>
          </FormGroup>
        </Col>
        <Col md={12} lg={5}>
          <FormGroup className="row">
            <Label className="form-control-label" md="4">
              ID đơn hàng
            </Label>
            <Col md="8">
              <Input type="text" value={donHangId || ''} disabled />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={5}>
          <FormGroup className="row">
            <Label className="form-control-label" md="4">
              Vùng
            </Label>
            <Col md="8">
              <Input type="text" value={khachHang?.khuVucKh || ''} disabled />
            </Col>
          </FormGroup>
        </Col>
        <Col md={12} lg={5}>
          <FormGroup className="row">
            <Label className="form-control-label" md="4">
              Ngày đặt hàng
            </Label>
            <Col md="8">
              <DatePicker inputProps={{ disabled: true }} value={ngayDatHang ? moment(ngayDatHang) : undefined} />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={5}>
          <FormGroup className="row">
            <Label className="form-control-label" md="4">
              Yêu cầu giữ hàng
            </Label>
            <Col md="8">
              <Input type="text" value={yeuCauGiuHang || ''} disabled />
            </Col>
          </FormGroup>
        </Col>
        <Col md={12} lg={5}>
          <FormGroup className="row">
            <Label className="form-control-label" md="4">
              Người tạo
            </Label>
            <Col md="8">
              <Input type="text" value={nguoiTao || ''} disabled />
            </Col>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={12} lg={5}>
          <FormGroup className="row">
            <Label className="form-control-label" md="4">
              Trạng thái
            </Label>
            <Col md="8">
              <Input type="text" value={trangThai || ''} disabled />
            </Col>
          </FormGroup>
        </Col>
        <Col md={12} lg={5}>
          <FormGroup className="row">
            <Label className="form-control-label" md="4">
              Người yêu cầu
            </Label>
            <Col md="8">
              <Input type="text" value={nguoiYeuCau || ''} disabled />
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}

export default OrderManagementDetailInfo;
