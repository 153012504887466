import {
  useKhovtTransPhieuNhapControllerDeleteById,
  useKhovtTransPhieuNhapControllerUpdateById,
} from 'main/apis/drc/endpoints/khovt-trans-phieu-nhap-controller/khovt-trans-phieu-nhap-controller';
import {
  KhovtTransPhieuNhapControllerFindDetail200,
  KhovtTransPhieuNhapControllerFindDetail200DataItem,
} from 'main/apis/drc/models';
import { BasicSpinner } from 'main/components/atoms/spiner';
import EditPermissionAction from 'main/components/molecules/permission-action/EditPermissionAction';
import { Page, ToastMessage } from 'main/constants';
import { FieldName, ImportStatus } from 'main/constants/enums';
import { useAppDispatch, useAppSelector } from 'main/redux/hooks';
import {
  selectImportTable,
  updateImportTable,
} from 'main/redux/modules/supplies-store-import/supplies-store-import-list-slice';
import { generateColumnStyle } from 'main/utils';
import { useCallback, useState } from 'react';
import BootstrapTable, { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import CancelImportBillModal from './cancel-import-bill-modal';
import LiquidationTransferImportBillModal from './liquidation-transfer-import-bill-modal';
import RequestRecheckImportBillModal from './request-recheck-import-bill-modal';
import tableConfig from './table-config';
import ViewPdfImportBillModal from './view-pdf-import-bill-modal';
import ViewPdfKH22ImportBillModal from './view-pdf-kh22-import-bill-modal';

type Props = {
  data?: KhovtTransPhieuNhapControllerFindDetail200;
  dataIsLoading: boolean;
  handleRefreshTable: () => void;
};

function SuppliesStoreImportTable({
  data: { data, count: dataCount } = { data: [], count: 0 },
  dataIsLoading,
  handleRefreshTable,
}: Props) {
  // React Router
  const navigate = useNavigate();

  // Redux
  const dispatch = useAppDispatch();
  const { page, sizePerPage, sortField, sortOrder } = useAppSelector(selectImportTable);

  // React State/Ref
  const [isOpenViewPdfModal, setOpenViewPdfModal] = useState(false);
  const [isOpenViewPdfKH22BillModal, setOpenViewPdfKH22BillModal] = useState(false);
  const [isOpenCancelModal, setOpenCancelModal] = useState(false);
  const [isOpenLiquidationTransferModal, setOpenLiquidationTransferModal] = useState(false);
  const [isOpenRequestRecheckModal, setOpenRequestRecheckModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<KhovtTransPhieuNhapControllerFindDetail200DataItem>();

  // React Query
  const { mutateAsync: deletedSuppliesImportBillMutateAsync } = useKhovtTransPhieuNhapControllerDeleteById();

  const { mutateAsync: updatedSuppliesImportBillMutateAsync } = useKhovtTransPhieuNhapControllerUpdateById();

  // React Function
  const handleTableChange = useCallback(
    (_: TableChangeType, newState: TableChangeState<KhovtTransPhieuNhapControllerFindDetail200DataItem>) => {
      const { page, sizePerPage, sortField, sortOrder } = newState;

      dispatch(
        updateImportTable({
          page,
          sizePerPage,
          sortField,
          sortOrder,
        }),
      );
    },
    [dispatch],
  );

  const toggleViewPdfModal = useCallback(() => setOpenViewPdfModal((isOpenViewPdfModal) => !isOpenViewPdfModal), []);

  const toggleViewPdfKH22BillModal = useCallback(
    () => setOpenViewPdfKH22BillModal((isOpenViewPdfKH22BillModal) => !isOpenViewPdfKH22BillModal),
    [],
  );

  const toggleCancelModal = useCallback(() => setOpenCancelModal((isOpenCancelModal) => !isOpenCancelModal), []);

  const toggleLiquidationTransferModal = useCallback(
    () => setOpenLiquidationTransferModal((isOpenLiquidationTransferModal) => !isOpenLiquidationTransferModal),
    [],
  );

  const toggleRequestRecheckModal = useCallback(
    () => setOpenRequestRecheckModal((isOpenRequestRecheckModal) => !isOpenRequestRecheckModal),
    [],
  );

  const handleViewPdfKH22BillActionClick = useCallback(
    (row: KhovtTransPhieuNhapControllerFindDetail200DataItem) => {
      setSelectedRow(row);
      toggleViewPdfKH22BillModal();
    },
    [toggleViewPdfKH22BillModal],
  );

  const handleCancelActionClick = useCallback(
    (row: KhovtTransPhieuNhapControllerFindDetail200DataItem) => {
      setSelectedRow(row);
      toggleCancelModal();
    },
    [toggleCancelModal],
  );

  const handleRequestRecheckActionClick = useCallback(
    (row: KhovtTransPhieuNhapControllerFindDetail200DataItem) => {
      setSelectedRow(row);
      toggleRequestRecheckModal();
    },
    [toggleRequestRecheckModal],
  );

  const handleLiquidationTransferActionClick = useCallback(
    (row: KhovtTransPhieuNhapControllerFindDetail200DataItem) => {
      setSelectedRow(row);
      toggleLiquidationTransferModal();
    },
    [toggleLiquidationTransferModal],
  );

  const handleCancelModalConfirmClick = useCallback(() => {
    // Close modal
    toggleCancelModal();

    // Delete supplies import bill
    deletedSuppliesImportBillMutateAsync({ id: Number(selectedRow?.phieuNhapId) })
      .then((_) => {
        // Refresh table data
        handleRefreshTable();
        toast.success(ToastMessage.CANCEL_SUCCESS);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.error?.message || e.message);
      });
  }, [deletedSuppliesImportBillMutateAsync, handleRefreshTable, selectedRow?.phieuNhapId, toggleCancelModal]);

  const handleLiquidationTransferModalConfirmClick = useCallback(() => {
    // Close modal
    toggleLiquidationTransferModal();

    // Update supplies import bill
    updatedSuppliesImportBillMutateAsync({
      id: Number(selectedRow?.phieuNhapId),
      data: {
        details: [
          {
            maVt: String(selectedRow?.maVt),
            status: ImportStatus.Disposal.id,
          },
        ],
      },
    })
      .then((_) => {
        // Refresh table data
        handleRefreshTable();
        toast.success(ToastMessage.LIQUIDATION_TRANSFER_SUCCESS);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.error?.message || e.message);
      });
  }, [
    toggleLiquidationTransferModal,
    updatedSuppliesImportBillMutateAsync,
    selectedRow?.phieuNhapId,
    selectedRow?.maVt,
    handleRefreshTable,
  ]);

  const handleRequestRecheckModalConfirmClick = useCallback(() => {
    // Close modal
    toggleRequestRecheckModal();

    // Update supplies import bill
    updatedSuppliesImportBillMutateAsync({
      id: Number(selectedRow?.phieuNhapId),
      data: {
        details: [
          {
            maVt: String(selectedRow?.maVt),
            status: ImportStatus.Wait.id,
          },
        ],
      },
    })
      .then((_) => {
        // Refresh table data
        handleRefreshTable();
        toast.success(ToastMessage.REQUEST_RECHECK_SUCCESS);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.error?.message || e.message);
      });
  }, [
    toggleRequestRecheckModal,
    updatedSuppliesImportBillMutateAsync,
    selectedRow?.phieuNhapId,
    selectedRow?.maVt,
    handleRefreshTable,
  ]);

  return (
    <ToolkitProvider
      data={data || []}
      keyField={`${FieldName.ID}_${FieldName.MA_VT}`}
      columns={[
        ...tableConfig.columns,
        {
          dataField: FieldName.ACTION,
          isDummyField: true,
          text: 'Thao tác',
          ...generateColumnStyle(),
          formatter: (_: any, row: KhovtTransPhieuNhapControllerFindDetail200DataItem) => {
            return (
              <div className="d-flex">
                <Link
                  className="px-2"
                  to={Page.SUPPLIES_STORE_IMPORT_DETAIL.PATH.replace(':id', String(row.phieuNhapId))}>
                  <i className="fa-solid fa-eye" title="Xem" />
                </Link>
                <Link
                  className="px-2"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleViewPdfKH22BillActionClick(row);
                  }}>
                  <i className="fas fa-file-invoice" title="Xem phiếu kết quả kiểm tra KH22" />
                </Link>
                {
                  //suppliesImportBillStatus === ImportStatus.Wait.id && (
                  <EditPermissionAction>
                    <Link
                      className="px-2"
                      to={Page.SUPPLIES_STORE_IMPORT_EDIT.PATH.replace(':id', String(row.phieuNhapId))}>
                      <i className="fa-solid fa-pen-to-square" title="Sửa" />
                    </Link>
                  </EditPermissionAction>
                  //)
                }
                {
                  //(suppliesImportBillStatus === ImportStatus.Wait.id ||
                  //suppliesImportBillStatus === ImportStatus.Meet.id ||
                  //suppliesImportBillStatus === ImportStatus.NotMeet.id) && (
                  <EditPermissionAction>
                    <Link
                      className="px-2"
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleCancelActionClick(row);
                      }}>
                      <i className="fa-solid fa-trash" title="Hủy" />
                    </Link>
                  </EditPermissionAction>
                  //)
                }
                {
                  //(suppliesImportBillStatus === ImportStatus.NotMeet.id ||
                  //suppliesImportBillStatus === ImportStatus.Overdue.id) && (
                  <Link
                    className="px-2"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleRequestRecheckActionClick(row);
                    }}>
                    <i className="fa-solid fa-check-double" title="Yêu cầu kiểm tra lại" />
                  </Link>
                  //)
                }
                {
                  //suppliesImportBillStatus === ImportStatus.NotMeet.id && (
                  <EditPermissionAction>
                    <Link
                      className="px-2"
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleLiquidationTransferActionClick(row);
                      }}>
                      <i className="fa fa-exchange" title="Chuyển thanh lý" />
                    </Link>
                  </EditPermissionAction>
                  //)
                }
              </div>
            );
          },
        },
      ]}>
      {(props: any) => (
        <>
          <div className="d-flex justify-content-end mb-4 pb-1">
            {/*
              <Button color="primary" onClick={toggleViewPdfModal}>
                Xem sổ theo dõi chứng từ nhập về
              </Button>
            */}
            <EditPermissionAction>
              <Button color="primary" onClick={() => navigate(Page.SUPPLIES_STORE_IMPORT_ADD.PATH)}>
                Thêm mới
              </Button>
            </EditPermissionAction>
          </div>
          <div className="table-responsive">
            <BootstrapTable
              {...props.baseProps}
              remote
              bootstrap4
              bordered={false}
              pagination={paginationFactory({
                ...tableConfig.pagination,
                page: page,
                sizePerPage: sizePerPage,
                totalSize: dataCount,
              })}
              sort={
                sortField
                  ? {
                      dataField: sortField,
                      order: sortOrder,
                    }
                  : {}
              }
              onTableChange={handleTableChange}
              noDataIndication={() => (
                <div className="text-center">
                  {dataIsLoading ? <BasicSpinner /> : 'Không có kết quả nào được tìm thấy'}
                </div>
              )}
            />
          </div>
          <ViewPdfImportBillModal isOpen={isOpenViewPdfModal} toggle={toggleViewPdfModal} suppliesImportBills={data} />
          <ViewPdfKH22ImportBillModal
            isOpen={isOpenViewPdfKH22BillModal}
            toggle={toggleViewPdfKH22BillModal}
            suppliesImportBill={{ ...selectedRow }}
          />
          <CancelImportBillModal
            suppliesImportBill={selectedRow}
            isOpen={isOpenCancelModal}
            toggle={toggleCancelModal}
            handleConfirmClick={handleCancelModalConfirmClick}
          />
          <LiquidationTransferImportBillModal
            suppliesImportBill={selectedRow}
            isOpen={isOpenLiquidationTransferModal}
            toggle={toggleLiquidationTransferModal}
            handleConfirmClick={handleLiquidationTransferModalConfirmClick}
          />
          <RequestRecheckImportBillModal
            suppliesImportBill={selectedRow}
            isOpen={isOpenRequestRecheckModal}
            toggle={toggleRequestRecheckModal}
            handleConfirmClick={handleRequestRecheckModalConfirmClick}
          />
        </>
      )}
    </ToolkitProvider>
  );
}

export default SuppliesStoreImportTable;
